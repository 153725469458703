import React from "react";
import { Card } from "antd";
import * as Asciidoctor from "asciidoctor";

const adoc = `
= Changelog

== [1.10.1] - 2021-10-08

  * [Added] *Prospection Copy*: botão para apagar todos os ficheiros *target*
  * [Added] *Prospection Copy*: indicação (a vermelho) de ficheiro que não foi possível tratar

== [1.10.0] - 2021-08-26

  * [Added] *RAV > Prospection Copy*

== [1.9.2] - 2021-08-26

  * [Changed] regra de quais os anos a apresentar na tabela *Indicadores Sócio-demográficos*

== [1.9.1] - 2021-06-28

  * [Added] ordenar por mediador

== [1.9.0] - 2021-05-27

  * [Added] Pesquisa por agência
  * [Changed] Filtro *manually updated*, passou para _filtros secundários_ (não visível) 

== [1.8.2] - 2020-12-17

  * [Fixed] Estatisticas Oferta: Valores unitários de Áreas médias

== [1.8.1] - 2020-04-29

  * [Changed] Cores nas estatisticas sugeridas pela REVC
  * [Fixed] Selecção de datas

== [1.8.0] - 2020-04-23

  * [Added] Estatisticas Comerciais
  * [Added] Possibilidade de visualizar se propriedade tem garagem/parqueamento
  * [Changed] Formula que invalida areas (demasiado pequenas/grandes)
  * [Fixed] Erro ao alterar area de propriedades 

== [1.7.0] - 2020-03-02

  * [Added] Estatisticas Oferta (Valor Venda/Arrendamento) 
  * [Added] Estatisticas Oferta (Area Média Venda/Arrendamento)

== [1.6.1] - 2020-02-12

  * [Fixed] Maps was not working

== [1.6.0] - 2020-02-01

  * [Added] Estatisticas Oferta (Segmentação Valores Pedidos)
  * [Changed] UI: adicionadas legendas ("€" e "%") no eixo dos yy de alguns gráficos
  * [Changed] UI: Mostra gráficos à medida que são processados (só estava a mostrar quando "TODOS" estavam processados)

== [1.5.0] - 2020-01-29

  * [Added] Estatisticas Oferta (Oferta Imobiliária por tipo)
  * [Added] Estatisticas Oferta (Oferta Apartamentos por tipo)
  * [Added] Estatisticas Oferta (Oferta Moradias por tipo)
  * [Added] Estatisticas Oferta (Oferta Aparatamentos/Moradias Novas/Usadsa por tipologia)
  * [Changed] UI: reduzido tamanha dos gráfico em *Estatisticas > Macro*
  * [Fixed] Some error messages

== [1.4.0] - 2020-01-24

  * [Added] Detecta quando cliente está com uma versão inválida e força o refresh.
  * [Added] Adicionada opção que permite entrar na aplicação sem chave. Esta opção fica desabilitada para todos os utilizadores e só é possível activar de forma manual na Base de Dados (apenas será usada excepcionalmente - em principio para o utilizador admin)
  * [Added] (admin only) Adicionado botão *Refresh* na pagina *Sessions*
  * [Changed] UI melhorado: removido efeito ao passar o rato por cima dos campos de pesquisa
  * [Changed] UI melhorado: Listagem de utilizadores
  * [Fixed] A cache não estava a funcionar (bug introduzido na versão 1.2.0). Ou seja nos ultimos dias a aplicação estava mais lenta.

== [1.3.0] - 2020-01-23

  * [Added] (admin only) Visualização de acessos (*MENU > Admin > Sessions*)
  * [Changed] Logins expiram ao fim de 12h

== [1.2.0] - 2020-01-20

  * [Added] Opção para os utilizadores inserirem RAVs
  * [Fixed] Erro ao inserir RAV (parsing do GPS)
  * [Fixed] Legendas na secção *Arrendamento Versus Venda* 
  * [Fixed] Nome de freguesias está a ser cortado aos 10 caraters (bug introduzido num teste)

== [1.1.0] - 2020-01-19

  * [Added] (admin only) Adicionada opção para "Unregister" chaves de utilizadores
  * [Added] página para ver o changelog (\`MENU > MyUser > Changelog\`)
  * [Added] Utilizadores podem mudar password em (\`MENU > Settings\`)
  * [Changed] Alteração de layout: Diminuição do tamanho de vários componentes 
  * [Changed] Alteração de layout: Adicionado sub-menu (vertical) na página \`Settings\`
  * [Changed] \`Ref\` no csv passou a ser um link para o url do registo (dá para clickar)
  * [Changed] Removido simbolo \`€\` na exportação para \`csv\`
  * [Fixed] crash quando se carregava \`Escape\` durante a pesquisa

== [1.0.0] - 2020-01-10

  * Initial release
`;

export const Changelog = () => {
  const asciidoctor = Asciidoctor();

  // const doc = asciidoctor.load(adoc);
  // var doctitle = doc.getDocumentTitle();
  // console.log("doctitle", doctitle);
  // return <>Title: {doctitle}</>;

  const html = asciidoctor.convert(adoc);
  // const html = asciidoctor.convert(adoc, { header_footer: true });
  return (
    <Card
      title="Changelog"
      style={{ margin: "50px auto auto auto", width: 1000 }}
    >
      <div className="content" dangerouslySetInnerHTML={{ __html: html }}></div>
    </Card>
  );
};
