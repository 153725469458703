import React from "react";
import { Table, Switch } from "antd";

import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { EditableFormRow } from "./EditableTable/EditableRow";
import { EditableCell } from "./EditableTable/EditableCell";

import { queryUserSettings } from "./Queries";

const updateUserSettingsMutation = gql`
  mutation updateUserSettings(
    $homogenizationParams: [HomogenizationParamInput!]!
  ) {
    updateUserSettings(homogenizationParams: $homogenizationParams) {
      homogenizationParams {
        id
        name
        enabled
        position
      }
    }
  }
`;

export const HomogenizationParamsSettings = () => {
  const { loading, error, data } = useQuery(queryUserSettings);
  const [mutation] = useMutation(updateUserSettingsMutation);

  if (loading) return null;

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const { userSettings } = data;
  const homogenizationParams = userSettings
    ? userSettings.homogenizationParams
    : [];

  const initialColumns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: true
    },
    {
      title: "Enabled?",
      dataIndex: "enabled",
      editing: true,
      inputRender: (form, record, dataIndex, onClear) => {
        return (
          <Switch
            checked={record.enabled}
            // size="small"
            onChange={change => {
              // console.log("changed", change);
              // console.log(
              //   "onChange: %o -> %o",
              //   record.enabled,
              //   !record.enabled
              // );
              const row = { ...record, enabled: change };
              handleSave(row, mutation);
              // handleSave()
              form.setFieldsValue({
                enabled: change
              });
            }}
          />
        );
      }
    }
  ];

  const columns = initialColumns.map(col => {
    if (!(col.editable || col.editing)) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        editing: col.editing,
        dataIndex: col.dataIndex,
        title: col.title,
        inputRender: col.inputRender,
        handleSave: row => handleSave(row, mutation)
      })
    };
  });

  const handleSave = (row, mutation) => {
    const { __typename, ...validFields } = row;
    const homogenizationParams = [validFields];
    mutation({
      variables: { homogenizationParams }
    });
  };

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  };

  return (
    <Table
      components={components}
      columns={columns}
      dataSource={homogenizationParams}
      rowKey="id"
      rowClassName={() => "editable-row"}
      pagination={false}
      size="small"
    />
  );
};
