import { connect } from "react-redux";
import { setHasGps } from "../actions";

import { FilterYesNoAll } from "../FilterYesNoAll";

const mapStateToProps = state => {
  return {
    value:
      state.filters.property.hasGps === null
        ? "all"
        : state.filters.property.hasGps === true
          ? "yes"
          : "no",
    label: "GPS? "
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: values => {
    switch (values) {
      case "all":
        return dispatch(setHasGps(null));
      case "yes":
        return dispatch(setHasGps(true));
      case "no":
        return dispatch(setHasGps(false));
      default:
        throw new Error("Invalid Has GPS option");
    }
  }
});

export const FilterWithGpsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterYesNoAll);
