// @flow
import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Form, Button, Input, Icon, Card, Col, message } from "antd";
import { AUTH_TOKEN } from "./constants";
import { connect } from "react-redux";
import { setUser, reload } from "./actions";
import { sha256 } from "./util/security";
import { solveLoginChallenge } from "@webauthn/client";

export const REQUEST_LOGIN_MUTATION = gql`
  mutation RequestLoginMutation($usernameOrEmail: String!, $password: String!) {
    requestLogin(usernameOrEmail: $usernameOrEmail, password: $password) {
      error
      challenge
      allowCredentials {
        type
        id
        transports
      }
      token
      user {
        id
        name
        role {
          id
          name
        }
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $usernameOrEmail: String!
    $credentials: LoginCredentials!
  ) {
    login(usernameOrEmail: $usernameOrEmail, credentials: $credentials) {
      error
      token
      user {
        id
        name
        role {
          id
          name
        }
      }
    }
  }
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

// type Props = {
//   user: any,
//   setUser: any,
//   form: any
// };

class LoginForm extends Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  onError = error => {
    console.log("onError", error);
    // const err = betterError(error);
    // Modal.error({
    //   title: "Authentication error:",
    //   content: err || error.message,
    //   okButtonProps: { "data-testid": "dismiss-modal" }
    // });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    // Only show error after a field is touched.
    const usernameOrEmailError =
      isFieldTouched("usernameOrEmail") && getFieldError("usernameOrEmail");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");

    return (
      <Mutation
        mutation={
          process.env.REACT_APP_ADMIN ? LOGIN_MUTATION : REQUEST_LOGIN_MUTATION
        }
        onError={this.onError}
      >
        {(requestLoginMutation, { loading, client, ..._rest }) => {
          return (
            <Col style={{ width: 400, margin: "0 auto" }}>
              <Card>
                <Form
                  // layout="vertical"
                  onSubmit={e =>
                    process.env.REACT_APP_ADMIN
                      ? this.handleSubmitLogin(requestLoginMutation, e, client)
                      : this.handleSubmitRequestLogin(
                          requestLoginMutation,
                          e,
                          client
                        )
                  }
                >
                  <Form.Item
                    label="Username or email address"
                    // {...formItemLayout}
                    validateStatus={usernameOrEmailError ? "error" : ""}
                    help={usernameOrEmailError || ""}
                  >
                    {getFieldDecorator("usernameOrEmail", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your username or email!"
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Username or Email"
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    validateStatus={passwordError ? "error" : ""}
                    help={passwordError || ""}
                  >
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Password!"
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Password"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      disabled={loading || hasErrors(getFieldsError())}
                      data-testid="submit-button"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          );
        }}
      </Mutation>
    );
  }

  handleSubmitRequestLogin = async (loginMutation, e, client) => {
    e.preventDefault();

    this.setState({ client });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { usernameOrEmail, password } = values;

        const requestLoginResult = await loginMutation({
          variables: {
            usernameOrEmail,
            password: await sha256(password)
          }
        });

        if (!requestLoginResult) {
          message.error("Unknown error during login");
          return;
        }

        const { data } = requestLoginResult;
        const { requestLogin } = data;

        const state = {
          NONE: "none",
          CANCELED: "canceled",
          SUCCESS: "success",
          ERROR: "error"
        };

        let loginState = state.NONE;
        if (!requestLogin) {
          message.error("Error in login");
          return;
          // loginState = state.ERROR;
        }

        const { error, token, user, ...challenge } = requestLogin;
        if (user && token && !error) {
          this._saveUserData(token);
          this.props.setUser(user);
          this.props.history.push("/");
          return;
        }

        if (error) {
          message.error(error);
          return;
        }

        try {
          const credentials = await solveLoginChallenge(challenge);
          const loginResult = await client.mutate({
            mutation: LOGIN_MUTATION,
            variables: {
              usernameOrEmail,
              credentials
            }
          });
          const login =
            loginResult && loginResult.data && loginResult.data.login;
          if (!login || !login.user || !login.token) {
            loginState = state.ERROR;
          } else {
            loginState = state.SUCCESS;
            const { user, token, error } = login;
            if (error) {
              message.error(error);
            } else {
              this._saveUserData(token);
              this.props.setUser(user);
              this.props.history.push("/");
            }
          }
        } catch {
          loginState = state.CANCELED;
        }

        switch (loginState) {
          case state.NONE:
            throw new Error("invalid state: NONE");
          case state.SUCCESS:
            console.log("login with success");
            break;
          case state.ERROR:
            console.log("login with error");
            message.error("Error in login");
            break;
          case state.CANCELED:
            break;
          default:
            throw new Error("invalid state: ", state);
        }
      }
    });
  };

  handleSubmitLogin = async (loginMutation, e, client) => {
    e.preventDefault();

    this.setState({ client });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { usernameOrEmail, password } = values;

        const loginResult = await loginMutation({
          variables: {
            usernameOrEmail,
            password: await sha256(password)
          }
        });

        if (loginResult && loginResult.data && loginResult.data.login) {
          const { token, user } = loginResult.data.login;
          console.log("token: ", token);
          this._saveUserData(token);
          this.props.setUser(user);
          this.props.history.push("/");
        } else {
          message.error("Invalid username or password");
        }
      }
    });
  };

  _saveUserData = token => {
    localStorage.setItem(AUTH_TOKEN, token);
  };
}

const LoginView = Form.create()(LoginForm);

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  reload: state => dispatch(reload(state))
});

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginView);
