import * as R from "ramda";
import * as RA from "ramda-adjunct";

export const VERSION = 16;
export const AUTH_TOKEN = "auth-token";
export const LOCALSTORAGE_APP_STATE = "reapp:state";
export const GET_STATE_THROTTLE = 1000;
export const MAX_PROPERTIES = 1000;
export const SHOW_FOOTER = false;
export const HEIGHT_ADJUSTMENT = 340;

export const APARTAMENTOS = "Apartamentos";
export const MORADIAS = "Moradias";
export const LOJAS = "Lojas";
export const ESCRITORIOS = "Escritórios";
export const ARMAZENS = "Armazéns";
export const TERRENOS = "Terrenos";
export const OUTROS = "Outros";

export const COLORS = ["#005B82", "grey", "#FF8042", "#FFBB28"];

export const DB = {
  sites: {
    valuation: { id: 4, name: "Valuation" },
    cpcv: { id: 5, name: "CPCV" },
    notice: { id: 6, name: "Notice" },
    find: id => {
      switch (Number(id)) {
        case 4:
          return DB.sites.valuation;
        case 5:
          return DB.sites.cpcv;
        case 6:
          return DB.sites.notice;
        default:
          throw new Error("invalid sites value:" + id);
      }
    }
  },
  gps_types: {
    exact: { id: 1 }
  },
  purposes: {
    buy: { id: 1 }
  },
  types: {
    Home: {
      id: 1,
      name: "Home",
      House: { id: 1, name: "House" },
      Apartment: { id: 2, name: "Apartment" }
    },
    Office: {
      id: 2,
      name: "Office",
      Office: { id: 76, name: "Office" }
    },
    Garage: {
      id: 4,
      name: "Garage",
      Garage: { id: 58, name: "Garage" }
    },
    StorageRoom: {
      id: 5,
      name: "StorageRoom",
      StorageRoom: { id: 84, name: "StorageRoom" }
    },
    Building: {
      id: 6,
      name: "Building",
      Residential: { id: 13, name: "Residential" },
      MixedResidentialCommercial: {
        id: 18,
        name: "MixedResidentialCommercial"
      },
      MixedOfficeCommercial: { id: 29, name: "MixedOfficeCommercial" },
      Hotel: { id: 38, name: "Hotel" }
    },
    Premise: {
      id: 8,
      name: "Premise",
      CommercialProperty: { id: 41, name: "CommercialProperty" },
      IndustrialPremise: { id: 42, name: "IndustrialPremise" }
    },
    Land: {
      id: 9,
      name: "Land",
      UrbanPlot: { id: 67, name: "UrbanPlot" },
      RusticPlot: { id: 71, name: "RusticPlot" }
    },
    Other: {
      id: 12,
      name: "Other",
      Aerodromo: { id: 90, name: "Aerodromo e Pista Aviação" },
      ApartTime: { id: 91, name: "Apart.time-sharing" },
      Barragens: { id: 92, name: "Barragens" },
      Equitacao: { id: 93, name: "Campo Equitação" },
      Futebol: { id: 94, name: "Campo Futebol" },
      Golfe: { id: 95, name: "Campo Golfe" },
      MiniGolfe: { id: 96, name: "Campo Mini Golfe" },
      Tenis: { id: 97, name: "Campo Ténis" },
      CampoTiro: { id: 98, name: "Campo de Tiro" },
      Hospital: { id: 99, name: "Edificio Hospitalar" },
      Estadio: { id: 100, name: "Estádio Desportivo" },
      ETAR: { id: 101, name: "ETAR" },
      Igreja: { id: 102, name: "Igreja" },
      Lixeira: { id: 103, name: "Lixeira" },
      Marina: { id: 104, name: "Marina" },
      Moinho: { id: 105, name: "Moinho" },
      CampoDesportivo: { id: 106, name: "Outros Campos Desportivos" },
      Campismo: { id: 107, name: "Parque Campismo" },
      Gimnodesportivo: { id: 108, name: "Pavilhões Gimnodesportivos" },
      Pedreira: { id: 109, name: "Pedreiras" },
      Piscina: { id: 110, name: "Piscinas" },
      Pista: { id: 111, name: "Pistas Automóveis" },
      PracaTouros: { id: 112, name: "praça de Touros" }
    },
    ND: {
      id: 13,
      name: "ND",
      ND: { id: 82, name: "ND" }
    },
    findBySubtypeId: id => {
      const found = R.filter(type => {
        const f = R.filter(subtype => {
          if (!subtype) {
            return null;
          }
          return R.propEq("id", id, subtype);
        }, type);
        return !R.isEmpty(f);
      }, DB.types);
      return R.values(found)[0];
    }
  },
  subtypes: {
    find: id => {
      const found = R.values(
        R.map(type => {
          const f = R.filter(subtype => {
            if (!subtype) {
              return null;
            }
            return R.propEq("id", id, subtype);
          }, type);
          return R.isEmpty(f) ? null : R.values(f)[0];
        }, DB.types)
      );
      return RA.compact(found)[0];
    }
  },
  conditions: {
    New: {
      id: 1,
      name: "New"
    },
    Used: {
      id: 2,
      name: "Renew"
    },
    Renew: {
      id: 3,
      name: "Renew"
    },
    find: id => {
      switch (Number(id)) {
        case 1:
          return DB.conditions.New;
        case 2:
          return DB.conditions.Used;
        case 3:
          return DB.conditions.Renew;
        default:
          throw new Error("invalid condition value:" + id);
      }
    }
  }
};

export const RAV_TipoBemId = {
  1: { name: "Apartamento", subtype_id: DB.types.Home.Apartment.id },
  2: { name: "Vivenda", subtype_id: DB.types.Home.House.id },
  3: {
    name: "Espaço comercial",
    subtype_id: DB.types.Premise.CommercialProperty.id
  },
  4: { name: "Escritório", subtype_id: DB.types.Office.Office.id },
  5: {
    name: "Prédio",
    subtype_id: DB.types.Building.MixedResidentialCommercial.id
  },
  6: { name: "Armazém", subtype_id: DB.types.Premise.IndustrialPremise.id },
  7: {
    name: "Instalação Industrial",
    subtype_id: DB.types.Premise.IndustrialPremise.id
  },
  8: {
    name: "Terreno Rústico",
    subtype_id: DB.types.Land.RusticPlot.id
  },
  9: {
    name: "Prédio Misto",
    subtype_id: DB.types.Building.MixedResidentialCommercial.id
  },
  10: {
    name: "Loteamento",
    subtype_id: DB.types.Land.UrbanPlot.id
  },
  31: { name: "Hotéis", subtype_id: DB.types.Building.Hotel.id },
  32: { name: "Aparthoteis", subtype_id: DB.types.Home.Apartment.id },
  33: { name: "Casas de Madeira", subtype_id: DB.types.Home.House.id },
  34: {
    name: "Edificio Habitacional",
    subtype_id: DB.types.Building.Residential.id
  },
  35: {
    name: "Edificio de Comércio e Serviços",
    subtype_id: DB.types.Building.MixedOfficeCommercial.id
  },
  40: {
    name: "Lote de Terreno para Construção",
    subtype_id: DB.types.Land.UrbanPlot.id
  },
  41: {
    name: "Aerodromo e Pistas de Aviação",
    subtype_id: DB.types.Other.Aerodromo.id
  },
  42: { name: "Barranges", subtype_id: DB.types.Other.Barragens.id },
  43: { name: "Campo Equitação", subtype_id: DB.types.Other.Equitacao.id },
  44: { name: "Campo Golfe", subtype_id: DB.types.Other.Golfe.id },
  45: { name: "Campo Mini Golfe", subtype_id: DB.types.Other.MiniGolfe.id },
  46: { name: "Campo Ténis", subtype_id: DB.types.Other.Tenis.id },
  47: { name: "Campo Futebol", subtype_id: DB.types.Other.Futebol.id },
  48: {
    name: "Outros Campos Desportivos",
    subtype_id: DB.types.Other.CampoDesportivo.id
  },
  49: { name: "Estádio Desportivo", subtype_id: DB.types.Other.Estadio.id },
  50: {
    name: "Pavilhões Gimnodesportivos",
    subtype_id: DB.types.Other.Gimnodesportivo.id
  },
  51: { name: "Piscinas", subtype_id: DB.types.Other.Piscina.id },
  52: { name: "Pistas Automóveis", subtype_id: DB.types.Other.Pista.id },
  53: { name: "praça de Touros", subtype_id: DB.types.Other.PracaTouros.id },
  54: { name: "Campo de Tiro", subtype_id: DB.types.Other.CampoTiro.id },
  55: { name: "ETAR", subtype_id: DB.types.Other.ETAR.id },
  56: { name: "Igreja", subtype_id: DB.types.Other.Igreja.id },
  57: { name: "Lixeira", subtype_id: DB.types.Other.Lixeira.id },
  58: { name: "Marina", subtype_id: DB.types.Other.Marina.id },
  59: { name: "Pedreiras", subtype_id: DB.types.Other.Pedreira.id },
  60: { name: "Zonas Verdes", subtype_id: DB.types.Land.RusticPlot.id },
  61: {
    name: "Terrenos Aglom Urb N/Constr S/Afet Agricola",
    subtype_id: DB.types.Land.RusticPlot.id
  },
  62: {
    name: "Arrecadações e arrumos",
    subtype_id: DB.types.StorageRoom.StorageRoom.id
  },
  63: {
    name: "Garagens",
    subtype_id: DB.types.Garage.Garage.id
  },
  64: {
    name: "Moinho",
    subtype_id: DB.types.Other.Moinho.id
  },
  65: {
    name: "Adegas",
    subtype_id: DB.types.Premise.IndustrialPremise.id
  },
  66: { name: "Apart.time-sharing", subtype_id: DB.types.Other.ApartTime.id },
  67: { name: "Parque Campismo", subtype_id: DB.types.Other.Campismo.id },
  68: { name: "Edificio Hospitalar", subtype_id: DB.types.Other.Hospital.id },
  69: {
    name: "Parque de Estacionamento",
    subtype_id: DB.types.Garage.Garage.id
  }
};

export const RAV_Ocupacao = {
  1: { name: "Arrendado" },
  2: { name: "Devoluto" },
  5: { name: "Ocupado pelo Próprio" },
  8: { name: "Ocupado pelo Vendedor" },
  9: { name: "Outros" }
};

export const RAV_ConstEstadoConservacaoId = {
  6: {
    name: "Muito Bom",
    condition_id: DB.conditions.New.id
  },
  7: {
    name: "Bom",
    condition_id: DB.conditions.New.id
  },
  8: {
    name: "Razoável",
    condition_id: DB.conditions.Used.id
  },
  9: {
    name: "Fraco",
    condition_id: DB.conditions.Renew.id
  },
  10: {
    name: "Mau",
    condition_id: DB.conditions.Renew.id
  }
};

export const FileTypes = {
  RAV: "0.0",
  INDICADORES_MACROECONOMICOS: "1.0",
  POPULATION: "2.1",
  POPULATION_ESTIMATE: "2.2",
  FAMILIES: "2.3",
  ACCOMMODATION: "2.4",
  ACCOMMODATION_ESTIMATE: "2.5",
  OWNERS_VS_TENANTS: "2.6",
  PURCHASING_POWER: "3.0",
  LICENCIAMENTO: "4.1",
  OBRAS_CONCLUIDAS: "4.2",
  TRANSACOES: "5.0",
  RENT_VS_SALE: "6.0",
  RENT: "6.1"
};
