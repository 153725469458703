import React, { useState } from "react";
import {
  Upload,
  Icon,
  notification,
  Select,
  Table,
  Button,
  Spin,
  Modal
} from "antd";
import Papa from "papaparse";
import * as R from "ramda";
import {
  NULL_MUTATION,
  sites_withManualInsert_Query,
  updateIndicadoresMacroeconomicos,
  updateMacroLicenciamento,
  updateMacroObrasConcluidas,
  updateMacroTransacoes,
  updateMacroPopulation,
  updateMacroPopulationEstimate,
  updateMacroAccomodation,
  updateMacroAccomodationEstimate,
  updateMacroFamilies,
  updateMacroOwnersVsTenants,
  updateMacroPurchasingPower,
  updateMacroRentVsSale,
  updateMacroTotalRents,
  createPropertiesMutation
} from "./Queries";
import { useMutation } from "@apollo/react-hooks";
import { betterError } from "./util/GraphQL";
import { DropdownWithQueryClass } from "./DropdownWithQuery";
import { DB, FileTypes } from "./constants";
import JSZip from "jszip";
import {
  PropertiesToUpload,
  usePropertiesToUpload
} from "./PropertiesToUpload";
import { extractInfo } from "./RAV";

function parseNumber(number) {
  return Number(number.replace(/ /g, ""));
}

const DB_FIELDS = {
  anos: "year",
  ano: "year",
  "consumo privado": "consumo_privado",
  "taxa de desemprego": "taxa_desemprego",
  nota: "notas",
  municipio: "municipality",
  municipios: "municipality",
  concelhos: "municipality",
  concelho: "municipality",
  codigo: "code",
  codigos: "code",
  "t0 ou t1": "t0_t1",
  "t4 ou mais": "t4_more",

  urbanos: "urban",
  outros: "other",
  rusticos: "rustic",
  mistos: "mixed",
  "em propriedade horizontal": "horizontal_property",
  "populacao residente": "population",

  "total alojamentos": "total",
  "alojamentos de  residencia habitual": "main",
  "alojamentos de uso sazonal ou secundario": "secondary",
  "alojamentos vagos": "empty",

  "numero familias": "total",
  "familias unipessoais": "unipessoal",

  "proprietario ou coproprietario": "owners",
  "arrendatario ou subarrendatario": "tenants",
  "outras situacoes": "other",

  "poder de compra per capita": "purchasing_power",

  "valor mediano das rendas por m2 de novos contratos de arrendamento de alojamentos familiares":
    "median_rent",
  "valor mediano das vendas por m2 de alojamentos familiares": "median_sale",

  "novos contratos de arrendamento de alojamentos familiares": "total_rents"
};

Papa.parsePromise = function(
  file,
  config = {
    header: true,
    skipEmptyLines: true,
    // encoding: "CP1252",
    encoding: "ISO-8859-1",

    transformHeader: header => {
      const normalized = R.compose(
        R.toLower,
        R.trim
      )(header.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      // console.log("header (%o) = normalized (%o)", header, normalized);
      return DB_FIELDS[normalized] || normalized;
    }
  }
) {
  return new Promise(function(complete, error) {
    Papa.parse(file, {
      ...config,
      complete,
      error
    });
  });
};

const messageDuration = 10;

const convert_population = (data, maxLength) => {
  const { code, population, year } = data;
  if (code.length === maxLength) {
    const municipality_id = code.substring(maxLength - 4);
    return {
      year,
      municipality: { id: municipality_id },
      population: parseNumber(population)
    };
  } else {
    return null;
  }
};

const convert_accomodation = (data, maxLength) => {
  let { code, municipality, year, total, main, secondary, empty } = data;
  if (code.length === maxLength) {
    const municipality_id = code.substring(maxLength - 4);
    total = parseNumber(total);
    main = parseNumber(main);
    secondary = parseNumber(secondary);
    empty = parseNumber(empty);
    if (total === main + secondary + empty) {
      return {
        year,
        municipality: { id: municipality_id },
        main,
        secondary,
        empty
      };
    } else {
      throw new Error(
        `municipality ${code} - "${municipality}": total should be equal to main + secondary + empty`
      );
    }
  } else {
    return null;
  }
};

const convert_accomodation_estimate = (data, maxLength) => {
  let { code, year, total } = data;
  if (code.length === maxLength) {
    const municipality_id = code.substring(maxLength - 4);
    total = parseNumber(total);
    return {
      year,
      municipality: { id: municipality_id },
      total
    };
  } else {
    return null;
  }
};

const convert_owners_vs_tenants = (data, maxLength) => {
  let { code, municipality, year, total, owners, tenants, other } = data;
  if (code.length === maxLength) {
    const municipality_id = code.substring(maxLength - 4);
    total = parseNumber(total);
    owners = parseNumber(owners);
    tenants = parseNumber(tenants);
    other = parseNumber(other);
    if (total === owners + tenants + other) {
      return {
        year,
        municipality: { id: municipality_id },
        owners,
        tenants,
        other
      };
    } else {
      throw new Error(
        `municipality ${code} - "${municipality}": total should be equal to main + secondary + empty`
      );
    }
  } else {
    return null;
  }
};

const convert_families = data => {
  const { code, year, total, unipessoal } = data;
  if (code.length === 4) {
    return {
      year,
      municipality: { id: code },
      total: parseNumber(total),
      unipessoal: parseNumber(unipessoal)
    };
  } else {
    return null;
  }
};

const convert_licenciamento_obrasConcluidas = data => {
  const { year, code, t0_t1, t2, t3, t4_more } = data;
  if (code.length === 7) {
    const municipality_id = code.substring(3);
    return {
      year,
      municipality: { id: municipality_id },
      t0_t1: parseNumber(t0_t1),
      t2: parseNumber(t2),
      t3: parseNumber(t3),
      t4_more: parseNumber(t4_more)
    };
  } else {
    return null;
  }
};

const convert_transacoes = data => {
  const { year, code, horizontal_property, other, rustic, mixed } = data;
  if (code.length === 7) {
    const municipality_id = code.substring(3);
    return {
      year,
      municipality: { id: municipality_id },
      horizontal_property: parseNumber(horizontal_property),
      other: parseNumber(other),
      rustic: parseNumber(rustic),
      mixed: parseNumber(mixed)
    };
  } else {
    return null;
  }
};

const convert_purchasing_power = (data, maxLength) => {
  let { code, year, purchasing_power } = data;
  if (code.length === maxLength) {
    const municipality_id = code.substring(maxLength - 4);
    purchasing_power = parseFloat(purchasing_power.replace(/,/g, "."));
    return {
      year,
      municipality: { id: municipality_id },
      purchasing_power
    };
  } else {
    return null;
  }
};

const getParent = string => {
  if (string === "") {
    return "PT";
  } else {
    const last = string[string.length - 1];
    if (last !== "0") {
      return string;
    } else {
      return getParent(string.substring(0, string.length - 1));
    }
  }
};

const convert_rent_vs_sale = (data, maxLength) => {
  let { code, year, median_rent, median_sale, municipality: name } = data;
  median_rent = parseFloat(median_rent.replace(/,/g, "."));
  median_sale = parseFloat(median_sale.replace(/,/g, "."));
  if (code.length === maxLength) {
    const municipality_id = code.substring(maxLength - 4);
    const parent = getParent(code.substring(0, maxLength - 4));
    return {
      year,
      code,
      municipality: { id: municipality_id },
      parent,
      median_rent,
      median_sale,
      name: null
    };
  } else if (code.length <= maxLength - 4) {
    return {
      year,
      code,
      municipality: null,
      parent:
        code === "PT"
          ? null
          : getParent(String(code.substring(0, code.length - 1))),
      median_rent,
      median_sale,
      name
    };
  } else {
    return null;
  }
};

const convert_total_rents = (data, maxLength) => {
  let { code, year, total_rents, municipality: name } = data;
  total_rents = parseNumber(total_rents);
  if (code.length === maxLength) {
    const municipality_id = code.substring(maxLength - 4);
    const parent = getParent(code.substring(0, maxLength - 4));
    return {
      year,
      code,
      municipality: { id: municipality_id },
      parent,
      total_rents,
      name: null
    };
  } else if (code.length <= maxLength - 4) {
    return {
      year,
      code,
      municipality: null,
      parent:
        code === "PT"
          ? null
          : getParent(String(code.substring(0, code.length - 1))),
      total_rents,
      name
    };
  } else {
    return null;
  }
};

const convertIndicadoresMacroeconomicos = data => {
  return R.mapObjIndexed((val, key, obj) => {
    switch (key) {
      case "pib":
      case "ihpc":
      case "consumo_privado":
      case "taxa_desemprego":
        return parseFloat(val.replace(/,/g, "."));
      default:
        return val;
    }
  }, data);
};

const convertRAV = p => {
  const { file, type, parish, ...newProperty } = p;
  // remove parish.name
  const x = { parish: { id: parish.id }, ...newProperty };
  return x;
};

const K = FileTypes;

const headers_indicadores_macroeconomicos = [
  "year",
  "pib",
  "ihpc",
  "consumo_privado",
  "taxa_desemprego",
  "notas"
];

const headers_licenciamento_obrasConcluidas = [
  "municipality",
  "code",
  "year",
  "total",
  "t0_t1",
  "t2",
  "t3",
  "t4_more"
];

const headers_transacoes = [
  "municipality",
  "code",
  "year",
  "total",
  "urban",
  "horizontal_property",
  "other",
  "rustic",
  "mixed"
];

const headers_population = ["municipality", "code", "year", "population"];

const headers_families = [
  "municipality",
  "code",
  "year",
  "total",
  "unipessoal"
];

const headers_accomodation = [
  "municipality",
  "code",
  "year",
  "total",
  "main",
  "secondary",
  "empty"
];

const headers_accomodation_estimate = ["municipality", "code", "year", "total"];

const headers_owners_vs_tenants = [
  "municipality",
  "code",
  "year",
  "total",
  "owners",
  "tenants",
  "other"
];

const headers_purchasing_power = [
  "municipality",
  "code",
  "year",
  "purchasing_power"
];

const headers_rent_vs_sale = [
  "municipality",
  "code",
  "year",
  "median_rent",
  "median_sale"
];
const headers_rent = ["municipality", "code", "year", "total_rents"];

const key_code_year = row => `${row["code"]}.${row["year"]}`;
const validate_subcodes_length = (maxLength, d) =>
  d.code && (d.code.length === maxLength || d.code.length <= maxLength - 4);
const validate_code_length = (maxLength, d) =>
  d.code && d.code.length === maxLength;

const TYPES = {
  [K.RAV]: {
    name: "Ficheiro RAV",
    mutation: createPropertiesMutation,
    convert: convertRAV
  },
  [K.INDICADORES_MACROECONOMICOS]: {
    name: "1. Indicadores Macroeconómicos",
    key: "year",
    convert: convertIndicadoresMacroeconomicos,
    headers: headers_indicadores_macroeconomicos,
    mutation: updateIndicadoresMacroeconomicos
  },
  [K.POPULATION]: {
    name: "2. População Residente",
    key: key_code_year,
    headers: headers_population,
    validate: validate_code_length,
    mutation: updateMacroPopulation,
    convert: convert_population
  },
  [K.POPULATION_ESTIMATE]: {
    name: "2. População Residente (estimativa)",
    key: key_code_year,
    headers: headers_population,
    validate: validate_code_length,
    mutation: updateMacroPopulationEstimate,
    convert: convert_population
  },
  [K.FAMILIES]: {
    name: "2. Familias",
    key: key_code_year,
    headers: headers_families,
    validate: validate_code_length,
    mutation: updateMacroFamilies,
    convert: convert_families
  },
  [K.ACCOMMODATION]: {
    name: "2. Alojamento",
    key: key_code_year,
    headers: headers_accomodation,
    validate: validate_code_length,
    mutation: updateMacroAccomodation,
    convert: convert_accomodation
  },
  [K.ACCOMMODATION_ESTIMATE]: {
    name: "2. Alojamento (estimativa)",
    key: key_code_year,
    headers: headers_accomodation_estimate,
    validate: validate_code_length,
    mutation: updateMacroAccomodationEstimate,
    convert: convert_accomodation_estimate
  },
  [K.OWNERS_VS_TENANTS]: {
    name: "2. Proprietário vs. Arrendatário",
    key: key_code_year,
    headers: headers_owners_vs_tenants,
    validate: validate_code_length,
    mutation: updateMacroOwnersVsTenants,
    convert: convert_owners_vs_tenants
  },
  [K.PURCHASING_POWER]: {
    name: "3. Poder de Compra",
    key: key_code_year,
    headers: headers_purchasing_power,
    validate: validate_code_length,
    codeLenghth: 7,
    mutation: updateMacroPurchasingPower,
    convert: convert_purchasing_power
  },
  [K.LICENCIAMENTO]: {
    name: "4. Licenciamento",
    key: key_code_year,
    convert: convert_licenciamento_obrasConcluidas,
    validate: validate_code_length,
    headers: headers_licenciamento_obrasConcluidas,
    mutation: updateMacroLicenciamento
  },
  [K.OBRAS_CONCLUIDAS]: {
    name: "4. Obras Concluidas",
    key: key_code_year,
    convert: convert_licenciamento_obrasConcluidas,
    validate: validate_code_length,
    codeLenghth: 7,
    headers: headers_licenciamento_obrasConcluidas,
    mutation: updateMacroObrasConcluidas
  },
  [K.TRANSACOES]: {
    name: "5. Transações",
    key: key_code_year,
    convert: convert_transacoes,
    validate: validate_code_length,
    codeLenghth: 7,
    headers: headers_transacoes,
    mutation: updateMacroTransacoes
  },
  [K.RENT_VS_SALE]: {
    name: "6. Arrendamento vs Venda",
    key: key_code_year,
    headers: headers_rent_vs_sale,
    validate: validate_subcodes_length,
    codeLenghth: 7,
    mutation: updateMacroRentVsSale,
    convert: convert_rent_vs_sale
  },
  [K.RENT]: {
    name: "6. Número de Arrendamentos",
    key: key_code_year,
    headers: headers_rent,
    validate: validate_subcodes_length,
    codeLenghth: 7,
    mutation: updateMacroTotalRents,
    convert: convert_total_rents
  }
};
const MAX_PER_PAGE = 1000;

const determineType = headers => {
  const found = R.filter(type => R.equals(type.headers, headers), TYPES);
  const types = R.keys(found);
  return types;
};

const maxCodeLength = data => {
  const codeLengthArray = data.map(d => {
    const { code } = d;
    if (!code) {
      return 0;
    } else {
      return code.length;
    }
  });
  const maxCodeLength = Math.max(...codeLengthArray);
  return maxCodeLength;
};
const readFileContents = async (file, type) => {
  if (type === K.RAV) {
    const zip = await JSZip.loadAsync(file);

    let entries = [];
    zip.forEach(function(_relativePath, zipEntry) {
      entries = [...entries, zipEntry];
    });
    let properties = [];
    await Promise.all(
      entries.map(async entry => {
        const content = await entry.async("string");
        const data = JSON.parse(content);
        const newProperties = extractInfo(data).map(p => ({
          ...p,
          file: file.name
        }));
        properties = [...properties, ...newProperties];
      })
    );
    return { type, data: properties };
  }
  const {
    data,
    // meta,
    errors,
    meta: { fields }
  } = await Papa.parsePromise(file);

  const validFields = R.reject(R.isEmpty, fields);
  const maxLength = TYPES[type].codeLenghth || maxCodeLength(data);
  const validData = R.reject(
    R.isNil,
    R.map(d => {
      const { "": empty, ...validData } = d;
      const alwaysTrue = () => true;
      const validateMethod = TYPES[type].validate || alwaysTrue;
      const valid = R.partial(validateMethod, [maxLength])(validData);
      return valid ? validData : null;
    }, data)
  );

  // console.log("fields", fields);
  // console.log("validFields", validFields);
  // console.log("data", data);
  // console.log("validData", validData);

  if (errors.length > 0) {
    console.log("Errors array should not empty. Found", errors);
    throw new Error("Errors array should not empty");
  }

  const possibleTypes = determineType(validFields);
  if (!R.includes(type, possibleTypes)) {
    console.log("cound not find valid type for headers: ", validFields);
    console.log("type (%o) == possible (%o)", type, possibleTypes);
    throw new Error(`File is not of type '${TYPES[type].name}'`);
  }

  if (R.isEmpty(validData)) {
    throw new Error(`No valid rows found in file`);
  }

  // console.log("data", validData);
  return { type, data: validData, fields: validFields };
};

const SelectTable = ({ onChange }) => {
  const types1 = null;
  // const types1 = Object.keys(TYPES)
  //   .filter(k => k === K.RAV)
  //   .map(key => (
  //     <Select.Option value={key} key={key}>
  //       {TYPES[key].name}
  //     </Select.Option>
  //   ));
  const types2 = Object.keys(TYPES)
    .filter(k => k !== K.RAV)
    .map(key => (
      <Select.Option value={key} key={key}>
        {TYPES[key].name}
      </Select.Option>
    ));
  return (
    <div style={{ display: "inline-block", padding: "20px" }}>
      <label>Table: </label>
      <Select
        style={{ width: 250 }}
        placeholder="Type"
        onChange={onChange}
        allowClear={true}
      >
        <Select.OptGroup label="RAV">{types1}</Select.OptGroup>
        <Select.OptGroup label="Statistics">{types2}</Select.OptGroup>
      </Select>
    </div>
  );
};

const SelectSource = ({ onChange, source }) => {
  return (
    <div style={{ display: "inline-block", padding: "20px" }}>
      <label>Source: </label>
      <DropdownWithQueryClass
        mode="default"
        queryName="sites"
        query={sites_withManualInsert_Query}
        placeholder="Valuation / CPCV / Notice"
        allowClear={false}
        value={source}
        onChange={onChange}
      />
    </div>
  );
};

const DisplayTable = ({ contents }) => {
  const { type, data, fields } = contents;
  const columns = fields.map(fieldName => ({
    title: fieldName,
    dataIndex: fieldName,
    key: fieldName
  }));
  const key = TYPES[type].key;
  const pagination =
    data.length > MAX_PER_PAGE
      ? {
          pageSize: MAX_PER_PAGE
        }
      : false;

  return (
    <div style={{ padding: "20px" }}>
      <Table
        dataSource={data}
        columns={columns}
        pagination={pagination}
        rowKey={key}
      />
    </div>
  );
};

export const AddFile = ({ type: selectedType }) => {
  const defaultSubtype = String(DB.sites.valuation.id);

  const emptyContents = { type: null, data: [], fields: [] };
  const [contents, setContents] = useState(emptyContents);
  const [type, setType] = useState(selectedType);
  const [subtype, setSubtype] = useState(defaultSubtype);
  const [spinning, setSpinning] = useState(false);

  const currentMutation = type ? TYPES[type].mutation : NULL_MUTATION;

  const [mutation] = useMutation(currentMutation);

  const propertiesState = usePropertiesToUpload({
    properties: type === K.RAV ? contents.data : null,
    source: subtype
  });

  notification.config({
    duration: messageDuration
  });

  const data =
    type === K.RAV ? propertiesState.propertiesWithSource : contents.data;

  return (
    <Spin tip="Loading..." spinning={spinning}>
      {!selectedType && (
        <SelectTable
          onChange={type => {
            setContents(emptyContents);
            setType(type || null);
            setSubtype(defaultSubtype);
          }}
        />
      )}
      {type && type === K.RAV && (
        <SelectSource
          source={subtype}
          onChange={subtype => {
            // setContents(emptyContents);
            setSubtype(subtype || null);
          }}
        />
      )}
      <Button
        style={{ display: "inline-block" }}
        type="primary"
        htmlType="submit"
        disabled={R.isEmpty(data)}
        onClick={async () => {
          setSpinning(true);
          try {
            const maxLength = TYPES[type].codeLenghth || maxCodeLength(data);
            const convertMethod = TYPES[type].convert || R.identity;
            const values = R.reject(
              R.isNil,
              R.map(R.partialRight(convertMethod, [maxLength]), data)
            );
            await mutation({
              variables: {
                data: values
              }
            });
            Modal.success({
              title: "Table updated with success."
            });
          } catch (error) {
            Modal.error({
              title: "ERROR during table update:",
              content: betterError(error)
            });
          }
          setContents(emptyContents);
          setSpinning(false);
        }}
      >
        Submit
      </Button>
      {/* </Col> */}
      {type && (
        <Upload.Dragger
          showUploadList={false}
          disabled={!type}
          beforeUpload={file => {
            readFileContents(file, type)
              .then(contents => {
                setContents(contents);
              })
              .catch(error => {
                notification.error({
                  message: "Messages:",
                  description: `Error parsing file "${file.name}". ${error}`
                });
              });
            return false;
          }}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      )}
      {data.length > 0 ? (
        type === K.RAV ? (
          <PropertiesToUpload propertiesWithState={propertiesState} />
        ) : (
          <DisplayTable contents={contents} />
        )
      ) : null}
    </Spin>
  );
};
