import React from "react";
import {
  BarChart,
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card, Row, Col } from "antd";
import * as R from "ramda";
import { COLORS } from "./constants";

export const MacroTransacoes = ({ data }) => {
  const percent_data = data.map((y) => {
    const year_total = y.horizontal_property + y.other;
    const percent_horizontal_property =
      (y.horizontal_property / year_total) * 100;
    return {
      ...y,
      percent_horizontal_property,
      percent_other: 100 - percent_horizontal_property,
    };
  });
  const numberOfYears = data.length;

  const total_transactions = R.reduce(
    (acc, elem) => {
      return {
        Outros: acc["Outros"] + elem.other,
        "Propriedade Horizontal":
          acc["Propriedade Horizontal"] + elem.horizontal_property,
        "Total Imóveis Urbanos": acc["Total Imóveis Urbanos"] + elem.urban,
      };
    },
    { Outros: 0, "Propriedade Horizontal": 0, "Total Imóveis Urbanos": 0 },
    data
  );
  const y = R.map((x) => {
    return Number((x / numberOfYears).toFixed(0));
  }, total_transactions);
  const median_transactions = R.toPairs(y).map((p) => {
    const [name, value] = p;
    return { name, value };
  });

  return (
    <Card style={{ width: "100%" }}>
      <h2 style={{ textAlign: "center" }}>Transações</h2>

      <Row type="flex" justify="center" align="middle">
        <Col span={7} style={{ width: 700 }}>
          <Row>
            <BarChart width={600} height={250} data={data} barGap={-5}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="urban"
                name="Prédios Urbanos"
                fill={COLORS[0]}
                // label
              />
            </BarChart>
          </Row>
          <Row>
            <BarChart width={600} height={250} data={percent_data} barGap={-5}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis unit="%" />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="percent_horizontal_property"
                name="Propriedade Horizontal"
                fill={COLORS[0]}
                stackId="a"
              />
              <Bar
                dataKey="percent_other"
                name="Outros"
                fill={COLORS[1]}
                stackId="a"
              />
            </BarChart>
          </Row>
        </Col>
        <Col span={3} style={{ width: 300 }}>
          <h3 style={{ textAlign: "center" }}>Número médio de Transações</h3>
          <ComposedChart
            width={300}
            height={250}
            data={median_transactions}
            margin={{ top: 0, right: 35, left: 25, bottom: 0 }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            {/* <XAxis dataKey="name" /> */}
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" />
            <Tooltip />
            <Bar
              dataKey="value"
              barSize={60}
              fill={COLORS[0]}
              label={{ position: "right" }}
              isAnimationActive={false}
            />
          </ComposedChart>
        </Col>
      </Row>
    </Card>
  );
};
