export function median(data) {
  return quartile50(data);
}

export function quartile25(data) {
  return quartile(data, 0.25);
}

export function quartile50(data) {
  return quartile(data, 0.5);
}

export function quartile75(data) {
  return quartile(data, 0.75);
}

export function quartile(data, q) {
  data = arraySortNumbers(data);
  var pos = (data.length - 1) * q;
  var base = Math.floor(pos);
  var rest = pos - base;
  if (data[base + 1] !== undefined) {
    return data[base] + rest * (data[base + 1] - data[base]);
  } else {
    return data[base];
  }
}

export function arraySortNumbers(inputarray) {
  return inputarray.sort(function(a, b) {
    return a - b;
  });
}

export function arraySum(t) {
  return t.reduce(function(a, b) {
    return a + b;
  }, 0);
}

export function average(data) {
  return arraySum(data) / data.length;
}

export function sampleStandardDeviation(data) {
  const n = data.length;
  if (n < 2) {
    return null;
  } else {
    const _average = average(data);
    const reducer = (acc, current) => acc + Math.pow(current - _average, 2);
    const sum = data.reduce(reducer, 0);
    const result = Math.sqrt((1 / (n - 1)) * sum);
    return result;
  }
}
export function standardDeviation(tab) {
  var i,
    j,
    total = 0,
    mean = 0,
    diffSqredArr = [];
  for (i = 0; i < tab.length; i += 1) {
    total += tab[i];
  }
  mean = total / tab.length;
  for (j = 0; j < tab.length; j += 1) {
    diffSqredArr.push(Math.pow(tab[j] - mean, 2));
  }
  return Math.sqrt(
    diffSqredArr.reduce(function(firstEl, nextEl) {
      return firstEl + nextEl;
    }) / tab.length
  );
}
