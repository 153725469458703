import React, { useState } from "react";
import { Card, Drawer, Button, Icon } from "antd";

import FilterBySitesContainer from "./containers/FilterBySitesContainer";
import { FilterByCondition } from "./FilterByCondition";
import FilterByTypologiesContainer from "./containers/FilterByTypologiesContainer";
import FilterByTypeContainer from "./containers/FilterByTypeContainer";
import FilterBySubtypeContainer from "./containers/FilterBySubtypeContainer";
import FilterByPurposeContainer from "./containers/FilterByPurposeContainer";
import FilterByPriceContainer from "./containers/FilterByPriceContainer";
import FilterByAreaContainer from "./containers/FilterByAreaContainer";
// import { FilterWithGpsContainer } from "./containers/FilterWithGpsContainer";
// import { FilterSelectedContainer } from "./containers/FilterSelectedContainer";
import {
  FilterByDateContainer,
  FilterByLastCrawlDateContainer
} from "./containers/FilterByDateContainer";
import { FilterPriceChangeContainer } from "./containers/FilterPriceChangeContainer";
import FilterAgencyContainer from "./containers/FilterAgencyContainer";

import { InputPropertiesPerPageContainer } from "./containers/InputPropertiesPerPageContainer";
import { InputMaxPropertiesContainer } from "./containers/InputMaxPropertiesContainer";
import { InputRadiusContainer } from "./containers/InputRadiusContainer";
import { ResetContainer } from "./containers/ResetContainer";
import { PropertyFilters2 } from "./PropertyFilters2";

const style1 = { width: "20%", height: "40px" };
const style2 = { width: "20%", height: "40px" };
const style3 = { width: "35%", height: "40px" };
const style4 = { width: "25%", height: "40px" };

const PropertyFilters = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => {
    setShowDrawer(v => !v);
  };

  const hoverable = false;

  return (
    <div style={{ minWidth: "1100px" }}>
      <Card style={{ zIndex: 100 }} size="small">
        <Card.Grid style={style1} hoverable={hoverable}>
          <FilterByPurposeContainer selection />
        </Card.Grid>
        <Card.Grid style={style2} hoverable={hoverable}>
          <FilterBySitesContainer selection />
        </Card.Grid>
        <Card.Grid style={style3} hoverable={hoverable}>
          <FilterByPriceContainer selection />
        </Card.Grid>
        <Card.Grid style={style4} hoverable={hoverable}>
          <InputPropertiesPerPageContainer />
        </Card.Grid>

        <Card.Grid style={style1} hoverable={hoverable}>
          <FilterPriceChangeContainer />
        </Card.Grid>
        <Card.Grid style={style2} hoverable={hoverable}>
          <FilterByTypologiesContainer />
        </Card.Grid>
        <Card.Grid style={style3} hoverable={hoverable}>
          <FilterByAreaContainer selection />
        </Card.Grid>
        <Card.Grid style={style4} hoverable={hoverable}>
          <InputMaxPropertiesContainer />
        </Card.Grid>

        <Card.Grid style={style1} hoverable={hoverable}>
          <FilterAgencyContainer />
        </Card.Grid>
        <Card.Grid style={style2} hoverable={hoverable}>
          <FilterByTypeContainer selection />
        </Card.Grid>
        <Card.Grid style={style3} hoverable={hoverable}>
          <FilterByDateContainer /> (date of crawl)
        </Card.Grid>
        <Card.Grid style={style4} hoverable={hoverable}>
          <InputRadiusContainer />
        </Card.Grid>

        <Card.Grid style={style1} hoverable={hoverable}>
          <FilterByCondition selection />
        </Card.Grid>
        <Card.Grid style={style2} hoverable={hoverable}>
          <FilterBySubtypeContainer />
        </Card.Grid>
        <Card.Grid style={style3} hoverable={hoverable}>
          <FilterByLastCrawlDateContainer /> (last crawl)
        </Card.Grid>
        <Card.Grid style={style4} hoverable={hoverable}>
          <ResetContainer />
          <Button
            size="small"
            type="dashed"
            onClick={toggleDrawer}
            style={{ marginLeft: "20px" }}
          >
            <Icon type={showDrawer ? "caret-up" : "caret-down"} />
          </Button>
        </Card.Grid>
      </Card>
      <Drawer
        visible={showDrawer}
        placement="top"
        onClose={toggleDrawer}
        getContainer={false}
        closable={false}
        style={{ position: "relative", zIndex: 90 }}
      >
        <PropertyFilters2 />
      </Drawer>
    </div>
  );
};

export default PropertyFilters;
