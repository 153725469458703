import React from "react";
import { Query } from "./Query";
import { Switch, Button, Icon, List } from "antd";
import { SHOW_FOOTER, HEIGHT_ADJUSTMENT } from "./constants";
import memoize from "memoize-one";

import GoogleMaps, {
  Map,
  Marker,
  InfoWindow,
  Polygon
} from "@wapps/react-google-maps";
import { mapsQuery, allPropertiesCoefficientsQuery } from "./Queries";

import { formatPrice, formatArea } from "./util/Formatter";

const MAX_PROPERTIES_IN_INFOWINDO = 1;
const REMOVE_HEIGHT = SHOW_FOOTER
  ? HEIGHT_ADJUSTMENT + 100 - 80
  : HEIGHT_ADJUSTMENT - 80;

function makeIcon({ fillColor, scale }) {
  if (!fillColor || !scale) return null;

  const icon = {
    path:
      "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
    fillColor: fillColor,
    fillOpacity: 0.7,
    scale: scale,
    strokeColor: "white",
    strokeWeight: 1,
    labelOrigin: { x: 0, y: -25 }
  };
  return icon;
}

const getCentroid = property => {
  const { district, municipality, parish } = property;
  const getCentroidOfLocation = location => {
    if (!location) return null;
    const { centroid } = location;
    const { coordinates } = JSON.parse(centroid);
    return { lat: coordinates[1], lng: coordinates[0] };
  };

  return (
    getCentroidOfLocation(parish) ||
    getCentroidOfLocation(municipality) ||
    getCentroidOfLocation(district)
  );
};

const propertyPosition = property => {
  const { lat, lng } = property;
  if (lat && lng) {
    return { lat, lng };
  } else {
    return getCentroid(property);
  }
};

const CrawlInfo = ({
  property,
  actions,
  onChangeSelected,
  onSetCenter,
  selectedProperties,
  selectField,
  centerField,
  client
}) => {
  const selectedPropertiesIds = Object.keys(selectedProperties);
  const isSelected = selectedPropertiesIds.includes(property.id);
  const {
    area,
    number_of_bedrooms,
    type,
    subtype,
    url,
    site_ref
  } = property || {
    type: {},
    subtype: {}
  };
  const typology = number_of_bedrooms && `T${number_of_bedrooms}`;
  return (
    <div>
      <h3>
        {typology ? `${typology}: ` : ""}
        {type.name} ({subtype.name})
      </h3>
      <h4>
        <b>area:</b> {formatArea(area)}
      </h4>
      <h4>
        <b>price:</b> {formatPrice(property.price)}
      </h4>
      <h4>
        <b>Ref: </b>
        <a href={url} rel="noopener noreferrer" target="_blank">
          {site_ref}
        </a>
      </h4>
      {actions &&
        selectField({
          propertyId: property.id,
          selectedPropertiesIds,
          isSelected,
          client,
          onChangeSelected
        })}
      {actions &&
        centerField({
          obj: { ...property, property_id: property.id },
          onSetCenter
        })}
    </div>
  );
};

export class PropertiesMap extends React.Component {
  state = {
    activeId: null,
    showInfo: false,
    width: 0,
    height: 0
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  // Re-run getCenter when location (disitrict/municipality/...) props changed
  getMemoizedCenter = memoize((district, municipality, parish) => {
    return getCentroid({ district, municipality, parish });
  });

  getPlolygonsFromGeoJson = parish => {
    if (!parish) return null;

    const { geojson } = parish;
    const geom = JSON.parse(geojson);
    let { type, coordinates } = geom;
    if (type === "Polygon") {
      coordinates = [coordinates];
    }

    const regions = coordinates.reduce((a, b) => [...a, ...b], []);

    const polygons = regions.map(region => {
      const coords = region.map(coords => {
        return { lat: coords[1], lng: coords[0] };
      });
      return coords;
    });

    return polygons.map((coords, idx) => {
      return (
        <Polygon
          key={idx}
          paths={coords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#0000FF"
          fillOpacity={0.35}
          onClick={this.onPolygonClick}
          zIndex={-10}
        />
      );
    });
  };

  getZoom = ({ district, municipality, parish }) => {
    return parish ? 13 : municipality ? 11 : district ? 9 : 5;
  };

  updateState = data => {
    if (!data) {
      console.log("NO DATA, should handle");
      return null;
    }
    const { parish, municipality, district } = data;

    if (!this.props.zoom) {
      const zoom = this.getZoom(data);
      this.props.onZoomChange(zoom);
    }
    if (!this.props.center) {
      const searchFor = this.props.searchFor && this.props.searchFor.searchFor;
      const center =
        searchFor && searchFor.type === "GPS"
          ? searchFor
          : this.getMemoizedCenter(district, municipality, parish);

      this.props.onCenterChange({
        lat: () => center.lat,
        lng: () => center.lng
      });
    }
  };

  onMarkerClick = (properties, id, _marker, _event) => {
    this.setState(
      (prevState, props) => ({
        activeId: null,
        showInfo: id === props.highlightedProperty ? false : true,
        properties
      }),
      () => {
        this.props.onRowClick(id);
      }
    );
  };

  onMarkerIn = (properties, id, marker) => {
    if (!this.state.showInfo) {
      this.setState({
        activeId: id
      });
    }
  };
  onMarkerOut = (props, marker, e) => {
    if (!this.state.showInfo) {
      this.setState({
        activeId: null
      });
    }
  };

  clearInfowWindow = () => {
    if (this.props.highlightedProperty) {
      this.setState({
        activeId: null,
        showInfo: false
      });
    }
  };

  selectProperty = (id, selected, previousSelectedIds) => {
    const isAlreadySelected = previousSelectedIds.includes(id);
    if (selected) {
      if (!isAlreadySelected) {
        return [...previousSelectedIds, id];
      }
    } else {
      if (isAlreadySelected) {
        return previousSelectedIds.filter(elem => elem !== id);
      }
    }
    return previousSelectedIds;
  };

  onMapClick = () => this.clearInfowWindow();

  onPolygonClick = () => this.clearInfowWindow();

  onInfoWindowClose = () => {
    this.clearInfowWindow();
  };

  selectField = ({
    propertyId,
    selectedPropertiesIds,
    isSelected,
    client,
    onChangeSelected
  }) => (
    <h4>
      <b>Selelect: </b>
      <Switch
        defaultChecked={isSelected}
        onChange={checked => {
          const selectedIds = this.selectProperty(
            propertyId,
            checked,
            selectedPropertiesIds
          );
          const sortedSelectedIds = selectedIds.concat().sort();
          client.query({
            query: allPropertiesCoefficientsQuery,
            variables: {
              where: {
                purpose: this.props.where.purpose,
                property_id_in: sortedSelectedIds
              }
            }
          });

          return onChangeSelected(selectedIds);
        }}
      />
    </h4>
  );

  centerField = ({ obj, onSetCenter }) => {
    return obj.lat ? (
      <h4>
        <b>Center: </b>
        <Button
          type="ghost"
          onClick={event => {
            event.stopPropagation();
            onSetCenter(obj);
          }}
        >
          <Icon type="environment-o" />
        </Button>
      </h4>
    ) : null;
  };

  render() {
    const {
      maxProperties,
      where,
      selectedProperties,
      highlightedProperty,
      filterSelected,
      geometryWhere
    } = this.props;

    const selectedPropertiesIds = Object.keys(selectedProperties);

    const property_id = where && where.property && where.property.id;
    if (!property_id && !geometryWhere) return null;

    let parishWhere;
    let municipalityWhere;
    let districtWhere;
    let withParish = false;
    let withMunicipality = false;
    let withDistrict = false;
    if (property_id) {
      parishWhere = { property_id: where.property.id };
      municipalityWhere = { property_id: where.property.id };
      districtWhere = { property_id: where.property.id };
      withParish = true;
      withMunicipality = true;
      withDistrict = true;
    } else {
      parishWhere = geometryWhere.parish || {};
      municipalityWhere = geometryWhere.municipality || {};
      districtWhere = geometryWhere.district || {};
      withParish = !!geometryWhere.parish;
      withMunicipality = !!geometryWhere.municipality;
      withDistrict = !!geometryWhere.district;
    }

    const { property, ...other } = where;
    const propertiesWhere = { ...other, ...property };

    // console.log("height", this.state.height);
    return (
      <Query
        query={mapsQuery}
        onCompleted={this.updateState}
        variables={{
          first: maxProperties,
          where: propertiesWhere,
          parishWhere,
          municipalityWhere,
          districtWhere,
          withParish,
          withMunicipality,
          withDistrict
        }}
      >
        {({ loading, error, data, client }) => {
          if (loading) {
            return this.props.center && this.props.zoom ? (
              <GoogleMaps
                api={{ key: "AIzaSyDGusUSxPkWCM_qlJEmLl5BbfaWZr1Eetc" }}
              >
                <div
                  style={{
                    height: this.state.height - REMOVE_HEIGHT,
                    width: "100wh"
                  }}
                >
                  <Map
                    center={this.props.center}
                    zoom={this.props.zoom}
                    onClick={this.onMapClick}
                    onZoomChanged={event => this.props.onZoomChange(event.zoom)}
                    onCenterChanged={event =>
                      this.props.onCenterChange(event.center)
                    }
                  />
                </div>
              </GoogleMaps>
            ) : (
              <div>Loading</div>
            );
          }

          if (error) {
            console.log(error);
            return <div>Error</div>;
          }

          const { properties, parish, municipality, district } = data;

          const polygons =
            this.getPlolygonsFromGeoJson(parish) ||
            this.getPlolygonsFromGeoJson(municipality) ||
            this.getPlolygonsFromGeoJson(district);

          const zoom = this.props.zoom || this.getZoom(data);
          const center =
            this.props.center ||
            this.getMemoizedCenter(district, municipality, parish);

          let filteredProperties;
          if (filterSelected === true) {
            filteredProperties = properties.filter(property =>
              selectedPropertiesIds.includes(property.id)
            );
          } else if (filterSelected === false) {
            filteredProperties = properties.filter(
              property => !selectedPropertiesIds.includes(property.id)
            );
          } else {
            filteredProperties = properties;
          }

          const markersInPosition = {};
          filteredProperties.forEach(property => {
            const position = propertyPosition(property);
            const { lat, lng } = position;
            if (lat && lng) {
              const key = `${lat},${lng}`;
              markersInPosition[key] = markersInPosition[key] || {
                position,
                properties: []
              };
              markersInPosition[key].properties.push(property);
            }
          });

          let markers = [];
          Object.keys(markersInPosition).forEach(key => {
            let { properties, position } = markersInPosition[key];

            const numberOfProperties = properties.length;
            const property = properties[0];
            const id = property.id;

            const allSelected = properties.every(property =>
              selectedPropertiesIds.includes(property.id)
            );
            const someSelected =
              !allSelected &&
              properties.some(property =>
                selectedPropertiesIds.includes(property.id)
              );

            const noneSelected = !allSelected && !someSelected;
            const isHighlighted = property.id === highlightedProperty;
            const isActive = this.state.activeId === property.id;
            const label = numberOfProperties > 1 ? `${numberOfProperties}` : "";

            const fillColor = allSelected
              ? "green"
              : someSelected
              ? "blue"
              : property.lat
              ? "red"
              : "yellow";
            const scale = isHighlighted
              ? 1.0
              : isActive
              ? 0.8
              : noneSelected
              ? 0.6
              : 0.7;

            const icon = makeIcon({ fillColor, scale });

            const marker = (
              <Marker
                key={id}
                onClick={marker =>
                  this.onMarkerClick(properties, property.id, marker)
                }
                onMouseover={marker =>
                  this.onMarkerIn(properties, property.id, marker)
                }
                onMouseout={this.onMarkerOut}
                property={property}
                position={position}
                label={label}
                icon={icon}
                // draggable={true}
                // label={"A"}
                // opacity={0.5}
                zIndex={noneSelected ? 9 : 10}
              >
                {this.props.highlightedProperty === property.id &&
                this.state.showInfo ? (
                  <InfoWindow open={true}>
                    <List
                      // header="Summary"
                      // itemLayout="horizontal"
                      bordered={false}
                      dataSource={properties}
                      renderItem={property => (
                        <List.Item>
                          <CrawlInfo
                            property={property}
                            actions={true}
                            onChangeSelected={this.props.onChangeSelected}
                            onSetCenter={this.props.onSetCenter}
                            selectedProperties={this.props.selectedProperties}
                            selectField={this.selectField}
                            centerField={this.centerField}
                            client={client}
                          />
                        </List.Item>
                      )}
                    />
                  </InfoWindow>
                ) : (
                  this.state.activeId === property.id && (
                    <InfoWindow
                      open={true}
                      onCloseclick={this.onInfoWindowClose}
                    >
                      <List
                        // header="Summary"
                        // itemLayout="horizontal"
                        bordered={false}
                        dataSource={properties.slice(
                          0,
                          MAX_PROPERTIES_IN_INFOWINDO
                        )}
                        footer={
                          properties.length > MAX_PROPERTIES_IN_INFOWINDO ? (
                            <div style={{ textAlign: "center" }}>
                              {properties.length - MAX_PROPERTIES_IN_INFOWINDO}{" "}
                              MORE
                            </div>
                          ) : null
                        }
                        renderItem={property => (
                          <List.Item>
                            <CrawlInfo
                              property={property}
                              actions={false}
                              onChangeSelected={this.props.onChangeSelected}
                              onSetCenter={this.props.onSetCenter}
                              selectedProperties={this.props.selectedProperties}
                              selectField={this.selectField}
                              centerField={this.centerField}
                              client={client}
                            />
                          </List.Item>
                        )}
                      />
                    </InfoWindow>
                  )
                )}
              </Marker>
            );
            markers.push(marker);
          });

          const searchFor =
            this.props.searchFor && this.props.searchFor.searchFor;
          if (searchFor && searchFor.type === "GPS") {
            const position = searchFor;
            const label = "";
            const fillColor = "black";
            const scale = 0.7;
            const zIndex = 11;

            const icon = makeIcon({ fillColor, scale });

            const marker = (
              <Marker
                key={"gps"}
                position={position}
                label={label}
                icon={icon}
                zIndex={zIndex}
              />
            );

            markers.push(marker);
          }

          return (
            <>
              <GoogleMaps
                api={{ key: "AIzaSyDGusUSxPkWCM_qlJEmLl5BbfaWZr1Eetc" }}
              >
                <div
                  style={{
                    height: this.state.height - REMOVE_HEIGHT,
                    width: "100wh"
                  }}
                >
                  <Map
                    center={center}
                    zoom={zoom}
                    onClick={this.onMapClick}
                    onZoomChanged={event => this.props.onZoomChange(event.zoom)}
                    onCenterChanged={event =>
                      this.props.onCenterChange(event.center)
                    }
                  >
                    {markers}
                    {polygons}
                  </Map>
                </div>
              </GoogleMaps>
            </>
          );
        }}
      </Query>
    );
  }
}
