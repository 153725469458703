import React from "react";
import styled from "styled-components";
import { Column } from "./Column";
import { DragDropContext } from "react-beautiful-dnd";

import { Query } from "./Query";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { queryFields } from "./Queries";

const propertiesMutation = gql`
  mutation updateUserSettings($fieldsSettings: FieldsSettingsInput!) {
    updateUserSettings(fieldsSettings: $fieldsSettings) {
      fieldsSettings {
        properties {
          fixedIds
          floatingIds
          notUsedIds
        }
      }
    }
  }
`;
const homogenizationMutation = gql`
  mutation updateUserSettings($fieldsSettings: FieldsSettingsInput!) {
    updateUserSettings(fieldsSettings: $fieldsSettings) {
      fieldsSettings {
        homogenization {
          fixedIds
          floatingIds
          notUsedIds
        }
      }
    }
  }
`;
const mutations = {
  properties: propertiesMutation,
  homogenization: homogenizationMutation
};

const Outter = styled.div`
  // border: 1px solid lightgrey;
  // border-radius: 2px;
  width: 720px;
  padding: 20px;
  margin: 20px;
  background-color: white;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
`;
const Container = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 12px;
  // padding: 20px;
`;

export const ColumnSettings = ({ type }) => {
  const onDragEnd = (result, mutation, columns) => {
    const { draggableId, source, destination } = result;

    if (!destination) {
      // console.log("no destination");
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      // console.log("same detination");
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    let newColumns = null;

    if (start === finish) {
      const newFieldIds = Array.from(start.fieldIds);
      newFieldIds.splice(source.index, 1);
      newFieldIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        fieldIds: newFieldIds
      };

      newColumns = {
        ...columns,
        [newColumn.id]: newColumn
      };
    } else {
      const startFieldIds = Array.from(start.fieldIds);
      startFieldIds.splice(source.index, 1);
      const newStart = {
        ...start,
        fieldIds: startFieldIds
      };

      const finishFieldIds = Array.from(finish.fieldIds);
      finishFieldIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        fieldIds: finishFieldIds
      };

      newColumns = {
        ...columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      };
    }

    const fieldsSettings = {
      [type]: {
        fixedIds: newColumns.fixed.fieldIds,
        floatingIds: newColumns.floating.fieldIds,
        notUsedIds: newColumns.notUsed.fieldIds
      }
    };
    const fieldsSettingsWithTypenames = {
      [type]: { ...fieldsSettings[type], __typename: "FieldsOrder" },
      __typename: "FieldsSettings"
    };

    const optimisticResponse = {
      __typename: "Mutation",
      updateUserSettings: {
        fieldsSettings: fieldsSettingsWithTypenames,
        __typename: "UserSettings"
      }
    };

    mutation({
      variables: { fieldsSettings },
      optimisticResponse,
      update: (proxy, { data: { updateUserSettings } }) => {
        const data = proxy.readQuery({ query: queryFields });
        data.userSettings.fieldsSettings[type] =
          fieldsSettingsWithTypenames[type];
        proxy.writeQuery({ query: queryFields, data });
      }
    });
  };

  return (
    <Query query={queryFields}>
      {({ error, loading, data }) => {
        if (error) {
          console.log(error);
          return <div>Error</div>;
        }
        if (loading) {
          return null;
        }

        const {
          fields: allFields,
          userSettings: { fieldsSettings }
        } = data;

        const {
          [type]: { fixedIds, floatingIds, notUsedIds }
        } = fieldsSettings;

        const columns = {
          fixed: { id: "fixed", title: "Fixed", fieldIds: fixedIds },
          floating: {
            id: "floating",
            title: "Floating",
            fieldIds: floatingIds
          },
          notUsed: {
            id: "notUsed",
            title: "Not Used",
            fieldIds: notUsedIds
          }
        };

        const fields = allFields.reduce(
          (acc, value) => ({ ...acc, [value.id]: value }),
          {}
        );

        return (
          <Mutation mutation={mutations[type]}>
            {(mutation, { error, called, loading, data, ...rest }) => {
              if (error) {
                console.log(error);
                return <div>Error</div>;
              }
              // if (loading) {
              //   return null;
              // }
              return (
                <DragDropContext
                  onDragEnd={result => onDragEnd(result, mutation, columns)}
                >
                  <Outter>
                    {/* {type.toUpperCase() + " TABLE"} */}
                    <Container>
                      {["fixed", "floating", "notUsed"].map(columnId => {
                        const column = columns[columnId];
                        const columnFields = column.fieldIds.map(
                          fieldId => fields[fieldId]
                        );
                        return (
                          <Column
                            key={column.id}
                            column={column}
                            fields={columnFields}
                          />
                        );
                      })}
                    </Container>
                  </Outter>
                </DragDropContext>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};
