import React from "react";

import { Card, List, Button, Popconfirm, message } from "antd";

import { NewUser } from "./NewUser";
import { users_Query } from "./Queries";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { uniq } from "./util/uniq";
import { UpdateUser } from "./UpdateUser";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { solveRegistrationChallenge } from "@webauthn/client";

const deleteUserMutation = gql`
  mutation($id: ID!) {
    deleteUser(id: $id) {
      id
      name
      username
      email
      role {
        id
        name
      }
      has_public_key
    }
  }
`;

const requestRegisterUserMutation = gql`
  mutation($id: ID!) {
    requestRegisterUser(id: $id) {
      challenge
      rp {
        id
        name
      }
      user {
        id
        name
        displayName
      }
      attestation
      pubKeyCredParams {
        type
        alg
      }
    }
  }
`;

const unregisterUserMutation = gql`
  mutation($id: ID!) {
    unregisterUser(id: $id) {
      id
      name
      username
      email
      role {
        id
        name
      }
      has_public_key
    }
  }
`;

const registerUserMutation = gql`
  mutation($id: ID!, $credentials: Credentials!) {
    registerUser(id: $id, credentials: $credentials) {
      id
      name
      username
      email
      role {
        id
        name
      }
      has_public_key
    }
  }
`;

const Header = ({ onClickNew }) => {
  return (
    <Button type="primary" onClick={onClickNew}>
      New
    </Button>
  );
};

const handleDeleteUser = (mutation, user, e) => {
  e.preventDefault();
  mutation({
    variables: { id: user.id }
  });
};

const handleRequestRegisterUser = async (client, user, e) => {
  e.preventDefault();
  const result = await client.mutate({
    mutation: requestRegisterUserMutation,
    variables: { id: user.id }
  });
  if (!result) {
    console.log("No result");
    return;
  }
  const { data } = result;
  const { requestRegisterUser } = data;

  const state = {
    NONE: "none",
    CANCELED: "canceled",
    SUCCESS: "success",
    ERROR: "error"
  };

  let registerState = state.NONE;
  try {
    const credentials = await solveRegistrationChallenge(requestRegisterUser);
    const registerResult = await client.mutate({
      mutation: registerUserMutation,
      variables: {
        id: user.id,
        credentials
      }
    });
    const registerUser =
      registerResult && registerResult.data && registerResult.data.registerUser;
    registerState = registerUser ? state.SUCCESS : state.ERROR;
  } catch {
    registerState = state.CANCELED;
  }

  switch (registerState) {
    case state.NONE:
      throw new Error("invalid state: NONE");
    case state.SUCCESS:
      message.success("User key registered with success.");
      break;
    case state.ERROR:
      message.error("Error registering user key.");
      break;
    case state.CANCELED:
      break;
    default:
      throw new Error("invalid state: ", state);
  }
};

const handleUnregisterUser = async (client, user, e) => {
  console.log("will unregister key", user);
  const result = await client.mutate({
    mutation: unregisterUserMutation,
    variables: { id: user.id }
  });
  if (!result) {
    console.log("No result");
    return;
  }
  const { data } = result;
  console.log("data", data);
  // const { requestRegisterUser } = data;
};

const User = ({ user }) => {
  const client = useApolloClient();
  const [showModal, setShowModal] = React.useState(false);
  const registerAction = (
    <Button
      size="small"
      onClick={e => handleRequestRegisterUser(client, user, e)}
    >
      Register
    </Button>
  );
  const unregisterAction = (
    <Button size="small" onClick={e => handleUnregisterUser(client, user, e)}>
      Unregister
    </Button>
  );
  const updateAction = (
    <div>
      <UpdateUser
        visible={showModal}
        hide={() => setShowModal(false)}
        user={user}
      />
      <Button size="small" onClick={() => setShowModal(true)}>
        Edit
      </Button>
    </div>
  );
  const deleteAction = (
    <Mutation mutation={deleteUserMutation}>
      {mutation => {
        return (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={e => handleDeleteUser(mutation, user, e)}
          >
            <Button size="small"> Delete </Button>
          </Popconfirm>
        );
      }}
    </Mutation>
  );
  const registerOrUnregister = user.has_public_key
    ? unregisterAction
    : registerAction;
  return (
    <List.Item
      actions={
        user.id === "1"
          ? [registerOrUnregister, updateAction]
          : [registerOrUnregister, updateAction, deleteAction]
      }
    >
      <List.Item.Meta size="small" title={user.name} description={user.email} />
    </List.Item>
  );
};

const UsersList = ({ loading, error, data }) => {
  if (loading) return null;
  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const { users } = data;
  const roles = uniq(
    users.map(u => u.role),
    e => e.id
  );

  const sorterRoles = roles
    .concat()
    .sort((r1, r2) => (r1.id < r2.id ? -1 : r1.id > r2.id ? 1 : 0));
  const rr = sorterRoles.map(role => {
    const roleUsers = users.filter(u => u.role.id === role.id);
    return roleUsers.length > 0 ? (
      <Card
        size="small"
        bordered={false}
        key={role.id}
        style={{ margin: "20px 20px 20px 20px" }}
        // style={{ width: 800 }}
      >
        <List
          size="small"
          header={<div>{role.name}</div>}
          bordered
          dataSource={roleUsers}
          renderItem={user => <User user={user} />}
        />
      </Card>
    ) : null;
  });
  return <div>{rr}</div>;
};

const UsersListWithData = () => {
  const { loading, ...result } = useQuery(users_Query);
  return <UsersList loading={loading} {...result} />;
};

export const Users = () => {
  const [showNewUser, setShowNewUser] = React.useState(false);

  return (
    <Card
      title="Users"
      extra={
        <Header
          style={{ margin: "auto auto auto auto" }}
          onClickNew={() => setShowNewUser(true)}
        />
      }
      style={{ margin: "50px auto auto auto", width: 800 }}
      // actions={[<Header onClickNew={() => setShowNewUser(true)} />]}
    >
      <UsersListWithData />
      <NewUser visible={showNewUser} hide={() => setShowNewUser(false)} />
    </Card>
  );
};
