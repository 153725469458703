import React from "react";
import {
  BarChart,
  PieChart,
  // ComposedChart,
  // Line,
  Bar,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card, Row, Col } from "antd";
import { CustomizedAxisTick } from "./CustomBarChart";

// const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;
import { COLORS } from "./constants";

export const MacroLicenciamentoVsObrasConcluidas = ({
  data,
  licenciamentoByTypology,
  obrasConcluidasByTypology,
  numberOfYearsOfLicenciamento,
  numberOfYearsOfObrasConcluidas,
}) => {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const pieChartWidth = 200;

  return (
    <Card style={{ width: "100%", padding: "auto" }}>
      <h2 style={{ textAlign: "center" }}>
        Licenciamento e Obras concluídas: Fogos Novos Habitação Familiar
      </h2>
      <Row type="flex" justify="center" align="middle">
        <Col span={8} style={{ width: 800 }}>
          <BarChart
            width={700}
            height={250}
            data={data}
            barGap={-5}
            barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              interval={0}
              dataKey="year"
              height={60}
              tick={<CustomizedAxisTick />}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="total_licenciamento"
              name="Licenciamento"
              fill={COLORS[0]}
              // label
            />
            <Bar
              dataKey="total_obras_concluidas"
              name="Obras Concluidas"
              fill={COLORS[1]}
              // label
            />
          </BarChart>
        </Col>
        <Col span={2} style={{ width: 200 }}>
          <Row>
            <PieChart width={pieChartWidth} height={pieChartWidth}>
              <Pie
                data={licenciamentoByTypology}
                dataKey="value"
                // nameKey="name"
                // cx={100}
                // cy={100}
                labelLine={false}
                label={renderCustomizedLabel}
                isAnimationActive={false}
                // outerRadius={"50%"}
                fill="#8884d8"
              >
                {licenciamentoByTypology.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
              <Tooltip
                formatter={(_value, _name, props) => {
                  return `average: ${(
                    props.value / numberOfYearsOfLicenciamento
                  ).toFixed(0)}`;
                }}
              />
            </PieChart>
            <h4 style={{ textAlign: "center" }}>Licenciamento por Tipologia</h4>
          </Row>
          <Row>
            <PieChart width={pieChartWidth} height={pieChartWidth}>
              <Pie
                data={obrasConcluidasByTypology}
                dataKey="value"
                // nameKey="name"
                // cx={200}
                // cy={200}
                labelLine={false}
                label={renderCustomizedLabel}
                isAnimationActive={false}
                // outerRadius={150}
                fill="#8884d8"
              >
                {obrasConcluidasByTypology.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
              <Tooltip
                formatter={(_value, _name, props) => {
                  return `average: ${(
                    props.value / numberOfYearsOfObrasConcluidas
                  ).toFixed(0)}`;
                }}
              />
            </PieChart>
            <h4 style={{ textAlign: "center" }}>
              Obras Concluídas por Tipologia
            </h4>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
