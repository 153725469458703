import React from "react";
import { InputNumber } from "antd";

export class SetNumber extends React.Component {
  state = {
    value: undefined
  };

  render() {
    const {
      value: initialValue,
      label,
      min,
      max,
      step,
      onChange,
      ...other
    } = this.props;
    const { value } = this.state;
    return (
      <div>
        <label>
          {label} [{min}-{max}
          ]:{" "}
        </label>
        <InputNumber
          size="small"
          style={{ float: "right" }}
          min={min}
          max={max}
          step={step}
          value={value || initialValue}
          onChange={value => this.setState({ value })}
          onBlur={() =>
            this.setState({ value: undefined }, () =>
              onChange(value || initialValue)
            )
          }
          {...other}
        />
      </div>
    );
  }
}
