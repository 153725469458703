import { connect } from "react-redux";
import { setIsSelected } from "../actions";

import { FilterYesNoAll } from "../FilterYesNoAll";

const mapStateToProps = state => {
  return {
    value:
      state.filters.property.isSelected === null
        ? "all"
        : state.filters.property.isSelected === true
          ? "yes"
          : "no",
    label: "Selected? "
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: values => {
    switch (values) {
      case "all":
        return dispatch(setIsSelected(null));
      case "yes":
        return dispatch(setIsSelected(true));
      case "no":
        return dispatch(setIsSelected(false));
      default:
        throw new Error("Invalid isSelected option");
    }
  }
});

export const FilterSelectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterYesNoAll);
