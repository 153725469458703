import moment from "moment";

const MOMENT_RANGE = {
  Today: () => [moment(), moment()],
  "This Month": () => [moment().startOf("month"), moment()],
  "Last 1 Month": () => [moment().subtract(1, "month"), moment()],
  "Last 3 Month": () => [moment().subtract(3, "months"), moment()],
  "Last 6 Month": () => [moment().subtract(6, "months"), moment()],
  "1 Month Ago": () => [
    moment().subtract(2, "months"),
    moment().subtract(1, "months")
  ]
};

export const dateRange = literal => {
  return MOMENT_RANGE[literal]().map(m => m.format("YYYY-MM-DD"));
};
