import React from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  districtQuery,
  municipalityQuery,
  parishQuery,
  purposeQuery
} from "./Queries";

export const District = ({ id }) => {
  const { loading, data } = useQuery(districtQuery, {
    variables: { where: { id } }
  });
  console.log("data", data);
  return data && data.district ? (
    <>{data.district.name}</>
  ) : loading ? null : (
    <>Invalid DistrictId: {id}</>
  );
};

export const Municipality = ({ id }) => {
  const { loading, data } = useQuery(municipalityQuery, {
    variables: { where: { id } }
  });
  return data && data.municipality ? (
    <>{data.municipality.name}</>
  ) : loading ? null : (
    <>Invalid MunicipalityId: {id}</>
  );
};

export const Parish = ({ id, name }) => {
  const { loading, data } = useQuery(parishQuery, {
    variables: { parishWhere: { id } }
  });
  return data && data.parish ? (
    <>{data.parish.name}</>
  ) : loading ? null : (
    <b style={{ color: "red" }}>
      Invalid ParishId: {id} ({name})
    </b>
  );
};

export const Purpose = ({ id }) => {
  const { loading, data } = useQuery(purposeQuery, {
    variables: { where: { id } }
  });
  return !loading && data ? <>{data.purpose.name}</> : null;
};
