import { connect } from "react-redux";
import { PropertiesTable } from "../PropertiesTable";
import { PropertiesMap } from "../PropertiesMap";
import { HomogenizationTable } from "../HomogenizationTable";
import { TYPOLOGY } from "./typologies";
import {
  setSelectedProperties,
  toggleHighlightedProperty,
  setWeight,
  setPropertyId,
  setLocation,
  setNearProperty,
  setZoom,
  setCenter,
  setPropertiesPage
} from "../actions";
import { dateRange } from "../util/date";
import { inputToRegex } from "../utils";

const buildNear = (near, radius) => {
  if (!near) {
    return undefined;
  } else {
    const newNear = { ...near, radius };
    return { near: newNear };
  }
};

const buildDivision = ({ type, id }) => {
  if (!type) {
    return undefined;
  } else {
    return { [type]: { id } };
  }
};

const buildSitesFilter = sites => {
  if (sites.length > 0) {
    return { site_id_in: sites };
  } else {
    return undefined;
  }
};

const buildConditionsFilter = conditions => {
  if (conditions.length > 0) {
    return { condition_id_in: conditions };
  } else {
    return undefined;
  }
};

const buildTypeFilter = typeId => {
  if (typeId) {
    return { type: { id: typeId } };
  } else {
    return {};
  }
};

const buildSubtypesFilter = subtypes => {
  if (subtypes.length > 0) {
    return { subtype_id_in: subtypes };
  } else {
    return undefined;
  }
};

const buildNumberOfBedroomsFilter = ids => {
  let result = [];
  const x = ids.filter(v => v !== TYPOLOGY.TPlus.id && v !== TYPOLOGY.ND.id);
  const y = ids.filter(v => v === TYPOLOGY.TPlus.id);
  const z = ids.filter(v => v === TYPOLOGY.ND.id);
  if (x.length > 0) {
    result = [...result, { number_of_bedrooms_in: x.map(Number) }];
  }
  if (y.length > 0) {
    result = [...result, { number_of_bedrooms_gte: Number(TYPOLOGY.TPlus.id) }];
  }
  if (z.length > 0) {
    result = [...result, { number_of_bedrooms: null }];
  }

  if (result.length === 0) {
    return {};
  } else if (result.length === 1) {
    return result[0];
  } else {
    return { OR: result };
  }
};

const buildPropertyWhere = (
  {
    near,
    division,
    sites,
    conditions,
    type,
    subtypes,
    typologies,
    area,
    hasGps,
    wasUpdatedManually
  },
  radius
) => {
  // if (!division) return undefined;
  const nearWhere = buildNear(near, radius);

  if (!division && !nearWhere) return undefined;

  const divisionWhere = division ? buildDivision(division) : {};
  // const divisionWhere = buildDivision(division);
  const sitesWhere = buildSitesFilter(sites);
  const conditionsWhere = buildConditionsFilter(conditions);
  const typeWhere = buildTypeFilter(type);
  const subtypesWhere = buildSubtypesFilter(subtypes);
  const numberOfBedroomsWhere = buildNumberOfBedroomsFilter(typologies);
  const areaWhere = buildBetweenWhere("area", area);
  const hasGpsWhere = buildBoolean("has_gps", hasGps);
  const wasUpdatedManuallyWhere = buildBoolean(
    "has_updated_number_of_bedrooms",
    wasUpdatedManually
  );

  const result = {
    ...nearWhere,
    ...divisionWhere,
    ...sitesWhere,
    ...conditionsWhere,
    ...typeWhere,
    ...subtypesWhere,
    ...numberOfBedroomsWhere,
    ...areaWhere,
    ...hasGpsWhere,
    ...wasUpdatedManuallyWhere
  };
  return result;
};

const buildPurposeWhere = purpose => {
  return { id: purpose };
};

const buildBoolean = (field, value) => {
  let result = {};
  if (value === true || value === false) {
    result[`${field}`] = value;
    return result;
  } else {
    return {};
  }
};
const buildBetweenWhere = (field, { min, max }) => {
  let result = {};
  if (!!min) {
    result[`${field}_gte`] = min;
  }
  if (!!max) {
    result[`${field}_lte`] = max;
  }
  return result;
};

const buildWhere = (
  { agency, purpose, price, date, lastCrawlDate, property },
  radius
) => {
  const purposeWhere = buildPurposeWhere(purpose);
  if (property && property.id) {
    return { purpose: purposeWhere, property: { id: property.id } };
  }
  const propertyWhere = buildPropertyWhere(property, radius);
  if (!propertyWhere) return undefined;

  const priceWhere = buildBetweenWhere("price", price);
  const hasPriceChanged = price && { has_price_changes: price.changed };
  const [startDate, endDate] = date.literal
    ? dateRange(date.literal)
    : [date.start, date.end];
  const [startLastCrawlDate, endLastCrawlDate] = lastCrawlDate.literal
    ? dateRange(lastCrawlDate.literal)
    : [lastCrawlDate.start, lastCrawlDate.end];

  const dateWhere = buildBetweenWhere("date_of_crawl", {
    min: startDate,
    max: endDate
  });
  const lastCrawlDateWhere = buildBetweenWhere("last_crawl_date", {
    min: startLastCrawlDate,
    max: endLastCrawlDate
  });
  const result = {
    purpose: purposeWhere,
    ...priceWhere,
    OR: [dateWhere, lastCrawlDateWhere],
    ...hasPriceChanged,
    property: propertyWhere
  };
  if (!!agency) {
    result.agency_regex = inputToRegex(agency)
  }
  return result;
};

const mapStateToProps = ({
  user,
  filters,
  searchFor,
  selectedProperties,
  activeWeights,
  highlightedProperty,
  preferences,
  googleMap,
  propertiesPage
}) => ({
  user,
  maxProperties: preferences.maxProperties,
  propertiesPerPage: preferences.propertiesPerPage,
  where: buildWhere(filters, preferences.radius),
  geometryWhere: buildPropertyWhere(filters.property, preferences.radius),
  selectedProperties,
  highlightedProperty,
  filterSelected: filters.property.isSelected,
  activeWeights,
  zoom: googleMap.zoom,
  center: googleMap.center || undefined,
  searchFor: {
    division: filters.property.division,
    property: { id: filters.property.id, siteRef: filters.property.siteRef },
    searchFor
  },
  propertiesPage
});

const mapDispatchToProps = dispatch => ({
  onChangeSelected: ids => dispatch(setSelectedProperties(ids)),
  onRowClick: id => dispatch(toggleHighlightedProperty(id)),
  onSetLocation: location => {
    if (location) {
      dispatch(
        setLocation({
          type: location.__typename.toLowerCase(),
          id: location.id,
          name: location.name
        })
      );
    }
    dispatch(setPropertyId(null));
  },
  onSetCenter: property => {
    dispatch(setNearProperty(property));
  },
  onWeightChange: (propertyId, weightId, weightValue) =>
    dispatch(setWeight(propertyId, weightId, weightValue)),
  onZoomChange: zoom => {
    dispatch(setZoom(zoom));
  },
  onCenterChange: center => {
    dispatch(setCenter({ lat: center.lat(), lng: center.lng() }));
  },
  setPropertiesPage: page => {
    dispatch(setPropertiesPage(page));
  }
});

export const PropertyListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesTable);
export const PropertyMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesMap);
export const HomogenizationTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomogenizationTable);
