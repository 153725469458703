import React from "react";
import { connect } from "react-redux";

import { Menu, Icon, Badge } from "antd";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, LOCALSTORAGE_APP_STATE } from "./constants";

import { hasRole } from "./util/authorization";

import { reload } from "./actions";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

const updateUserStateMutation = gql`
  mutation updateUserState($state: JSON!) {
    updateUserState(state: $state)
  }
`;

const userStateQuery = gql`
  query userState {
    userState
  }
`;

const TopBar = ({ match, user, numberOfSelectedProperties, reload }) => {
  const authToken = localStorage.getItem(AUTH_TOKEN);

  const { selected } = match.params;
  const selectedKeys = [selected];

  if (!authToken) return null;

  return (
    <Mutation mutation={updateUserStateMutation}>
      {(mutation, { client, error, called, loading, data, ...rest }) => {
        return (
          <Menu
            size="small"
            selectedKeys={selectedKeys}
            mode="horizontal"
            theme="dark"
          >
            <Menu.Item key="map">
              <Link to="/map">
                <Icon type="environment" />
                Map
              </Link>
            </Menu.Item>
            <Menu.Item key="properties">
              <Link to="/properties">
                <Icon type="table" />
                Properties
              </Link>
            </Menu.Item>
            <Menu.Item
              key="homogenization"
              disabled={numberOfSelectedProperties === 0}
            >
              <Link to="/homogenization">
                <Icon type="file-done" />
                <Badge
                  size="small"
                  count={numberOfSelectedProperties}
                  // style={{
                  //   backgroundColor: "#000",
                  //   color: "#fff",
                  //   boxShadow: "0 0 0 0px #d9d9d9 inset"
                  // }}
                  offset={[10, 15]}
                >
                  Homogenization
                </Badge>
              </Link>
            </Menu.Item>
            <Menu.SubMenu
              key="statistics"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="bar-chart" />
                  Statistics
                </span>
              }
            >
              <Menu.Item key="statistics-offer-residential">
                <Link to="/statistics-offer-residential">
                  <Icon type="bar-chart" />
                  Oferta (Residêncial)
                </Link>
              </Menu.Item>
              <Menu.Item key="statistics-offer-comercial">
                <Link to="/statistics-offer-comercial">
                  <Icon type="bar-chart" />
                  Oferta (Comercial)
                </Link>
              </Menu.Item>
              <Menu.Item key="statistics-macro">
                <Link to="/statistics-macro">
                  <Icon type="bar-chart" />
                  Macro
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            {/* {hasRole(user, "MANAGER") && (
              <Menu.Item key="add-properties">
                <Link to="/add-properties">
                  <Icon type="file-add" />
                  Add Properties
                </Link>
              </Menu.Item>
            )} */}
            <Menu.SubMenu
              key="rav"
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="solution" />
                  RAV
                </span>
              }
            >
              <Menu.Item key="addRav">
                <Link to="/add-rav">
                  <Icon type="file-add" />
                  Upload
                </Link>
              </Menu.Item>
              <Menu.Item key="copyRav">
                <Link to="/prospection-copy-rav">
                  <Icon type="file-sync" />
                  Prospection Copy
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            {hasRole(user, "ADMIN") && (
              <Menu.SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="setting" />
                    Admin
                  </span>
                }
              >
                <Menu.Item key="admin-users">
                  <Link to="/admin-users">
                    <Icon type="usergroup-add" />
                    Users
                  </Link>
                </Menu.Item>
                <Menu.Item key="admin-add-file">
                  <Link to="/admin-add-file">
                    <Icon type="file-add" />
                    Add File
                  </Link>
                </Menu.Item>
                <Menu.Item key="admin-sessions">
                  <Link to="/admin-sessions">
                    <Icon type="login" />
                    Sessions
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
            <Menu.SubMenu
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="user" />
                  {user.name}
                </span>
              }
              style={{ float: "right" }}
            >
              <Menu.Item key="save">
                <Link
                  to="/save"
                  onClick={e => {
                    e.preventDefault();
                    const state = localStorage.getItem(LOCALSTORAGE_APP_STATE);
                    mutation({ variables: { state: state } });
                  }}
                >
                  <Icon type="save" /> Save
                </Link>
              </Menu.Item>
              <Menu.Item key="sync">
                <Link
                  to="/sync"
                  onClick={async e => {
                    e.preventDefault();
                    const {
                      data: { userState }
                    } = await client.query({
                      query: userStateQuery,
                      fetchPolicy: "network-only"
                    });
                    reload(userState);
                  }}
                >
                  <Icon type="sync" /> Sync
                </Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="help">
                <Link to="/help">
                  <Icon type="file-unknown" />
                  Help
                </Link>
              </Menu.Item>
              <Menu.Item key="changelog">
                <Link to="/changelog">
                  <Icon type="file-unknown" />
                  Changelog
                </Link>
              </Menu.Item>
              <Menu.Item key="settings">
                <Link to="/settings">
                  <Icon type="setting" />
                  Settings
                </Link>
              </Menu.Item>
              <Menu.Item key="logout">
                <Link to="/logout">
                  <Icon type="logout" />
                  Logout
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        );
      }}
    </Mutation>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  numberOfSelectedProperties: Object.keys(state.selectedProperties).length
});
const mapDispatchToProps = dispatch => ({
  reload: state => dispatch(reload(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
