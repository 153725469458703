import { connect } from "react-redux";
import { setFilterPriceChanges } from "../actions";
import { FilterPriceChange } from "../FilterPriceChange";

const mapStateToProps = state => {
  return {
    filterPriceChanges: state.filters.price.changed
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilterPriceChanges: filterPriceChanges =>
      dispatch(setFilterPriceChanges(filterPriceChanges))
  };
};

export const FilterPriceChangeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterPriceChange);
