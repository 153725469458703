import React from "react";
import { Checkbox } from "antd";
import "antd/dist/antd.css";

export const FilterPriceChange = ({
  filterPriceChanges,
  setFilterPriceChanges
}) => (
  <Checkbox
    checked={filterPriceChanges}
    data-testid="only_with_price_variations"
    onChange={e => {
      const isChecked = e.target.checked;
      return setFilterPriceChanges(isChecked);
    }}
  >
    only with price variations
  </Checkbox>
);
