import React from "react";
import { Card } from "antd";

// import { FilterPriceChangeContainer } from "./containers/FilterPriceChangeContainer";
// import { FilterUpdatedManually } from "./FilterUpdatedManually";
import { FilterWithGpsContainer } from "./containers/FilterWithGpsContainer";
import { FilterSelectedContainer } from "./containers/FilterSelectedContainer";
import { FilterUpdatedManually } from "./FilterUpdatedManually";

const style = { width: "20%", height: "40px" };
export const PropertyFilters2 = () => {
  return (
    <div style={{ minWidth: "1100px" }}>
      <Card size="small">
        <Card.Grid style={style}>
          <FilterUpdatedManually />
        </Card.Grid>
        <Card.Grid style={style}>
          <FilterSelectedContainer />
        </Card.Grid>
        <Card.Grid style={style}>
          <FilterWithGpsContainer />
        </Card.Grid>
      </Card>
    </div>
  );
};
