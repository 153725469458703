import { connect } from "react-redux";
import { setSites } from "../actions";

import FilterBySite from "../FilterBySite";

const mapStateToProps = state => ({
  value: state.filters.property.sites
});

const mapDispatchToProps = dispatch => ({
  onChange: ids => dispatch(setSites(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterBySite);
