import React from "react";
import moment from "moment";
import {
  DB,
  RAV_TipoBemId,
  RAV_Ocupacao,
  RAV_ConstEstadoConservacaoId
} from "./constants";
import { notification } from "antd";

const DEFAULT_IS_FINISHED = true;

export const extractInfo = data => {
  const {
    PedidoNumero,
    AvaliadorDataEnvioRelatorio: date_of_crawl,
    RegistoCadastralCRP,
    ConstEstadoConservacaoId,
    ImovelFreguesia
  } = data;
  // console.log("data", data);
  const messages = new Set();

  // console.log("RegistoCadastralCRP", RegistoCadastralCRP);
  if (!RegistoCadastralCRP || RegistoCadastralCRP.length === 0) {
    messages.add(`No RegistoCadastralCRP found`);
  }

  const conservacao = RAV_ConstEstadoConservacaoId[ConstEstadoConservacaoId];
  const condition_id = conservacao ? conservacao.condition_id : null;
  const condition = condition_id ? { id: condition_id } : null;
  // console.log("conservacao: ", conservacao);
  if (!conservacao && ConstEstadoConservacaoId) {
    messages.add(
      `No conditiond defined for ConstEstadoConservacaoId ${ConstEstadoConservacaoId}`
    );
  }

  const info = RegistoCadastralCRP.map(r => {
    const {
      PredialConservRegPredialNome: CRP,
      FiscalArtigo,
      FiscalFracaoLetra,
      CadernetasPrediais,
      PedidoTipoBemId
    } = r;

    const RAV_TipoBem = RAV_TipoBemId[PedidoTipoBemId];
    if (!RAV_TipoBem) {
      messages.add(`No subtype defined for TipoBemId ${PedidoTipoBemId}`);
    }
    const subtype_id = RAV_TipoBem
      ? DB.subtypes.find(RAV_TipoBem.subtype_id).id
      : DB.types.ND.ND.id;

    const type_id = DB.types.findBySubtypeId(subtype_id).id;

    let ArtMat = FiscalArtigo;
    let Fracao = FiscalFracaoLetra;
    let lng = null;
    let lat = null;
    let price = null;
    let area_gross = null;
    let area_net = null;
    let area_ground = null; // "Terreno" (if Terreno > 0)
    let number_of_bedrooms = null;
    let floor = null;
    let construction_year = null;
    let energy_rating = null;
    let description = null;
    let district_id = null;
    let municipality_id = null;
    let parish_id = null;
    CadernetasPrediais.forEach(caderneta => {
      const {
        _FiscalCoordenadaX: lngNew,
        _FiscalCoordenadaY: latNew,
        VFPVT: priceNew,
        CalcAreaBrutaPrivativa: area_grossNew,
        CalcAreaBrutaConst: area_newNew,
        AreaTerreno: area_groundNew,
        Tipologia,
        Piso,
        AnoConstrucao,
        CEClasse,
        Ocupacao: ocupacaoId,
        ObservacoesFracao,
        VFObservacoes,
        FiscalCodDistrito,
        FiscalCodConcelho,
        FiscalCodFreguesia,
        FiscalArtigo,
        FiscalFracaoLetra
      } = caderneta;
      // console.log("Values:", { lngNew, latNew, priceNew, area_gross });
      if (
        lng === null &&
        lat === null &&
        price === null &&
        area_gross === null &&
        area_net === null &&
        floor === null &&
        construction_year === null
      ) {
        lng = parseFloat(lngNew.replace(",", "."));
        lat = parseFloat(latNew.replace(",", "."));
        price = priceNew;
        area_gross = parseInt(area_grossNew);
        area_net = parseInt(area_newNew);
        area_ground = area_groundNew > 0 ? parseInt(area_groundNew) : null;
        number_of_bedrooms = Tipologia > 0 ? Tipologia - 1 : null;
        floor = Piso;
        construction_year = AnoConstrucao;
        energy_rating = CEClasse;
        let descriptionData = [];
        if (ocupacaoId) {
          const ocupacao = RAV_Ocupacao[ocupacaoId];
          if (!ocupacao) {
            messages.add(`No Ocupacao defined for id ${ocupacaoId}`);
          } else {
            descriptionData = [
              ...descriptionData,
              `Ocupação: ${ocupacao.name}`
            ];
          }
        }
        if (ObservacoesFracao) {
          descriptionData = [
            ...descriptionData,
            `ObservacoesFracao: ${ObservacoesFracao}`
          ];
        }
        if (VFObservacoes) {
          descriptionData = [
            ...descriptionData,
            `VFObservacoes: ${VFObservacoes}`
          ];
        }
        description =
          descriptionData === [] ? null : descriptionData.join("; ");

        district_id = FiscalCodDistrito;
        municipality_id = `${FiscalCodDistrito}${FiscalCodConcelho}`;
        parish_id = `${FiscalCodDistrito}${FiscalCodConcelho}${FiscalCodFreguesia}`;
        if (FiscalArtigo) {
          if (ArtMat && ArtMat !== FiscalArtigo) {
            messages.add(
              `Invalid ArtMat. Global: ${ArtMat}, CadPredial: ${FiscalArtigo}`
            );
          } else {
            ArtMat = FiscalArtigo;
          }
        }
        if (FiscalFracaoLetra) {
          if (Fracao && Fracao !== FiscalFracaoLetra) {
            messages.add(
              `Invalid Fracao. Global: ${Fracao}, CadPredial: ${FiscalFracaoLetra}`
            );
          } else {
            Fracao = FiscalFracaoLetra;
          }
        }
      } else if (
        lng !== lngNew ||
        lat !== latNew ||
        price !== priceNew ||
        area_gross !== area_grossNew ||
        area_net !== area_newNew ||
        floor !== Piso ||
        construction_year !== AnoConstrucao
      ) {
        throw new Error("Values in CadernetasPrediais are not the same");
      }
    });

    if (!ArtMat) {
      messages.add("No FiscalArtigo defined");
    }

    let site_ref = null;
    if (Fracao) {
      site_ref = `${PedidoNumero}-${CRP}-${ArtMat}-${Fracao}`;
    } else {
      site_ref = `${PedidoNumero}-${CRP}-${ArtMat}`;
      // messages.add("No FiscalFracaoLetra defined ");
    }

    const i = {
      site_ref,
      subtype: { id: subtype_id },
      type: { id: type_id },
      district: { id: district_id },
      municipality: { id: municipality_id },
      parish: { id: parish_id, name: ImovelFreguesia },
      lng,
      lat,
      gps_type: { id: DB.gps_types.exact.id }, // exact
      crawls: [
        {
          purpose: { id: DB.purposes.buy.id }, // buy
          price,
          date_of_crawl: moment(date_of_crawl).format("YYYY-MM-DD")
        }
      ],
      area_gross,
      area_net,
      area_ground,
      number_of_bedrooms,
      // floor,
      // construction_year
      energy_rating,
      is_finished: DEFAULT_IS_FINISHED,
      description,
      condition
    };
    return i;
  });

  if (messages.size > 0) {
    notification.warning({
      message: "Messages:",
      description: (
        <div>
          {[...messages].map((m, i) => (
            <div key={i}>{m}</div>
          ))}
        </div>
      )
    });
  }

  return info;
};
