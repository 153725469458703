import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Form, Input, Button, message } from "antd";
import { sha256 } from "./util/security";

const updateUserSettingsMutation = gql`
  mutation updateCurrentUserPassword(
    $oldPassword: String!
    $newPassword: String!
  ) {
    updateCurrentUserPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      success
      message
    }
  }
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const RequiredItem = ({
  name,
  label,
  layout,
  form,
  validator,
  onBlur,
  message,
  min
}) => {
  const errorMsg = form.isFieldTouched(name) && form.getFieldError(name);
  return (
    <Form.Item
      {...layout}
      label={label}
      validateStatus={errorMsg ? "error" : ""}
      help={errorMsg || ""}
    >
      {form.getFieldDecorator(name, {
        rules: [
          {
            required: true,
            whitespace: true,
            message: message || `${label} is required`
          },
          {
            min,
            message: `must have at least ${min} characters`
          },
          {
            validator: validator
          }
        ]
      })(<Input.Password placeholder={label} onBlur={onBlur} />)}
    </Form.Item>
  );
};

const AccountSettingsForm = ({ form }) => {
  const [execUpdateUserSettings, result] = useMutation(
    updateUserSettingsMutation
  );
  const [confirmDirty, setConfirmDirty] = React.useState(false);

  React.useEffect(() => {
    if (result && result.data && result.data.updateCurrentUserPassword) {
      const {
        success,
        message: errorMsg
      } = result.data.updateCurrentUserPassword;
      if (success) {
        message.success("Password updated with success");
      } else {
        message.error(errorMsg);
      }
    }
  }, [result]);

  const itemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 }
  };
  const { getFieldsError, isFieldTouched, validateFields } = form;

  React.useEffect(() => {
    validateFields();
  }, [validateFields]);

  const handleConfirmBlur = e => {
    const value = e.target.value;
    setConfirmDirty(confirmDirty || !!value);
  };

  const validateField = fieldName => {
    return (rule, value, callback) => {
      if (value && confirmDirty) {
        form.validateFields([fieldName], { force: true });
      }
      callback();
    };
  };

  const compareToField = (fieldName, errorMsg) => {
    return (rule, value, callback) => {
      if (value && value !== form.getFieldValue(fieldName)) {
        callback(errorMsg);
      } else {
        callback();
      }
    };
  };

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, data) => {
          if (!err) {
            let { oldPassword, newPassword } = data;
            oldPassword = await sha256(oldPassword);
            newPassword = await sha256(newPassword);
            execUpdateUserSettings({
              variables: { oldPassword, newPassword }
            });
          } else {
            console.log("error", err);
          }
        });
      }}
    >
      <RequiredItem
        name="oldPassword"
        label="Old password"
        layout={itemLayout}
        form={form}
      />

      <RequiredItem
        name="newPassword"
        label="New password"
        layout={itemLayout}
        form={form}
        min={6}
        validator={validateField("confirmNewPassword")}
      />

      <RequiredItem
        name="confirmNewPassword"
        label="Confirm new password"
        layout={itemLayout}
        form={form}
        min={6}
        message={"Password confirmation doesn't match the password"}
        validator={compareToField(
          "newPassword",
          "Password confirmation doesn't match the password"
        )}
        onBlur={handleConfirmBlur}
      />

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError(), isFieldTouched)}
        >
          Update password
        </Button>
      </Form.Item>
    </Form>
  );
};

export const AccountSettings = Form.create({ name: "accountSettings" })(
  AccountSettingsForm
);
