import { connect } from "react-redux";
import { setRadius } from "../actions";

import { SetNumber } from "../SetNumber";

const mapStateToProps = state => ({
  value: state.preferences.radius,
  label: "Radius",
  min: 1,
  max: 100000,
  step: 1000
});

const mapDispatchToProps = dispatch => ({
  onChange: radius => dispatch(setRadius(radius))
});

export const InputRadiusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetNumber);
