import React from "react";

const UNAUTHENTICATED = "UNAUTHENTICATED";
const ECONNREFUSED = "ECONNREFUSED";

const checkFor = (code, errors) =>
  errors &&
  errors.find(
    e => e.extensions.code === code || e.extensions.exception.code === code
  );

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { code: null, error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    let code = "ERROR";
    if (
      checkFor(UNAUTHENTICATED, error.graphQLErrors) ||
      checkFor(ECONNREFUSED, error.graphQLErrors)
    ) {
      code = UNAUTHENTICATED;
    }
    // Catch errors in any components below and re-render with error message
    this.setState({
      code,
      error,
      errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.code) {
      console.log("ErrorBoundary code:", this.state.code);
    }
    if (this.state.code === UNAUTHENTICATED) {
      return null;
      // redirection should already be done in errorLink
      // return <Redirect to="/logout" />;
    }
    if (this.state.error) {
      if (this.state.error.message === "Network error: Failed to fetch") {
        return (
          <h1>
            Erro na ligação ao servidor. <br />
            Tente de novo.
            <br />
            Se o problema persistir, contacte o administrador de sistema
          </h1>
        );
      }
    }
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    if (this.state.code) {
      // Still here?
      return null;
    }
    // Normally, just render children
    return this.props.children;
  }
}
