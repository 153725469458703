// flow

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setUser, reset } from "./actions";
import { Redirect } from "react-router-dom";
import { AUTH_TOKEN, LOCALSTORAGE_APP_STATE } from "./constants";

export function LogoutView(props) {
  useEffect(() => {
    props.resetAppState();
    props.setUser(null);
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(LOCALSTORAGE_APP_STATE);
  });
  return props.user ? null : <Redirect to="/login" />;
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  resetAppState: () => dispatch(reset())
});

export const Logout = connect(mapStateToProps, mapDispatchToProps)(LogoutView);
