import React from "react";
import { Card, Row, Col, Descriptions, Table, Divider } from "antd";

import { CustomBarChart, CustomBarChart2 } from "./CustomBarChart";
import { CustomPieChart } from "./CustomPieChart";
import * as R from "ramda";
import { formatNumber, formatPrice } from "./util/Formatter";

import {
  APARTAMENTOS,
  MORADIAS,
  LOJAS,
  ESCRITORIOS,
  ARMAZENS,
  TERRENOS,
  OUTROS,
} from "./constants";

const subtypes = {
  Apartment: APARTAMENTOS,
  House: MORADIAS,
  CommercialProperty: LOJAS,
  Office: ESCRITORIOS,
  IndustrialPremise: ARMAZENS,
  Land: TERRENOS,
  Plot: TERRENOS,
};

const typologies = {
  LE_T1: "≤T1",
  T2: "T2",
  T3: "T3",
  T4: "T4",
  GE_T5: "≥T5",
};

// key is purposeId
const SEGMENTS = {
  1: {
    GE_750000: "≥ 750.000 €",
    B_500000_750000: "500.000 €\n750.000 €",
    B_400000_500000: "400.000 €\n500.000 €",
    B_300000_400000: "300.000 €\n400.000 €",
    B_200000_300000: "200.000 €\n300.000 €",
    B_100000_200000: "100.000 €\n200.000 €",
    LE_100000: "≤ 100.000 €",
  },
  2: {
    GE_3000: "≥ 3.000 €",
    B_2500_3000: "2.500 €\n3.000 €",
    B_2000_2500: "2.000 €\n2.500 €",
    B_1500_2000: "1.500 €\n2.000 €",
    B_1000_1500: "1.000 €\n1.500 €",
    B_500_1000: "500 €\n1.000 €",
    LE_500: "≤ 500 €",
  },
};

const aggregateBySubtype = ({
  numberOfPropertiesBySubtypeForMunicipality,
  numberOfPropertiesBySubtypeForCountry,
}) => {
  let result = {};
  let municipality_total = 0;
  let country_total = 0;
  numberOfPropertiesBySubtypeForMunicipality.forEach((d) => {
    const { subtype, count } = d;
    const key = subtypes[subtype.name] || OUTROS;
    if (result[key] === undefined) {
      result[key] = {
        municipality: 0,
        country: 0,
      };
    }
    result[key].municipality += count;
    municipality_total += count;
  });

  numberOfPropertiesBySubtypeForCountry.forEach((d) => {
    const { subtype, count } = d;
    const key = subtypes[subtype.name] || OUTROS;
    if (result[key] === undefined) {
      result[key] = {
        municipality: 0,
        country: 0,
      };
    }
    result[key].country += count;
    country_total += count;
  });

  const res = [
    APARTAMENTOS,
    MORADIAS,
    LOJAS,
    ESCRITORIOS,
    ARMAZENS,
    TERRENOS,
    OUTROS,
  ].map((s) => {
    const theSubtype = result[s] || {
      municipality: 0,
    };
    const municipality_count = theSubtype.municipality;
    const country_count = theSubtype.country;
    return {
      name: s,
      municipality: theSubtype.municipality,
      municipality_percentage: municipality_total
        ? Number(((100 * municipality_count) / municipality_total).toFixed(1))
        : 0,
      country: theSubtype.country,
      country_percentage: country_total
        ? Number(((100 * country_count) / country_total).toFixed(1))
        : 0,
    };
  });
  // console.log("res", res);
  return res;
};

const aggregateByTypology = (numberOfPropertiesByTypologyForMunicipality) => {
  let result = {};
  let municipality_total = 0;
  numberOfPropertiesByTypologyForMunicipality.forEach((d) => {
    const { number_of_bedrooms, count } = d;
    if (number_of_bedrooms) {
      let key = null;
      if (number_of_bedrooms <= 1) {
        key = typologies.LE_T1;
      } else if (number_of_bedrooms >= 5) {
        key = typologies.GE_T5;
      } else {
        key = typologies[`T${number_of_bedrooms}`];
      }
      result[key] = result[key] || 0;
      result[key] += count;
      municipality_total += count;
    }
  });

  const res = Object.values(typologies).map((s) => {
    const value = result[s] || 0;
    const municipality_count = value;
    return {
      name: s,
      municipality: value,
      municipality_percentage: municipality_total
        ? Number(((100 * municipality_count) / municipality_total).toFixed(1))
        : 0,
    };
  });
  return res;
};

export const OfertaByType = ({
  name,
  numberOfPropertiesBySubtypeForMunicipality,
  numberOfPropertiesBySubtypeForCountry,
  show,
}) => {
  if (
    !numberOfPropertiesBySubtypeForMunicipality ||
    !numberOfPropertiesBySubtypeForCountry
  )
    return null;

  const dataBySubtype = aggregateBySubtype({
    numberOfPropertiesBySubtypeForMunicipality,
    numberOfPropertiesBySubtypeForCountry,
  });

  const total = dataBySubtype.reduce((acc, item) => {
    return acc + item.municipality;
  }, 0);

  const toShow = dataBySubtype.filter((d) => show.includes(d.name));

  return (
    <Card
      title={
        <div style={{ textAlign: "center" }}>Oferta Imobiliária por tipo</div>
      }
    >
      <Row type="flex" justify="center">
        <Col span={6} style={{ width: 600 }}>
          <Descriptions
            size="small"
            bordered
            style={{ width: "50%", margin: "10px auto 20px auto" }}
          >
            <Descriptions.Item label="Número Imóveis Oferta">
              {total}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col span={6} style={{ width: 600 }}>
          <CustomBarChart
            data={dataBySubtype}
            barNames={[name, "Portugal"]}
            barDataKeys={["municipality_percentage", "country_percentage"]}
            // unit="%"
            yUnit="%"
          />
        </Col>
        <Col span={5} style={{ width: 500 }}>
          <CustomPieChart data={toShow} dataKey="municipality" width={500} />
        </Col>
      </Row>
    </Card>
  );
};

export const InnerOfferSubtypeByPurpose = ({
  subtypeName,
  numberRenda,
  numberVenda,
  numberOutro,
}) => {
  return (
    <>
      <Row type="flex" justify="center">
        <Col span={12} style={{ width: 1400 }}>
          <Descriptions
            size="small"
            bordered
            style={{ width: "50%", margin: "10px auto 20px auto" }}
          >
            <Descriptions.Item label={`Número ${subtypeName} Venda`}>
              {numberVenda}
            </Descriptions.Item>
            <Descriptions.Item label={`Número ${subtypeName} Arrendamento`}>
              {numberRenda}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <CustomPieChart
        width={800}
        outerRadius="80%"
        dataKey="count"
        data={[
          {
            name: "Arrendamento",
            count: numberRenda,
          },
          {
            name: "Venda",
            count: numberVenda,
          },
          {
            name: "Outro",
            count: numberOutro,
          },
        ]}
      />
    </>
  );
};

export const OfferSubtypeByPurpose = ({
  subtypeName,
  numberRenda,
  numberVenda,
  numberOutro,
}) => {
  return (
    <Card
      title={
        <div
          style={{ textAlign: "center" }}
        >{`Oferta ${subtypeName} por tipo`}</div>
      }
      style={{ margin: "40px auto" }}
    >
      <InnerOfferSubtypeByPurpose
        subtypeName={subtypeName}
        numberRenda={numberRenda}
        numberVenda={numberVenda}
        numberOutro={numberOutro}
      />
    </Card>
  );
};

export const OfferSubtypeByTypology = ({ title, data }) => {
  if (!data) return null;
  // console.log("XXX data", data);
  const aggregatedData = aggregateByTypology(data);
  // console.log("XXX aggregateData", aggregatedData);
  return (
    <Card
      title={<div style={{ textAlign: "center" }}>{title}</div>}
      style={{ margin: "40px auto" }}
    >
      <CustomBarChart
        data={aggregatedData}
        barNames={["Value"]}
        barDataKeys={["municipality_percentage"]}
        legendType="none"
        yUnit="%"
      />
    </Card>
  );
};

const aggregateByPriceRangeForSubtype = (data, { purposeId }) => {
  let result = {};
  let total = 0;
  const segments = SEGMENTS[purposeId];
  data.forEach((d) => {
    const { segmento, count } = d;
    const key = segments[segmento];
    result[key] = result[key] || 0;
    result[key] += count;
    total += count;
  });

  const res = Object.values(segments).map((s) => {
    const value = result[s] || 0;
    const count = value;
    return {
      name: s,
      value: value,
      percentage: total ? Number(((100 * count) / total).toFixed(0)) : 0,
    };
  });
  return res;
};

export const OfferSubtypeByPriceRange = ({ title, data, purposeId }) => {
  if (!data) return null;

  let aggregatedData = aggregateByPriceRangeForSubtype(data, {
    purposeId,
  });
  let biggestPercentage = 0;
  aggregatedData = aggregatedData.map((d) => {
    if (d.percentage > biggestPercentage) {
      biggestPercentage = d.percentage;
    }
    return {
      ...d,
      range: [-d.percentage / 2, d.percentage / 2],
    };
  });

  return (
    <Card
      title={<div style={{ textAlign: "center" }}>{title}</div>}
      style={{ margin: "40px auto" }}
    >
      <CustomBarChart2
        layout="vertical"
        data={aggregatedData}
        barNames={["Value"]}
        barDataKeys={["municipality_percentage"]}
        legendType="none"
        yUnit="%"
        domain={[-(biggestPercentage + 10) / 2, (biggestPercentage + 10) / 2]}
      />
    </Card>
  );
};

const OfferByAverageArea = ({ title, name, data }) => {
  let columns = Object.entries(typologies).map(([key, name]) => ({
    title: name,
    dataIndex: key,
    key,
  }));
  columns = [{ title, dataIndex: "name", key: "name" }, ...columns];

  let row = { key: "row", name };
  data.forEach(({ typology, average }) => {
    row = { ...row, [typology]: average };
  });
  const dataSource = [row];
  // console.log("columns", columns);
  // console.log("dataSource", dataSource);
  return (
    <Table
      key={title}
      style={{ width: 600, margin: "auto" }}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
    />
  );
};

export const OfferByAverageAreaArray = ({ data }) => {
  if (!data) return null;
  // console.log("data", data);
  const anyIsNil = R.any(({ data }) => R.isNil(data), data);

  if (anyIsNil) return null;

  return (
    <Card style={{ margin: "40px auto" }}>
      {data.map((d) => OfferByAverageArea(d))}
    </Card>
  );
};

export const OfferByAveragePrice = ({ data, title }) => {
  if (!data) return null;
  let finalData = [];
  Object.entries(typologies).forEach(([key, name]) => {
    const found = R.find((d) => d.typology === key)(data);
    const entry = { ...found, typology: name } || {
      typology: name,
      average: 0,
      count: 0,
    };
    finalData = [...finalData, entry];
  });
  return (
    <Card
      title={<div style={{ textAlign: "center" }}>{title}</div>}
      style={{ margin: "40px auto" }}
    >
      <CustomBarChart
        data={finalData}
        barNames={["average"]}
        barDataKeys={["average"]}
        legendType="none"
        xDataKey="typology"
        yUnit=" €"
        yTickFormatter={(v) => formatNumber(v)}
        formatters={{
          average: formatPrice,
        }}
        tooltipValues={[{ name: "count" }]}
      />
    </Card>
  );
};

export const TableOfAverages = ({ title, data }) => {
  const columns = [
    { title: "name", dataIndex: "name", key: "name", width: 400 },
    {
      title: "value",
      dataIndex: "value",
      key: "value",
      render: (v) => (v && v !== 0 && v !== "0" ? v : "-"),
    },
  ];
  return (
    <Table
      showHeader={false}
      key={title}
      style={{ width: 600, margin: "auto" }}
      dataSource={data}
      columns={columns}
      pagination={false}
    />
  );
};

export const CommercialOfferBySubtype = ({ subtypeName, data }) => {
  return (
    <Card
      title={
        <div
          style={{ textAlign: "center" }}
        >{`Oferta ${subtypeName} por tipo`}</div>
      }
      style={{ margin: "40px auto" }}
    >
      <InnerOfferSubtypeByPurpose
        subtypeName={subtypeName}
        numberRenda={data?.numberOfLojasRenda?.count}
        numberVenda={data?.numberOfLojasVenda?.count}
        numberOutro={data?.numberOfLojasOutro?.count}
      />
      <Divider />
      <TableOfAverages
        title="Venda"
        data={[
          {
            key: "avgPriceSell",
            name: "Valor Médio de Venda",
            value: formatPrice(data?.avgPriceLojasVenda?.average, 0),
          },
          {
            key: "avgAreaSell",
            name: "Área Média",
            value: formatNumber(data?.avgAreaLojasVenda?.average),
          },
          {
            key: "avgSQMPriceSell",
            name: "€/m2 Venda",
            value: formatPrice(data?.avgSQMPriceLojasVenda?.average, 0),
          },
        ]}
      />
      <Divider />
      <TableOfAverages
        title="Arrendamento"
        data={[
          {
            key: "avgPriceRent",
            name: "Valor Médio de Arrendamento",
            value: formatPrice(data?.avgPriceLojasArrendamento?.average, 0),
          },
          {
            key: "avgAreaRent",
            name: "Área Média",
            value: formatNumber(data?.avgAreaLojasArrendamento?.average),
          },
          {
            key: "avgSQMPriceRent",
            name: "€/m2 Arrendamento",
            value: formatPrice(data?.avgSQMPriceLojasArrendamento?.average, 0),
          },
        ]}
      />
    </Card>
  );
};
