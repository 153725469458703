import React from "react";
import { Query } from "./Query";
import { parse } from "json2csv";

import { Table, Card, Col, Row, Button, List } from "antd";

import { allPropertiesCoefficientsQuery, queryUserSettings } from "./Queries";

import { EditableFormRow } from "./EditableTable/EditableRow";
import { EditableCell } from "./EditableTable/EditableCell";

import {
  renderSelectPrice,
  renderFilterByNumberOfBedrooms
} from "./TableRender";

import * as Statistics from "./util/Statistics";
import { setupHomogenizationColumns } from "./propertyColumns";
import { formatNumber } from "./util/Formatter";

import * as R from "ramda";

const round2dp = num => Math.round((num + 0.00001) * 100) / 100;

function downloadCSV({ filename, fields, data: stockData }) {
  const validFields = fields
    .filter(o => o.value)
    .map(o => {
      switch (o.label) {
        case "Ref":
          return {
            label: "Ref",
            value: obj => {
              const url = R.path(["url"], obj);
              const ref = R.path(o.value.split("."), obj);
              const result = `=HYPERLINK("${url}";"${ref}")`;
              return result;
            }
          };

        default:
          return o;
        // const x =
        //   typeof o.value === "function"
        //     ? obj => {
        //         const newV = o.value(obj);
        //         return newV ? `="${newV}"` : '=""';
        //       }
        //     : o.value
        //     ? obj => {
        //         const result = R.path(o.value.split("."), obj);
        //         return typeof result == "number" || result
        //           ? `="${result}"`
        //           : `=""`;
        //       }
        //     : `=""`;
        //
        // return { label: o.label, value: x };
      }
    });

  const opts = {
    fields: validFields,
    // excelStrings: true,
    withBOM: true,
    delimiter: ";"
  };

  let csv = parse(stockData, opts);

  if (csv == null) return;
  filename = filename || "export.csv";

  // console.log("CSV", csv);
  if (!csv.match(/^data:text\/csv/i)) {
    // csv = "data:text/csv;charset=utf-8," + "sep=,\n\n" + csv;
    csv = "data:text/csv;charset=utf8," + csv;
  }
  const data = encodeURI(csv);

  let link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", filename);
  link.click();
}

const buildStatisticsArray = (priceData, areaData) => {
  const areaAverage = Statistics.average(areaData);
  const data = [
    {
      key: "1",
      name: "Média",
      value: Statistics.average(priceData),
      suffix: "€"
    },
    {
      key: "2",
      name: "Mediana",
      value: Statistics.median(priceData),
      suffix: "€"
    },
    {
      key: "3",
      name: "Desvio Padrão",
      value: Statistics.sampleStandardDeviation(priceData),
      suffix: "€"
    },
    {
      key: "4",
      name: "Área Média (m2)",
      value: areaAverage,
      suffix: "m2"
    },
    {
      key: "5",
      name: "1º Quartil",
      value: Statistics.quartile25(priceData),
      suffix: "€"
    },
    {
      key: "6",
      name: "2º Quartil",
      value: Statistics.quartile50(priceData),
      suffix: "€"
    },
    {
      key: "7",
      name: "3º Quartil",
      value: Statistics.quartile75(priceData),
      suffix: "€"
    },
    {
      key: "8",
      name: "Valor Mínimo",
      value: Math.min(...priceData),
      suffix: "€"
    },
    {
      key: "9",
      name: "Valor Máximo",
      value: Math.max(...priceData),
      suffix: "€"
    }
  ];
  return data.map(o => ({ ...o, value: round2dp(o.value) }));
};

export const HomogenizationTable = ({
  where,
  selectedProperties,
  activeWeights: stateWeights,
  highlightedProperty,
  onWeightChange
}) => {
  // const selectedPropertiesIds = Object.keys(selectedProperties);

  const sortedSelectedIds = Object.keys(selectedProperties)
    .concat()
    .sort();

  return where ? (
    <Query query={queryUserSettings}>
      {({ error, loading, data }) => {
        // if (error) {
        //   console.log(error);
        //   return <div>Error</div>;
        // }
        if (loading) {
          return null;
        }
        const { fields: allFields, userSettings } = data;
        const { homogenizationParams, fieldsSettings } = userSettings;

        const fields = allFields.reduce(
          (acc, value) => ({ ...acc, [value.id]: value }),
          {}
        );

        const { fixedIds, floatingIds } = fieldsSettings.homogenization;
        const columnsFixedLeft = fixedIds.map(id => fields[id]);
        const columnsFloating = floatingIds.map(id => fields[id]);

        const activeWeights = homogenizationParams.filter(param => {
          return param.id in stateWeights
            ? stateWeights[param.id]
            : param.enabled;
        });

        return (
          <Query
            query={allPropertiesCoefficientsQuery}
            variables={{
              where: {
                purpose: where.purpose,
                property_id_in: sortedSelectedIds
              }
            }}
          >
            {({ loading, error, data, client }) => {
              // if (error) {
              //   console.log(error);
              //   return <div>Error</div>;
              // }

              const properties = loading ? [] : data.properties;
              const filteredProperties = properties;

              const datasource = filteredProperties.map(property => {
                const {
                  id: property_id,
                  area: originalArea,
                  number_of_bedrooms: originalNumberOfBedrooms,
                  price: originalPrice
                } = property;

                let result = {
                  ...property,
                  property_id
                };

                const changedPirce = selectedProperties[property_id].price;
                const price = changedPirce || originalPrice;
                const changedArea = selectedProperties[property_id].area;
                const changedNumberOfBedrooms =
                  selectedProperties[property_id].number_of_bedrooms;
                const area = changedArea || originalArea;
                const sqm_price = area > 0 && price > 0 ? price / area : 0;

                const number_of_bedrooms =
                  changedNumberOfBedrooms || originalNumberOfBedrooms;
                const weightSum = activeWeights.reduce(
                  (acc, w) =>
                    Number(selectedProperties[property_id][w.id] || 0) + acc,
                  0
                );
                const sqm_price_fixed = sqm_price * (1 + weightSum / 100);

                activeWeights.forEach(w => {
                  const weight = Number(
                    selectedProperties[property_id][w.id] || 0
                  );
                  result[w.id] = weight;
                });
                result = {
                  ...result,
                  weightSum,
                  sqm_price_fixed,
                  area,
                  sqm_price,
                  number_of_bedrooms,
                  price,
                  originalArea,
                  originalNumberOfBedrooms,
                  originalPrice
                };

                return result;
              });

              const areaData = datasource.map(d =>
                Number(d.area) > 0 ? Number(d.area) : 0
              );
              const sqmPriceData = datasource.map(d => d.sqm_price);
              const sqmPriceFixedData = datasource.map(d => d.sqm_price_fixed);
              // const areaAverage = Statistics.average(areaData);

              const sqmPriceDatasource = buildStatisticsArray(
                sqmPriceData,
                areaData
              );
              const sqmPriceFixedDatasource = buildStatisticsArray(
                sqmPriceFixedData,
                areaData
              );

              const columnsSetup = setupHomogenizationColumns({
                renderSelectPrice,
                renderFilterByNumberOfBedrooms
              });

              let weightColumns = [];
              const sortedActiveWeights = activeWeights.concat().sort();
              sortedActiveWeights.forEach(w => {
                weightColumns.push({
                  title: w.name,
                  dataIndex: w.id,
                  width: 80,
                  fixed: "left",
                  editable: true,
                  inputType: "number"
                });
              });
              weightColumns.push({
                title: "Total",
                dataIndex: "weightSum",
                width: 80,
                fixed: "left",
                inputType: "number"
              });

              const has_trade = datasource.find(
                p => p.coefficients && p.coefficients.trade
              );
              const has_housing = datasource.find(
                p => p.coefficients && p.coefficients.housing
              );
              const has_industry = datasource.find(
                p => p.coefficients && p.coefficients.industry
              );
              const has_services = datasource.find(
                p => p.coefficients && p.coefficients.services
              );
              const has_land = datasource.find(
                p => p.coefficients && p.coefficients.land
              );

              let scroll_increment = 0;
              if (has_trade) {
                scroll_increment += 120;
                weightColumns = [
                  ...weightColumns,
                  {
                    title: "Trade",
                    dataIndex: "coefficients.trade.value",
                    fixed: "left",
                    width: 120
                  }
                ];
              }
              if (has_housing) {
                scroll_increment += 120;
                weightColumns = [
                  ...weightColumns,
                  {
                    title: "Housing",
                    dataIndex: "coefficients.housing.value",
                    fixed: "left",
                    width: 120
                  }
                ];
              }
              if (has_industry) {
                scroll_increment += 120;
                weightColumns = [
                  ...weightColumns,
                  {
                    title: "Industry",
                    dataIndex: "coefficients.industry.value",
                    fixed: "left",
                    width: 120
                  }
                ];
              }
              if (has_services) {
                scroll_increment += 120;
                weightColumns = [
                  ...weightColumns,
                  {
                    title: "Services",
                    dataIndex: "coefficients.services.value",
                    fixed: "left",
                    width: 120
                  }
                ];
              }
              if (has_land) {
                scroll_increment += 120;
                weightColumns = [
                  ...weightColumns,
                  {
                    title: "Land",
                    dataIndex: "coefficients.land.value",
                    fixed: "left",
                    width: 120,
                    render: obj => (obj ? `${obj.value} (${obj.percent}%)` : "")
                  }
                ];
              }

              const configuredColumnsFixedLeft = columnsFixedLeft.map(c => {
                return { fixed: "left", ...c, ...columnsSetup[c.tag] };
              });
              const configuredColumnsFloating = columnsFloating.map(c => {
                return { ...c, ...columnsSetup[c.tag] };
              });

              let initialColumns = [
                ...configuredColumnsFixedLeft,
                ...weightColumns,
                ...configuredColumnsFloating,
                columnsSetup.empty
              ];

              const columns = initialColumns.map(col => {
                if (!col.editable) {
                  return col;
                }
                return {
                  ...col,
                  onCell: record => ({
                    record,
                    inputType: col.inputType || "text",
                    inputMin: col.inputMin,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    inputRender: col.inputRender,
                    editing: true,
                    onClear: col.allowClear ? onWeightChange : null,
                    onBlur: e => {
                      if (col.inputType !== "select") {
                        const propertyId = record.property_id;
                        const weightId = col.dataIndex;
                        const weightValue = e.target.value;
                        return onWeightChange(
                          propertyId,
                          weightId,
                          weightValue
                        );
                      }
                    }
                  })
                };
              });

              const components = {
                body: {
                  row: EditableFormRow,
                  cell: EditableCell
                }
              };

              const csvData2 = sqmPriceDatasource.map((row, i) => {
                return {
                  name: row.name,
                  asked: formatNumber(row.value, 2),
                  fixed: formatNumber(sqmPriceFixedDatasource[i].value, 2)
                };
              });
              const csvColumns2 = [
                { label: "Name", value: "name" },
                { label: "Pedido", value: "asked" },
                { label: "Ajustado", value: "fixed" }
              ];

              return (
                <>
                  <Card>
                    <Table
                      size="small"
                      components={components}
                      loading={loading}
                      rowKey="id"
                      dataSource={datasource}
                      columns={columns}
                      bordered
                      scroll={{
                        x: 3100 + scroll_increment + activeWeights.length * 100
                      }}
                      rowClassName={record =>
                        record.property_id === highlightedProperty
                          ? "highlighted-row"
                          : "row"
                      }
                      pagination={false}
                      footer={() => (
                        <Button
                          data-testid="download-csv-button"
                          type="primary"
                          shape="circle"
                          icon="download"
                          onClick={() => {
                            let fields = columns.map(c => {
                              return {
                                label: c.title,
                                value: c.csv || c.dataIndex
                              };
                            });
                            fields.push({ label: "URL", value: "url" });
                            downloadCSV({
                              filename: "homogenization_data.csv",
                              data: datasource,
                              fields
                            });
                          }}
                        />
                      )}
                    />
                    {/* <div>&nbsp;</div>
                  <div>&nbsp;</div> */}
                  </Card>
                  <HomogenizationStatistics
                    data={csvData2}
                    fields={csvColumns2}
                    sqmPriceDatasource={sqmPriceDatasource}
                    sqmPriceFixedDatasource={sqmPriceFixedDatasource}
                  />
                </>
              );
            }}
          </Query>
        );
      }}
    </Query>
  ) : null;
};

const HomogenizationStatistics = ({
  data,
  fields,
  sqmPriceDatasource,
  sqmPriceFixedDatasource
}) => {
  return (
    <Card>
      <List
        bordered={true}
        footer={
          <Button
            data-testid="download-statistics-analyses-button"
            type="primary"
            shape="circle"
            icon="download"
            onClick={() => {
              downloadCSV({
                filename: "statistical_analysis.csv",
                data,
                fields
              });
            }}
          />
        }
      >
        <Row gutter={16} type="flex" justify="space-around">
          <Col span={8} style={{ paddingTop: "50px" }}>
            <Card>
              <Table
                size="small"
                dataSource={sqmPriceDatasource}
                pagination={false}
              >
                <Table.ColumnGroup title="Análise Estatistica  - Valores Pedidos">
                  <Table.Column dataIndex="name" key="name" />
                  <Table.Column
                    dataIndex="value"
                    key="value"
                    render={(text, record) => `${text} ${record.suffix || ""}`}
                  />
                </Table.ColumnGroup>
              </Table>
            </Card>
          </Col>
          <Col span={8} style={{ paddingTop: "50px" }}>
            <Card>
              <Table
                size="small"
                dataSource={sqmPriceFixedDatasource}
                pagination={false}
              >
                <Table.ColumnGroup title="Análise Estatistica - Valores Ajustados">
                  <Table.Column dataIndex="name" key="name" />
                  <Table.Column
                    dataIndex="value"
                    key="value"
                    render={(text, record) => `${text} ${record.suffix || ""}`}
                  />
                </Table.ColumnGroup>
              </Table>
            </Card>
          </Col>
        </Row>
      </List>
    </Card>
  );
};
