import React from "react";
import Downshift from "downshift";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Input, Menu, Row } from "antd";
import { parseGPS } from "./util/parsers";
import { ApolloConsumer } from "react-apollo";
import { parishQuery } from "./Queries";

import { inputToRegex, charsToFilter  } from "./utils";

const searchQuery = gql`
  query(
    $nameRegex: String!
    $withDistrict: Boolean! = false
    $withMunicipality: Boolean! = false
    $withParish: Boolean! = false
    $withSiteRef: Boolean! = false
  ) {
    districts(where: { name_regex: $nameRegex }) @include(if: $withDistrict) {
      id
      name
    }
    municipalities(where: { name_regex: $nameRegex })
      @include(if: $withMunicipality) {
      id
      name
    }
    parishes(where: { name_regex: $nameRegex }) @include(if: $withParish) {
      id
      name
      municipality {
        id
        name
      }
    }

    siteRefs(regex: $nameRegex) @include(if: $withSiteRef) {
      property_id
      site_ref
    }

    # siteRefs: properties(where: { site_ref_regex: $nameRegex })
    #   @include(if: $withSiteRef) {
    #   id
    #   site_ref
    #   parish {
    #     id
    #     name
    #   }
    # }

    # ids: properties(where: { property_id_regex: $nameRegex })
    #   @include(if: $withSiteRef) {
    #   id
    # }
  }
`;

const Item = ({
  index,
  name,
  highlightedIndex,
  selectedItem,
  inputValue,
  ...props
}) => {
  const filtered = inputValue.replace(charsToFilter, " ");
  const matches = match(name, filtered);
  const parts = parse(name, matches);

  return (
    <div
      className="content"
      {...props}
      style={{
        backgroundColor: highlightedIndex === index ? "gray" : "white",
        fontWeight: selectedItem === name ? "bold" : "normal"
      }}
    >
      {parts.map((part, index) => {
        const style = part.highlight ? { color: "red" } : {};
        return (
          <span key={index} style={style}>
            {part.text}
          </span>
        );
      })}
    </div>
  );
};

const Items = ({
  loading,
  error,
  data,
  highlightedIndex,
  selectedItem,
  getItemProps,
  inputValue
}) => {
  if (error) {
    return <div>{error.message}</div>;
  }
  const { districts, municipalities, parishes, siteRefs, ids } = data;
  const numberOfDistricts = districts ? districts.length : 0;
  const numberOfMunicipalities = municipalities ? municipalities.length : 0;
  const numberOfParishes = parishes ? parishes.length : 0;
  const numberOfSiteRefs = siteRefs ? siteRefs.length : 0;
  return loading ? null : (
    <Menu
      style={{
        borderStyle: "ridge",
        width: 800,
        position: "fixed",
        zIndex: 100
      }}
    >
      {districts &&
        districts.slice(0, 30).map((item, index) => {
          const { id, name } = item;
          return (
            <Menu.Item key={id}>
              <Item
                index={index}
                name={`${name} (Distrito)`}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                inputValue={inputValue}
                {...getItemProps({ item: item })}
              />
            </Menu.Item>
          );
        })}
      {municipalities &&
        municipalities.slice(0, 30).map((item, index) => {
          const { id, name } = item;
          return (
            <Menu.Item key={id}>
              <Item
                id={id}
                index={index + numberOfDistricts}
                name={`${name} (Concelho)`}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                inputValue={inputValue}
                {...getItemProps({ item: item })}
              />
            </Menu.Item>
          );
        })}
      {parishes &&
        parishes.slice(0, 30).map((item, index) => {
          const { id, name, municipality } = item;
          const municipality_name = municipality
            ? `, ${municipality.name}`
            : "";
          return (
            <Menu.Item key={id}>
              <Item
                index={index + numberOfDistricts + numberOfMunicipalities}
                name={`${name}${municipality_name} (Freguesia)`}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                inputValue={inputValue}
                {...getItemProps({ item: item })}
              />
            </Menu.Item>
          );
        })}
      {siteRefs &&
        siteRefs.slice(0, 30).map((item, index) => {
          const { site_ref } = item;
          // const site_ref = item;
          // const nn = { id: site_ref, site_ref, __typename: "siteRef" };
          return (
            <Menu.Item key={site_ref}>
              <Item
                index={
                  index +
                  numberOfDistricts +
                  numberOfMunicipalities +
                  numberOfParishes
                }
                name={`${site_ref} (Ref)`}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                inputValue={inputValue}
                {...getItemProps({ item })}
              />
            </Menu.Item>
          );
        })}
      {ids &&
        ids.slice(0, 30).map((item, index) => {
          const { id } = item;
          return (
            <Menu.Item key={id}>
              <Item
                index={
                  index +
                  numberOfDistricts +
                  numberOfMunicipalities +
                  numberOfParishes +
                  numberOfSiteRefs
                }
                name={`${id} (Id)`}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                inputValue={inputValue}
                {...getItemProps({ item: item })}
              />
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

const FetchSelector = ({
  fetchDistricts,
  fetchMunicipalities,
  fetchParishes,
  nameRegex,
  ...props
}) => {
  const query = searchQuery;
  const variables = {
    nameRegex,
    withDistrict: fetchDistricts,
    withMunicipality: fetchMunicipalities,
    withParish: fetchParishes,
    withSiteRef: true
  };

  const params = useQuery(query, { variables });
  if (!params || params.loading) {
    return null;
  }

  return <Items {...params} {...props} />;
};

const BasicAutocomplete = ({
  fetchDistricts,
  fetchMunicipalities,
  fetchParishes,
  onChange,
  onStateChange,
  itemToString,
  selectedItem
}) => (
  <Downshift
    onChange={onChange}
    itemToString={itemToString}
    onStateChange={onStateChange}
    selectedItem={selectedItem}
  >
    {({
      getInputProps,
      getItemProps,
      isOpen,
      inputValue,
      selectedItem,
      highlightedIndex,
      closeMenu
    }) => (
      <div>
        <Row>
          <div>
            <ApolloConsumer>
              {client => (
                <Input
                  style={{ width: 800 }}
                  align="center"
                  {...getInputProps({
                    placeholder: "Search a district...",
                    onKeyDown: async event => {
                      if (event.key === "Enter" && highlightedIndex === null) {
                        const gps = parseGPS(inputValue);
                        if (gps) {
                          const {
                            data: { parish }
                          } = await client.query({
                            query: parishQuery,
                            variables: {
                              parishWhere: gps
                            }
                          });
                          if (parish) {
                            closeMenu();
                            onChange({
                              gps,
                              parish,
                              __typename: "gps"
                            });
                          }
                        }
                      } else if (event.key === "Escape") {
                        onChange({
                          __typename: "cancel"
                        });
                      }
                    }
                  })}
                />
              )}
            </ApolloConsumer>

            {isOpen ? (
              <FetchSelector
                fetchDistricts={fetchDistricts}
                fetchMunicipalities={fetchMunicipalities}
                fetchParishes={fetchParishes}
                nameRegex={inputToRegex(inputValue)}
                selectedItem={selectedItem}
                highlightedIndex={highlightedIndex}
                getItemProps={getItemProps}
                inputValue={inputValue}
              />
            ) : null}
          </div>
        </Row>
      </div>
    )}
  </Downshift>
);

const PropertyDivisionSearch = ({ onChange }) => {
  const [text, setText] = React.useState("");
  const onStateChange = changes => {
    // if (changes.hasOwnProperty("selectedItem")) {
    if (changes.selectedItem) {
      setText(changes.selectedItem.name);
    } else if (changes.hasOwnProperty("inputValue")) {
      setText(changes.inputValue);
    }
  };
  return (
    <BasicAutocomplete
      fetchDistricts
      fetchMunicipalities
      fetchParishes
      onChange={onChange}
      onStateChange={onStateChange}
      selectedItem={text}
      // itemToString={item => (item == null ? text : item.name)}
      itemToString={_ => text}
    />
  );
};

export default PropertyDivisionSearch;
