import React from "react";
import DropdownWithQuery from "./DropdownWithQuery";

import { connect } from "react-redux";
import { setConditions } from "./actions";

import { conditions_Query } from "./Queries";

const FilterByConditionView = ({ value, onChange }) => {
  return (
    <DropdownWithQuery
      mode="multiple"
      queryName="conditions"
      query={conditions_Query}
      placeholder="Condition"
      selection
      value={value}
      onChange={value => onChange(value)}
    />
  );
};

const mapStateToProps = state => ({
  value: state.filters.property.conditions
});

const mapDispatchToProps = dispatch => ({
  onChange: ids => dispatch(setConditions(ids))
});

export const FilterByCondition = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByConditionView);
