import React from "react";
import { Query } from "./Query";
import { Row, Col } from "antd";
import { statisticsMacroQuery } from "./Queries";
import { MacroLicenciamentoVsObrasConcluidas } from "./MacroLicenciamentoVsObrasConcluidas";
import { MacroIndicadoresSocioDemograficos } from "./MacroIndicadoresSocioDemograficos";
import { MacroTransacoes } from "./MacroTransacoes";
import { MacroPurchasingPower } from "./MacroPurchasingPower";
import { MacroRentVsSale } from "./MacroRentVsSale";
import * as R from "ramda";

export const StatisticsMacroCharts = ({ municipality }) => {
  return (
    <Query
      query={statisticsMacroQuery}
      variables={{ municipality_id: municipality.key }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <div>Loading...</div>;
        }
        if (error) {
          throw error;
        }
        const {
          macroLicenciamento,
          macroObrasConcluidas,
          macroLicenciamentoVsObrasConcluidas,
          macroTransacoes,
          macroPopulation,
          macroPopulationEstimate,
          macroFamilies,
          macroAccomodation,
          macroAccomodationEstimate,
          macroOwnersVsTenants,
          macroPurchasingPower,
          macroRentVsSale,
          macroTotalRents
        } = data;

        const licenciamentoByTopologyMap = R.reduce(
          (acc, elem) => {
            return {
              ...acc,
              "≤T1": (acc["≤T1"] || 0) + elem.t0_t1,
              T2: (acc.T2 || 0) + elem.t2,
              T3: (acc.T3 || 0) + elem.t3,
              "≥T4": (acc["≥T4"] || 0) + elem.t4_more
            };
          },
          {},
          macroLicenciamento
        );
        const licenciamentoByTypology = R.toPairs(
          licenciamentoByTopologyMap
        ).map(p => {
          const [name, value] = p;
          return { name, value };
        });

        const obrasConcluidasByTopologyMap = R.reduce(
          (acc, elem) => {
            return {
              ...acc,
              "≤T1": (acc["≤T1"] || 0) + elem.t0_t1,
              T2: (acc.T2 || 0) + elem.t2,
              T3: (acc.T3 || 0) + elem.t3,
              "≥T4": (acc["≥T4"] || 0) + elem.t4_more
            };
          },
          {},
          macroObrasConcluidas
        );
        const obrasConcluidasByTypology = R.toPairs(
          obrasConcluidasByTopologyMap
        ).map(p => {
          const [name, value] = p;
          return { name, value };
        });
        return (
          <>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={24}>
                <MacroIndicadoresSocioDemograficos
                  name={municipality.label}
                  population={macroPopulation}
                  populationEstimate={macroPopulationEstimate}
                  families={macroFamilies}
                  accomodation={macroAccomodation}
                  accomodationEstimate={macroAccomodationEstimate}
                  ownersVsTenants={macroOwnersVsTenants}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <MacroPurchasingPower data={macroPurchasingPower} />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={24}>
                <MacroLicenciamentoVsObrasConcluidas
                  data={macroLicenciamentoVsObrasConcluidas}
                  licenciamentoByTypology={licenciamentoByTypology}
                  obrasConcluidasByTypology={obrasConcluidasByTypology}
                  numberOfYearsOfLicenciamento={macroLicenciamento.length}
                  numberOfYearsOfObrasConcluidas={macroObrasConcluidas.length}
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={24}>
                <MacroTransacoes data={macroTransacoes} />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={24}>
                <MacroRentVsSale
                  data={macroRentVsSale}
                  totalRents={macroTotalRents}
                  municipality={municipality}
                />
              </Col>
            </Row>
          </>
        );
      }}
    </Query>
  );
};
