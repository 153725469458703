import React from "react";
import NumberFormat from "react-number-format";
import { formatPrice } from "./util/Formatter";
import { Popover, Button, Select, Icon, Tag } from "antd";

import { FilterByNumberOfBedroomsClass } from "./FilterByNumberOfBedrooms";
import { TYPOLOGY } from "./containers/typologies";
import { uniq } from "./util/uniq";

const FormatedPrice = price => {
  return price ? (
    <NumberFormat
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      decimalScale={2}
      fixedDecimalScale
      suffix={" €"}
      value={price}
    />
  ) : (
    <NumberFormat
      displayType={"text"}
      decimalSeparator={","}
      thousandSeparator={"."}
      decimalScale={2}
      fixedDecimalScale
      suffix={" €"}
    />
  );
};

export const renderPrice = (text, record) => {
  const priceHistory = record.price_history;
  const priceElem = FormatedPrice(text);
  if (priceHistory.length === 1) {
    return priceElem;
  } else {
    const sortedByDate = priceHistory
      .concat()
      .sort((p1, p2) => (p1.date < p2.date ? -1 : p1.date > p2.date ? 1 : 0));

    const content = sortedByDate.map(p => (
      <div key={p.date}>
        {p.date} - {formatPrice(p.price)}
      </div>
    ));

    const distinctPriceHistory = sortedByDate.reduce((acc, current) => {
      const accLength = acc.length;
      if (current.price === null) {
        return acc;
      } else if (accLength === 0) {
        return [current];
      } else if (current.price === acc[accLength - 1].price) {
        return acc;
      } else {
        return [...acc, current];
      }
    }, []);

    const length = distinctPriceHistory.length;
    let color = "";
    if (distinctPriceHistory.length > 1) {
      const last1 = distinctPriceHistory[length - 1];
      const last2 = distinctPriceHistory[length - 2];
      if (last1.price > last2.price) {
        color = "red";
      } else {
        color = "blue";
      }
    }
    if (content.length === 1) {
      return priceElem;
    } else {
      return (
        <Popover content={content}>
          <Tag color={color}>{priceElem}</Tag>
          {/* <Button type="primary">{priceElem}</Button> */}
        </Popover>
      );
    }
  }
};

const priceElementTemplate = FormatedPrice();

export const renderSelectPrice = (form, record, dataIndex, onClear) => {
  const priceHistory = record.price_history;
  if (priceHistory.length === 1) {
    return priceElementTemplate;
  } else {
    const sortedHistory = priceHistory
      .concat()
      .sort((p1, p2) => (p1.date < p2.date ? -1 : p1.date > p2.date ? 1 : 0))
      .reduce((acc, current) => {
        const accLength = acc.length;
        if (accLength === 0) {
          return [current];
        } else if (current.price === acc[accLength - 1].price) {
          return acc;
        } else {
          return [...acc, current];
        }
      }, []);

    const content = sortedHistory.map(p => (
      <div key={p.date}>
        {p.date} - {formatPrice(p.price)}
      </div>
    ));

    if (content.length === 1) {
      return priceElementTemplate;
    } else {
      return (
        <Popover content={content}>
          <Select
            size="small"
            value={form.getFieldValue("price")}
            style={{ width: 150 }}
            allowClear={false}
            mode="default"
            onChange={value => {
              form.setFieldsValue({
                price: value
              });
              return onClear(record.property_id, "price", value);
            }}
            suffixIcon={
              <Icon
                type="close-circle"
                onClick={e => {
                  form.setFieldsValue({
                    price: String(record.originalPrice || "")
                  });
                  return onClear(record.property_id, "price", null);
                }}
              />
            }
          >
            {uniq(
              sortedHistory
                .concat()
                .sort((a, b) => (a.price < b.price ? -1 : a > b ? 1 : 0)),
              elem => elem.price
            ).map(option => (
              <Select.Option key={option.price}>
                {option.price ? FormatedPrice(option.price) : <div>&nbsp;</div>}
              </Select.Option>
            ))}
          </Select>
        </Popover>
      );
    }
  }
};

const lengthLimit = 50;
export const renderLocation = (obj, onClick) => {
  if (!obj) return null;
  const button = (
    <Button
      size="small"
      type="ghost"
      onClick={event => {
        event.stopPropagation();
        onClick(obj);
      }}
    >
      {obj.name.length > lengthLimit
        ? obj.name.substring(0, lengthLimit - 4) + " ..."
        : obj.name}
    </Button>
  );
  return obj.name.length > lengthLimit ? (
    <Popover content={obj.name}>{button}</Popover>
  ) : (
    <>{button}</>
  );
};

export const renderFilterByNumberOfBedrooms = (
  form,
  record,
  dataIndex,
  onClear
) => {
  // console.log(
  //   "renderFilterBuNumberOfBedrooms::form:%o,record:%o",
  //   form,
  //   record
  // );
  return (
    <FilterByNumberOfBedroomsClass
      width={80}
      options={Object.values(TYPOLOGY)}
      allowClear={false}
      mode="default"
      onChange={value => {
        const propertyId = record.property_id;
        const weightId = dataIndex;
        return onClear(propertyId, weightId, value);
      }}
      suffixIcon={
        <Icon
          type="close-circle"
          onClick={e => {
            form.setFieldsValue({
              number_of_bedrooms: String(record.originalNumberOfBedrooms || "")
            });
          }}
        />
      }
    />
  );
};
