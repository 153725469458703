// @flow

// type GraphQLError = {
//   extensions?: any,
//   message: string
// };

// type GraphQLResultError = {
//   message: string,
//   graphQLErrors: Array<GraphQLError>
// };

export const betterError = error => {
  const { message, graphQLErrors: errors } = error;
  let result = message;
  if (!errors) {
    console.log("Error: %s", message);
  } else if (errors.length !== 1) {
    console.log("Expecting errors.length === 1, got: %o", errors.length);
  } else {
    const error = errors[0];
    // console.log("betterError[error]", error);
    const { extensions, message } = error;
    result = message;
    if (extensions) {
      const exception = extensions.exception;
      if (exception) {
        // console.log("betterError[exception]", exception);
        let { code, detail } = exception;
        code = code || extensions.code;
        switch (code) {
          case "23505":
            // result = "User already exists.";
            result = detail;
            break;
          case "COEFFICIENTES_API":
            const coefficientsUrl =
              "https://zonamentopf.portaldasfinancas.gov.pt/coeficientes/";
            result = `Error fetching coefficients from:\n ${coefficientsUrl}.\nContact ReasonStack support.`;
            break;

          default:
            result = detail || result;
            break;
        }
      }
    }
  }
  return result;
};
