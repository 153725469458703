import React, { useState } from "react";
import { Card, Col, Row, Upload, Icon, Button, Spin, Radio } from "antd";
import * as R from "ramda";
import JSZip from "jszip";
import { saveAs } from "file-saver";

// TODO: dont accept the same file in target files

const k_expectedName = "RelatorioAvaliacao.json";
const jsonOfRav = async file => {
  console.log("file1", file);
  const zip = await JSZip.loadAsync(file);
  console.log("file2", zip);

  let entries = [];
  zip.forEach(function(_relativePath, zipEntry) {
    if (zipEntry.name !== k_expectedName)
      throw new Error(
        `Expecting json with name ${k_expectedName}, got: ${zipEntry.name}`
      );
    entries = [...entries, zipEntry];
  });
  if (entries.length !== 1) throw new Error("RAV with more than one json file");

  const entry = entries[0];
  console.log("entry", entry);
  const content = await entry.async("string");
  const data = JSON.parse(content);
  return data;
};

const ravOfJson = async jsonContent => {
  const zip = new JSZip();
  const stingContent = JSON.stringify(jsonContent);
  zip.file(k_expectedName, stingContent);
  console.log("zip", zip);
  return zip.generateAsync({ type: "blob" });
};

const k_prospection = "ProspeccaoMercado";
const k_anexo_xi = "AnexoXI";

const replaceProspection = async (source, targetFiles, replace = true) => {
  console.log(`Start ${replace ? "replacing" : "copying"} prosperction`);

  let sourceData = null;
  try {
    sourceData = await jsonOfRav(source);
  } catch {
    return [source, []];
  }

  const prospection = sourceData[k_prospection];
  console.log("prospection", prospection);

  const changedFiles = await Promise.all(
    targetFiles.map(async target => {
      console.log("target file", target);
      const targetName = target["name"];
      try {
        const targetData = await jsonOfRav(target);
        // console.log("target prospection before", targetData[k_prospection]);

        // replace vs copy(merge)
        if (replace) {
          targetData[k_prospection] = prospection;
        } else {
          targetData[k_prospection] = [
            ...targetData[k_prospection],
            ...prospection
          ];
        }
        // activate AnexoXI
        targetData[k_anexo_xi] = true;

        console.log("target prospection after", targetData[k_prospection]);
        const rav = ravOfJson(targetData);
        return {
          name: targetName,
          success: true,
          content: rav
        };
      } catch {
        return {
          name: targetName,
          success: false,
          content: null
        };
      }
    })
  );
  console.log("changedFiles", changedFiles);

  const zip = new JSZip();
  const dirname = "RAVs";

  let error_list = [];
  changedFiles.forEach(f => {
    if (f.success) {
      zip.folder(dirname).file(f.name, f.content);
    } else {
      error_list = [...error_list, f.name];
    }
  });

  if (changedFiles.length > error_list.length) {
    const zipFile = await zip.generateAsync({ type: "blob" });
    saveAs(zipFile, `${dirname}.zip`);
  }
  return [null, error_list];
};

// const _createZip = files => {
//   const zip = new JSZip();

//   // const zip = await JSZip.loadAsync(file);
// }

export const CopyRavProspection = () => {
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [mode, setMode] = useState("replace");
  const [spinning, setSpinning] = useState(false);
  console.log(target);
  return (
    <Spin tip="Loading..." spinning={spinning}>
      <Row>
        <Radio.Group
          onChange={e => {
            console.log("e", e);
            setMode(e.target.value);
          }}
          value={mode}
        >
          <Radio value={"replace"}>Replace</Radio>
          <Radio value={"copy"}>Copy</Radio>
        </Radio.Group>
        <Button
          style={{ display: "inline-block" }}
          type="primary"
          htmlType="submit"
          disabled={R.isEmpty(source) || R.isEmpty(target)}
          onClick={async () => {
            setSpinning(true);
            let sourceFile = source[0];
            const [source_errors, target_errors] = await replaceProspection(
              sourceFile,
              target,
              mode === "replace"
            );
            if (source_errors) {
              sourceFile.status = "error";
            }
            target_errors.forEach(fileName => {
              const file = R.find(f => f.name === fileName, target);
              if (file) {
                file.status = "error";
              }
            });

            setSpinning(false);
          }}
        >
          Submit
        </Button>
      </Row>
      <Row>
        <Col span={12}>
          <Card>
            <Upload.Dragger
              multiple={false}
              beforeUpload={file => {
                setSource([file]);
                return false;
              }}
              onRemove={file => setSource([])}
              fileList={source}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">Click or drag one SOURCE file</p>
            </Upload.Dragger>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Upload.Dragger
              multiple={true}
              beforeUpload={file => {
                setTarget(prevList => [...prevList, file]);
                return false;
              }}
              onRemove={file =>
                setTarget(prevList => {
                  const index = prevList.indexOf(file);
                  const newFileList = prevList.slice();
                  newFileList.splice(index, 1);
                  return newFileList;
                })
              }
              fileList={target}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag one or more TARGET files
              </p>
            </Upload.Dragger>
          </Card>
          {target.length > 0 && (
            <Card>
              <Button icon="delete" onClick={() => setTarget([])}>
                Delete All
              </Button>
            </Card>
          )}
        </Col>
      </Row>
    </Spin>
  );
};
