import React from "react";

import { Form, Button, Input, Modal } from "antd";
import gql from "graphql-tag";
import { DropdownWithQueryClass } from "./DropdownWithQuery";
import { Mutation } from "react-apollo";
import { sha256 } from "./util/security";

const roles_Query = gql`
  query roles {
    roles {
      id
      name
    }
  }
`;

const updateUserMutation = gql`
  mutation(
    $id: ID!
    $username: String
    $email: String
    $password: String
    $name: String
    $role_id: ID
  ) {
    updateUser(
      id: $id
      username: $username
      email: $email
      password: $password
      name: $name
      role_id: $role_id
    ) {
      id
      name
      username
      email
      role {
        id
        name
      }
    }
  }
`;

class UserFields extends React.Component {
  state = {
    confirmDirty: false
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    const form = this.props.form;
    form.validateFields(["confirm"], { force: true });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    const password = form.getFieldValue("password");
    if (!value && !password) {
      callback();
    } else if (value !== password) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  render() {
    const isAdmin = this.props.user.id === "1";
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    return (
      <div>
        <Form.Item {...formItemLayout} label="Username">
          {getFieldDecorator("username", {
            rules: [
              {
                required: true,
                message: "Please input a Username!",
                whitespace: true
              }
            ]
          })(<Input disabled={isAdmin} />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Name">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Please input a Name!",
                whitespace: true
              }
            ]
          })(<Input disabled={isAdmin} />)}
        </Form.Item>

        <Form.Item {...formItemLayout} label="E-mail">
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: "The input is not valid E-mail!"
              },
              {
                required: true,
                message: "Please input your E-mail!"
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item {...formItemLayout} label="Password">
          {getFieldDecorator("password", {
            rules: [
              {
                whitespace: true
              },
              {
                validator: this.validateToNextPassword
              }
            ]
          })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Confirm Password">
          {getFieldDecorator("confirm", {
            rules: [
              {
                validator: this.compareToFirstPassword
              }
            ]
          })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
        </Form.Item>
        <Form.Item label="Role" {...formItemLayout}>
          {getFieldDecorator("role_id", {
            rules: [{ required: true }]
          })(
            <DropdownWithQueryClass
              mode="default"
              queryName="roles"
              query={roles_Query}
              placeholder="Role"
              allowClear={false}
              disabled={isAdmin}
            />
          )}
        </Form.Item>
      </div>
    );
  }
}
class UserView extends React.Component {
  state = {
    confirmDirty: false
  };

  handleSubmit = (mutation, id, e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, data) => {
      if (!err) {
        let { password } = data;
        password = await sha256(password);
        mutation({
          variables: { id, ...data, password }
        });
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  onCompleted = data => {
    this.handleReset();
    this.props.hide();
  };

  onError = error => {
    Modal.error({
      title: "ERROR during user creation:",
      content: error.message
    });
  };

  render() {
    const { visible } = this.props;

    return (
      <Mutation
        mutation={updateUserMutation}
        onCompleted={this.onCompleted}
        onError={this.onError}
      >
        {(mutation, { data, loading, error, called }) => {
          return (
            <Modal
              visible={visible}
              title="Update User"
              onCancel={_ => {
                this.handleReset();
                this.props.hide();
              }}
              footer={[
                <Button
                  key="back"
                  onClick={_ => {
                    this.handleReset();
                    this.props.hide();
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={e =>
                    this.handleSubmit(mutation, this.props.user.id, e)
                  }
                >
                  Update
                </Button>
              ]}
            >
              <Form>
                <UserFields form={this.props.form} user={this.props.user} />
              </Form>
            </Modal>
          );
        }}
      </Mutation>
    );
  }
}

export const UpdateUser = Form.create({
  mapPropsToFields(props) {
    const { user } = props;
    if (user) {
      return {
        username: Form.createFormField({
          name: "username",
          value: user.username
        }),
        name: Form.createFormField({ name: "name", value: user.name }),
        email: Form.createFormField({ name: "email", value: user.email }),
        role_id: Form.createFormField({
          name: "role_id",
          value: user.role.id
        }),
        password: Form.createFormField("__OLD_PASSWORD__")
      };
    } else {
      return;
    }
  }
})(UserView);
