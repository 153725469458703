import gql from "graphql-tag";

export const NULL_MUTATION = gql`
  mutation {
    whatever
  }
`;

export const propertiesQuery = gql`
  query($first: Int!, $where: PropertyWhereInput) {
    properties(first: $first, where: $where) {
      id
      price
      sqm_price
      purpose {
        name
      }
      condition {
        name
      }
      date_of_crawl
      price_history {
        date
        price
      }
      site {
        id
        name
      }
      site_ref
      url
      district {
        id
        name
        centroid
      }
      municipality {
        id
        name
        centroid
      }
      parish {
        id
        name
        centroid
      }
      number_of_bedrooms
      updated_number_of_bedrooms
      crawled_number_of_bedrooms
      area
      updated_area
      crawled_area
      type {
        id
        name
      }
      subtype {
        id
        name
      }
      lat
      lng
      advertiser_name1
      advertiser_name2
      advertiser_location
      advertiser_phones
      distance
      description
      is_finished
      energy_rating
      has_garage
      has_parking
    }
  }
`;

export const mapsQuery = gql`
  query(
    $first: Int!
    $where: PropertyWhereInput
    $withParish: Boolean!
    $withMunicipality: Boolean!
    $withDistrict: Boolean!
    $parishWhere: ParishWhereUniqueInput!
    $municipalityWhere: MunicipalityWhereUniqueInput!
    $districtWhere: DistrictWhereUniqueInput!
  ) {
    properties(first: $first, where: $where) {
      id
      price
      sqm_price
      purpose {
        name
      }
      condition {
        name
      }
      date_of_crawl
      price_history {
        date
        price
      }
      site {
        id
        name
      }
      site_ref
      url
      district {
        id
        name
        centroid
      }
      municipality {
        id
        name
        centroid
      }
      parish {
        id
        name
        centroid
      }
      number_of_bedrooms
      updated_number_of_bedrooms
      crawled_number_of_bedrooms
      area
      updated_area
      crawled_area
      type {
        id
        name
      }
      subtype {
        id
        name
      }
      lat
      lng
      advertiser_name1
      advertiser_name2
      advertiser_location
      advertiser_phones
      distance
      description
      is_finished
      energy_rating
      has_garage
      has_parking
    }

    parish(where: $parishWhere) @include(if: $withParish) {
      id
      geojson
      centroid
    }

    municipality(where: $municipalityWhere) @include(if: $withMunicipality) {
      id
      geojson
      centroid
    }

    district(where: $districtWhere) @include(if: $withDistrict) {
      id
      geojson
      centroid
    }
  }
`;

export const allPropertiesCoefficientsQuery = gql`
  query($where: PropertyWhereInput) {
    properties(where: $where) {
      id
      price
      sqm_price
      purpose {
        name
      }
      condition {
        name
      }
      date_of_crawl
      price_history {
        date
        price
      }
      site {
        id
        name
      }
      site_ref
      url
      district {
        id
        name
        centroid
      }
      municipality {
        id
        name
        centroid
      }
      parish {
        id
        name
        centroid
      }
      number_of_bedrooms
      updated_number_of_bedrooms
      crawled_number_of_bedrooms
      area
      updated_area
      crawled_area
      type {
        id
        name
      }
      subtype {
        id
        name
      }
      lat
      lng
      advertiser_name1
      advertiser_name2
      advertiser_location
      advertiser_phones
      distance
      description
      is_finished
      energy_rating
      has_garage
      has_parking
      coefficients {
        trade {
          value
          descr
        }
        housing {
          value
          descr
        }
        industry {
          value
          descr
        }
        services {
          value
          descr
        }
        land {
          value
          descr
          percent
        }
      }
    }
  }
  # query($where: PropertyWhereInput!) {
  #   properties(where: $where) {
  #     id
  #     coefficients {
  #       trade {
  #         value
  #         descr
  #       }
  #     }
  #   }
  # }
`;

export const singlePropertyCoefficientsQuery = gql`
  query($where: PropertyWhereUniqueInput!) {
    property(where: $where) {
      id
      coefficients {
        trade {
          value
          descr
        }
      }
    }
  }
`;

export const users_Query = gql`
  query users {
    users {
      id
      name
      username
      email
      role {
        id
        name
      }
      has_public_key
    }
  }
`;

export const purposes_Query = gql`
  query {
    purposes {
      id
      name
    }
  }
`;

export const purposeQuery = gql`
  query($where: PurposeWhereUniqueInput!) {
    purpose(where: $where) {
      id
      name
    }
  }
`;

export const sites_Query = gql`
  query sites {
    sites {
      id
      name
    }
  }
`;

export const conditions_Query = gql`
  query conditions {
    conditions {
      id
      name
    }
  }
`;

export const sites_withManualInsert_Query = gql`
  query sites {
    sites(where: { manual_insert: true }) {
      id
      name
    }
  }
`;

export const queryConditions = gql`
  query {
    conditions {
      id
      name
    }
  }
`;

export const propertyTypes_Query = gql`
  query {
    propertyTypes(first: -1) {
      id
      name
    }
  }
`;

export const queryPropertySubtypes = gql`
  query($type_id: ID) {
    propertySubtypes(first: -1, where: { type: { id: $type_id } }) {
      id
      name
    }
  }
`;

export const districts_Query = gql`
  query {
    districts {
      id
      name
    }
  }
`;

export const districtQuery = gql`
  query($where: DistrictWhereUniqueInput!) {
    district(where: $where) {
      id
      name
    }
  }
`;

export const municipalities_Query = gql`
  query($districtId: ID, $hasDistrict: Boolean!) {
    municipalities(where: { district: { id: $districtId } })
      @include(if: $hasDistrict) {
      id
      name
    }
  }
`;

export const municipalityQuery = gql`
  query($where: MunicipalityWhereUniqueInput!) {
    municipality(where: $where) {
      id
      name
    }
  }
`;

export const parishes_Query = gql`
  query($municipalityId: ID, $hasMunicipality: Boolean!) {
    parishes(where: { municipality: { id: $municipalityId } })
      @include(if: $hasMunicipality) {
      id
      name
    }
  }
`;

// export const numberOfProperties_Query = gql`
//   query numberOfProperties(
//     $municipalityId: ID!
//     $startDate: Date!
//     $endDate: Date!
//   ) {
//     numberOfProperties(
//       municipalityId: $municipalityId
//       startDate: $startDate
//       endDate: $endDate
//     ) {
//       count
//       subtype {
//         name
//       }
//       purpose {
//         name
//       }
//       number_of_bedrooms
//       condition
//     }
//   }
// `;
//
export const parishQuery = gql`
  query($parishWhere: ParishWhereUniqueInput!) {
    parish(where: $parishWhere) {
      id
      name
    }
  }
`;

export const queryUserSettings = gql`
  query userSettings {
    fields {
      id
      tag
      name
    }
    userSettings {
      homogenizationParams {
        id
        name
        enabled
        position
      }
      fieldsSettings {
        homogenization {
          fixedIds
          floatingIds
        }
      }
    }
  }
`;

export const queryColumns = gql`
  query userSettings {
    fields {
      id
      tag
      name
    }
    userSettings {
      fieldsSettings {
        properties {
          fixedIds
          floatingIds
        }
      }
    }
  }
`;

export const queryFields = gql`
  query fields {
    fields {
      id
      tag
      name
    }
    userSettings {
      fieldsSettings {
        properties {
          fixedIds
          floatingIds
          notUsedIds
        }
        homogenization {
          fixedIds
          floatingIds
          notUsedIds
        }
      }
    }
  }
`;

export const createPropertyMutation = gql`
  mutation($data: PropertyCreateInput!) {
    createProperty(data: $data) {
      id
      site_ref
    }
  }
`;

export const createPropertiesMutation = gql`
  mutation($data: [PropertyCreateInput!]!) {
    createProperties(data: $data) {
      id
      site_ref
    }
  }
`;

export const updateIndicadoresMacroeconomicos = gql`
  mutation($data: [IndicadorMacroeconomicoInput]!) {
    updateIndicadoresMacroeconomicos(data: $data)
  }
`;

export const updateMacroLicenciamento = gql`
  mutation($data: [MacroLicenciamentoInput]!) {
    updateMacroLicenciamento(data: $data)
  }
`;

export const updateMacroObrasConcluidas = gql`
  mutation($data: [MacroObrasConcluidasInput]!) {
    updateMacroObrasConcluidas(data: $data)
  }
`;

export const updateMacroTransacoes = gql`
  mutation($data: [MacroTransacoesInput]!) {
    updateMacroTransacoes(data: $data)
  }
`;

export const updateMacroPopulation = gql`
  mutation($data: [MacroPopulationInput]!) {
    updateMacroPopulation(data: $data)
  }
`;

export const updateMacroPopulationEstimate = gql`
  mutation($data: [MacroPopulationInput]!) {
    updateMacroPopulationEstimate(data: $data)
  }
`;

export const updateMacroAccomodation = gql`
  mutation($data: [MacroAccomodationInput]!) {
    updateMacroAccomodation(data: $data)
  }
`;

export const updateMacroAccomodationEstimate = gql`
  mutation($data: [MacroAccomodationEstimateInput]!) {
    updateMacroAccomodationEstimate(data: $data)
  }
`;

export const updateMacroFamilies = gql`
  mutation($data: [MacroFamiliesInput]!) {
    updateMacroFamilies(data: $data)
  }
`;

export const updateMacroOwnersVsTenants = gql`
  mutation($data: [MacroOwnersVsTenantsInput]!) {
    updateMacroOwnersVsTenants(data: $data)
  }
`;

export const updateMacroPurchasingPower = gql`
  mutation($data: [MacroPurchasingPowerInput]!) {
    updateMacroPurchasingPower(data: $data)
  }
`;

export const updateMacroRentVsSale = gql`
  mutation($data: [MacroRentVsSaleInput]!) {
    updateMacroRentVsSale(data: $data)
  }
`;

export const updateMacroTotalRents = gql`
  mutation($data: [MacroTotalRentsInput]!) {
    updateMacroTotalRents(data: $data)
  }
`;

export const statisticsMacroPortugalQuery = gql`
  query {
    indicadoresMacroeconomicos {
      year
      pib
      consumo_privado
      ihpc
      taxa_desemprego
    }
  }
`;

export const statisticsMacroQuery = gql`
  query indicadoresMacroeconomicos($municipality_id: ID!) {
    macroLicenciamento(municipality_id: $municipality_id) {
      year
      municipality {
        id
      }
      t0_t1
      t2
      t3
      t4_more
      total
    }

    macroObrasConcluidas(municipality_id: $municipality_id) {
      year
      municipality {
        id
      }
      t0_t1
      t2
      t3
      t4_more
      total
    }

    macroLicenciamentoVsObrasConcluidas(municipality_id: $municipality_id) {
      year
      municipality {
        id
      }
      total_licenciamento
      total_obras_concluidas
    }

    macroTransacoes(municipality_id: $municipality_id) {
      year
      municipality {
        id
      }
      urban
      horizontal_property
      other
    }

    macroPopulation(municipality_id: $municipality_id, limit: 3) {
      year
      municipality {
        id
      }
      population
    }

    macroPopulationEstimate(municipality_id: $municipality_id, limit: 3) {
      year
      municipality {
        id
      }
      population
    }

    macroFamilies(municipality_id: $municipality_id, limit: 3) {
      year
      municipality {
        id
      }
      total
      unipessoal
    }

    macroAccomodation(municipality_id: $municipality_id, limit: 3) {
      year
      municipality {
        id
      }
      main
      secondary
      empty
      total
    }

    macroAccomodationEstimate(municipality_id: $municipality_id, limit: 3) {
      year
      municipality {
        id
      }
      total
    }

    macroOwnersVsTenants(municipality_id: $municipality_id, limit: 1) {
      year
      municipality {
        id
      }
      owners
      tenants
      other
      total
    }

    macroPurchasingPower(municipality_id: $municipality_id) {
      year
      municipality {
        id
      }
      purchasing_power
    }

    macroRentVsSale(municipality_id: $municipality_id, limit: 1) {
      year
      municipality {
        id
      }
      median_rent
      median_sale
      code
      parent {
        id
        median_rent
        median_sale
        name
      }
      pt {
        id
        median_rent
        median_sale
        name
      }
    }

    macroTotalRents(municipality_id: $municipality_id, limit: 1) {
      year
      municipality {
        id
      }
      total_rents
      code
      parent {
        id
        total_rents
        name
      }
      pt {
        id
        total_rents
        name
      }
    }
  }
`;

export const topRentAndSalesQuery = gql`
  query topRentAndSalesValue($year: String!, $limit: Int) {
    topRentValue(year: $year, limit: $limit) {
      year
      municipality {
        id
        name
      }
      median_rent
      pt {
        id
        median_rent
        median_sale
        name
      }
    }

    topSalesValue(year: $year, limit: $limit) {
      year
      municipality {
        id
        name
      }
      median_sale
      pt {
        id
        median_rent
        median_sale
        name
      }
    }
  }
`;

// export const statisticsOffertaQuery = gql`
//   query statisticsOffertaQuery(
//     $startDate: Date!
//     $endDate: Date!
//     $municipalityId: ID!
//   ) {
//     municipality(where: { id: $municipalityId }) {
//       id
//       name
//     }
//
//     numberOfPropertiesBySubtypeForMunicipality: numberOfPropertiesBySubtype(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//     ) {
//       subtype {
//         id
//         name
//       }
//       count
//     }
//
//     numberOfPropertiesBySubtypeForCountry: numberOfPropertiesBySubtype(
//       startDate: $startDate
//       endDate: $endDate
//     ) {
//       subtype {
//         id
//         name
//       }
//       count
//     }
//
//     numberOfMoradiasVenda: numberOfPropertiesByPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 1
//       purposeId: 1
//     ) {
//       count
//     }
//     numberOfMoradiasRenda: numberOfPropertiesByPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 1
//       purposeId: 2
//     ) {
//       count
//     }
//     numberOfMoradiasOutro: numberOfPropertiesByPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 1
//       purposeId: 3
//     ) {
//       count
//     }
//
//     numberOfApartamentosVenda: numberOfPropertiesByPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 2
//       purposeId: 1
//     ) {
//       count
//     }
//     numberOfApartamentosRenda: numberOfPropertiesByPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 2
//       purposeId: 2
//     ) {
//       count
//     }
//     numberOfApartamentosOutro: numberOfPropertiesByPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 2
//       purposeId: 3
//     ) {
//       count
//     }
//
//     numberOfApartamentosNovosParaVendaPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 2 # Apartamentos
//       purposeId: 1 # Venda
//       conditionId: 1 # Novos
//     ) {
//       number_of_bedrooms
//       count
//     }
//     numberOfApartamentosNovosParaArrendarPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 2 # Apartamentos
//       purposeId: 2 # Arrendar
//       conditionId: 1 # Novos
//     ) {
//       number_of_bedrooms
//       count
//     }
//     numberOfApartamentosUsadosParaVendaPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 2 # Apartamentos
//       purposeId: 1 # Venda
//       conditionId: 2 # Usados
//     ) {
//       number_of_bedrooms
//       count
//     }
//     numberOfApartamentosUsadosParaArrendarPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 2 # Apartamentos
//       purposeId: 2 # Arrendar
//       conditionId: 2 # Usados
//     ) {
//       number_of_bedrooms
//       count
//     }
//     numberOfMoradiasParaVendaPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 1 # Moradias
//       purposeId: 1 # Venda
//     ) {
//       number_of_bedrooms
//       count
//     }
//     numberOfMoradiasParaArrendarPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
//       startDate: $startDate
//       endDate: $endDate
//       municipalityId: $municipalityId
//       subtypeId: 1 # Moradias
//       purposeId: 2 # Arrendar
//     ) {
//       number_of_bedrooms
//       count
//     }
//   }
// `;
//
export const statisticsOffertaQuery1 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    municipality(where: { id: $municipalityId }) {
      id
      name
    }
    numberOfPropertiesBySubtypeForMunicipality: numberOfPropertiesBySubtype(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
    ) {
      subtype {
        id
        name
      }
      count
    }
    numberOfPropertiesBySubtypeForCountry: numberOfPropertiesBySubtype(
      startDate: $startDate
      endDate: $endDate
    ) {
      subtype {
        id
        name
      }
      count
    }
  }
`;

export const statisticsOffertaQuery2 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    numberOfApartamentosVenda: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: [2]
      purposeId: 1
    ) {
      count
    }
    numberOfApartamentosRenda: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: [2]
      purposeId: 2
    ) {
      count
    }
    numberOfApartamentosOutro: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: [2]
      purposeId: 3
    ) {
      count
    }
  }
`;

export const statisticsOffertaCommercialQuery2 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
    $subtypeIds: [ID!]!
  ) {
    numberOfLojasVenda: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 1
    ) {
      count
    }
    numberOfLojasRenda: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 2
    ) {
      count
    }
    numberOfLojasOutro: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 3
    ) {
      count
    }
    avgAreaLojasVenda: averageAreaOfPropertiesGivenSubtypePurpose(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 1
    ) {
      average
      count
    }
    avgPriceLojasVenda: averagePriceOfPropertiesGivenSubtypePurpose(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 1
    ) {
      average
      count
    }
    avgSQMPriceLojasVenda: averageSQMPriceOfPropertiesGivenSubtypePurpose(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 1
    ) {
      average
      count
    }
    avgAreaLojasArrendamento: averageAreaOfPropertiesGivenSubtypePurpose(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 2
    ) {
      average
      count
    }
    avgPriceLojasArrendamento: averagePriceOfPropertiesGivenSubtypePurpose(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 2
    ) {
      average
      count
    }
    avgSQMPriceLojasArrendamento: averageSQMPriceOfPropertiesGivenSubtypePurpose(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: $subtypeIds
      purposeId: 2
    ) {
      average
      count
    }
  }
`;

export const statisticsOffertaQuery3 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    numberOfMoradiasVenda: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: [1]
      purposeId: 1
    ) {
      count
    }
    numberOfMoradiasRenda: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: [1]
      purposeId: 2
    ) {
      count
    }
    numberOfMoradiasOutro: numberOfPropertiesByPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeIds: [1]
      purposeId: 3
    ) {
      count
    }
  }
`;

export const statisticsOffertaQuery4 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    numberOfApartamentosNovosParaVendaPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: 2 # Apartamentos
      purposeId: 1 # Venda
      conditionId: 1 # Novos
    ) {
      number_of_bedrooms
      count
    }
  }
`;

export const statisticsOffertaQuery5 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    numberOfApartamentosNovosParaArrendarPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: 2 # Apartamentos
      purposeId: 2 # Arrendar
      conditionId: 1 # Novos
    ) {
      number_of_bedrooms
      count
    }
  }
`;

export const statisticsOffertaQuery6 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    numberOfApartamentosUsadosParaVendaPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: 2 # Apartamentos
      purposeId: 1 # Venda
      conditionId: 2 # Usados
    ) {
      number_of_bedrooms
      count
    }
  }
`;

export const statisticsOffertaQuery7 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    numberOfApartamentosUsadosParaArrendarPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: 2 # Apartamentos
      purposeId: 2 # Arrendar
      conditionId: 2 # Usados
    ) {
      number_of_bedrooms
      count
    }
  }
`;

export const statisticsOffertaQuery8 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    numberOfMoradiasParaVendaPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: 1 # Moradias
      purposeId: 1 # Venda
    ) {
      number_of_bedrooms
      count
    }
  }
`;

export const statisticsOffertaQuery9 = gql`
  query statisticsOffertaQuery(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
  ) {
    numberOfMoradiasParaArrendarPorTipologia: numberOfPropertiesByTypologyGivenPurposeAndCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: 1 # Moradias
      purposeId: 2 # Arrendar
    ) {
      number_of_bedrooms
      count
    }
  }
`;

export const statisticsOffertaQuery10 = gql`
  query(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
    $subtypeId: ID!
    $purposeId: ID!
    $conditionId: ID
  ) {
    numberOfPropertiesSegmentedByFixedPriceRangesGivenSubtypePurposeCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: $subtypeId
      purposeId: $purposeId
      conditionId: $conditionId
    ) {
      count
      segmento
    }
  }
`;

export const statisticsOffertaQuery11 = gql`
  query(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
    $subtypeId: ID!
    $purposeId: ID!
    $conditionId: ID
  ) {
    averageAreaOfPropertiesByFixedTypologyGivenSubtypePurposeCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: $subtypeId
      purposeId: $purposeId
      conditionId: $conditionId
    ) {
      typology
      average
    }
  }
`;

export const statisticsOffertaQuery12 = gql`
  query(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
    $subtypeId: ID!
    $purposeId: ID!
    $conditionId: ID
  ) {
    averagePriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: $subtypeId
      purposeId: $purposeId
      conditionId: $conditionId
    ) {
      typology
      average
      count
    }
  }
`;
export const statisticsOffertaQuery13 = gql`
  query(
    $startDate: Date!
    $endDate: Date!
    $municipalityId: ID!
    $subtypeId: ID!
    $purposeId: ID!
    $conditionId: ID
  ) {
    averageSQMPriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition(
      startDate: $startDate
      endDate: $endDate
      municipalityId: $municipalityId
      subtypeId: $subtypeId
      purposeId: $purposeId
      conditionId: $conditionId
    ) {
      typology
      average
      count
    }
  }
`;
