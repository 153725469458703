import React from "react";
import { OfertaByType, CommercialOfferBySubtype } from "./OfertaByType";
import { useQuery } from "@apollo/react-hooks";
// import { useSelector } from "react-redux";
import { SelectMunicipality } from "./SelectMunicipality";
import moment from "moment";

import {
  statisticsOffertaQuery1,
  statisticsOffertaCommercialQuery2,
} from "./Queries";

import { Spin, DatePicker, Card } from "antd";

import { ESCRITORIOS, ARMAZENS, LOJAS } from "./constants";

export const StatisticsOfferComercial = () => {
  const [municipality, setMunicipality] = React.useState(null);
  const [month, setMonth] = React.useState(null);

  // const [municipality, setMunicipality] = React.useState({
  //   key: "1111",
  //   label: "Sintra",
  //   district: {
  //     key: "11",
  //     label: "Lisboa",
  //   },
  // });
  // const [month, setMonth] = React.useState("2019-03");

  const startDate =
    month &&
    moment(month)
      .startOf("month")
      .format("YYYY-MM-DD");
  const endDate =
    month &&
    moment(month)
      .endOf("month")
      .format("YYYY-MM-DD");

  const valid =
    municipality && municipality.key && startDate && endDate && true;

  const variables = {
    municipalityId: municipality && municipality.key,
    startDate,
    endDate,
  };

  // const [loading1, data1] = [false, null];

  const { loading: loading1, data: data1 } = useQuery(statisticsOffertaQuery1, {
    variables,
    skip: !valid,
  });

  const { loading: loading2, data: data2 } = useQuery(
    statisticsOffertaCommercialQuery2,
    {
      variables: { ...variables, subtypeIds: [40, 41, 45] },
      skip: !valid,
    }
  );

  const { loading: loading3, data: data3 } = useQuery(
    statisticsOffertaCommercialQuery2,
    {
      variables: { ...variables, subtypeIds: [16, 76, 114] },
      skip: !valid,
    }
  );

  const { loading: loading4, data: data4 } = useQuery(
    statisticsOffertaCommercialQuery2,
    {
      variables: { ...variables, subtypeIds: [42] },
      skip: !valid,
    }
  );

  const { loading: loading5, data: data5 } = useQuery(
    statisticsOffertaCommercialQuery2,
    {
      variables: { ...variables, subtypeIds: [44, 58, 63] },
      skip: !valid,
    }
  );

  const loading = loading1 && loading2 && loading3 && loading4 && loading5;

  // console.log("data", data10);
  return (
    <div style={{ minWidth: "1000px" }}>
      <div style={{ display: "inline-block", padding: "20px" }}>
        <label>Month: </label>
        <DatePicker.MonthPicker
          size="small"
          placeholder="Select month"
          onChange={(month) => setMonth(month)}
        />
      </div>
      <SelectMunicipality
        onChange={(municipality) => setMunicipality(municipality)}
      />
      {valid && (
        <Card style={{ padding: "20px" }}>
          {/* <Button size="small" onClick={() => getStatistics({ variables })}> */}
          {/*   Submit */}
          {/* </Button> */}
          <Spin spinning={loading} tip="Loading...">
            {!loading ? (
              <>
                <Spin spinning={loading1} tip="Loading...">
                  <OfertaByType
                    name={data1?.municipality.name}
                    numberOfPropertiesBySubtypeForCountry={
                      data1?.numberOfPropertiesBySubtypeForCountry
                    }
                    numberOfPropertiesBySubtypeForMunicipality={
                      data1?.numberOfPropertiesBySubtypeForMunicipality
                    }
                    show={[ESCRITORIOS, ARMAZENS, LOJAS]}
                  />
                </Spin>
                <Spin spinning={loading2} tip="Loading...">
                  <CommercialOfferBySubtype subtypeName="Lojas" data={data2} />
                </Spin>
                <Spin spinning={loading3} tip="Loading...">
                  <CommercialOfferBySubtype
                    subtypeName="Escritórios"
                    data={data3}
                  />
                </Spin>
                <Spin spinning={loading4} tip="Loading...">
                  <CommercialOfferBySubtype
                    subtypeName="Armazéns"
                    data={data4}
                  />
                </Spin>
                <Spin spinning={loading5} tip="Loading...">
                  <CommercialOfferBySubtype
                    subtypeName="Garagens e Estacionamentos"
                    data={data5}
                  />
                </Spin>
              </>
            ) : null}
          </Spin>
        </Card>
      )}
    </div>
  );
};
