import React from "react";
import DropdownWithQuery from "./DropdownWithQuery";
import { propertyTypes_Query as query } from "./Queries";

const FilterByType = ({ value, onChange }) => (
  <DropdownWithQuery
    mode="default"
    queryName="propertyTypes"
    query={query}
    placeholder="Type"
    // allowClear={false}
    value={value}
    onChange={value => onChange(value)}
  />
);

export default FilterByType;
