import { connect } from "react-redux";
import { setMinPrice, setMaxPrice } from "../actions";
import FilterByNumberBetween from "../FilterByNumberBetween";

const mapStateToProps = state => ({
  step: 1000,
  formatter: value => (value ? `${value} €` : ""),
  parser: value => value.replace(/[ €]/g, ""),
  minPlaceholder: "Min Price",
  maxPlaceholder: "Max Price",
  initialMinValue: state.filters.price.min,
  initialMaxValue: state.filters.price.max,
  minWidth: 150
});

const mapDispatchToProps = dispatch => ({
  onMinChange: price => dispatch(setMinPrice(price)),
  onMaxChange: price => dispatch(setMaxPrice(price))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByNumberBetween);
