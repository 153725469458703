import React from "react";

import { Form, Input, Modal, InputNumber } from "antd";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { FilterByNumberOfBedroomsClass } from "./FilterByNumberOfBedrooms";
import { TYPOLOGY } from "./containers/typologies";

const mutation = gql`
  mutation($id: ID!, $area: Int, $number_of_bedrooms: Int) {
    updateProperty(
      id: $id
      area: $area
      number_of_bedrooms: $number_of_bedrooms
    ) {
      id
    }
  }
`;

class Fields extends React.Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    return (
      <div>
        <Form.Item {...formItemLayout} label="Ref">
          {getFieldDecorator("ref")(<Input disabled />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Area">
          {getFieldDecorator("area", {})(<InputNumber />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label="Typology">
          {getFieldDecorator("number_of_bedrooms")(
            <FilterByNumberOfBedroomsClass
              mode="default"
              options={Object.values(TYPOLOGY)}
            />
          )}
        </Form.Item>
      </div>
    );
  }
}

class View extends React.Component {
  handleSubmit = (mutation, id, e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, data) => {
      if (!err) {
        let fieldsToUpdate = data;
        if (fieldsToUpdate.number_of_bedrooms) {
          switch (data.number_of_bedrooms) {
            case "6":
              fieldsToUpdate.number_of_bedrooms = null;
              break;
            default:
              fieldsToUpdate.number_of_bedrooms = Number(
                data.number_of_bedrooms
              );
              break;
          }
        }
        console.log("fields to update", fieldsToUpdate);
        mutation({
          variables: { id, ...fieldsToUpdate },
        });
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  onCompleted = (data) => {
    this.handleReset();
    this.props.hide();
  };

  onError = (error) => {
    Modal.error({
      title: "ERROR during user creation:",
      content: error.message,
    });
  };

  render() {
    const { visible, property } = this.props;
    return (
      <Mutation
        mutation={mutation}
        onCompleted={this.onCompleted}
        onError={this.onError}
      >
        {(mutation, { data, loading, error, called }) => {
          return property ? (
            <Modal
              visible={visible}
              title={`Property update: ${property.property_id}`}
              confirmLoading={loading}
              onOk={(e) => this.handleSubmit(mutation, property.property_id, e)}
              onCancel={(_) => {
                this.handleReset();
                this.props.hide();
              }}
            >
              <Form>
                <Fields form={this.props.form} property={this.props.property} />
              </Form>
            </Modal>
          ) : null;
        }}
      </Mutation>
    );
  }
}

export const UpdateProperty = Form.create({
  mapPropsToFields(props) {
    const { property } = props;
    if (property) {
      return {
        ref: Form.createFormField({
          name: "ref",
          value: property.site_ref,
        }),
        area: Form.createFormField({ name: "area", value: property.area }),
        number_of_bedrooms: Form.createFormField({
          name: "number_of_bedrooms",
          value:
            property.number_of_bedrooms !== null
              ? String(property.number_of_bedrooms)
              : "",
        }),
      };
    } else {
      return;
    }
  },
})(View);
