import { connect } from "react-redux";
import { reset } from "../actions";

import { Reset } from "../Reset";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(reset())
});

export const ResetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Reset);
