import { connect } from "react-redux";
import { setMaxProperties } from "../actions";

import { SetNumber } from "../SetNumber";
import { MAX_PROPERTIES } from "../constants";

const mapStateToProps = state => ({
  value: state.preferences.maxProperties,
  "data-testid": "max-properties",
  label: "Max Properties",
  min: 1,
  max: MAX_PROPERTIES,
  step: 10
});

const mapDispatchToProps = dispatch => ({
  onChange: max => dispatch(setMaxProperties(max))
});

export const InputMaxPropertiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetNumber);
