import React from "react";
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from "recharts";
import { Table, Card, Row, Col } from "antd";
import { COLORS } from "./constants";

export const MacroPurchasingPower = ({ data }) => {
  const columns = [
    { dataIndex: "year" },
    { title: "IpC", dataIndex: "purchasing_power" },
  ];
  return (
    <Card style={{ width: "100%", padding: "auto" }}>
      <h2 style={{ textAlign: "center" }}>
        Poder de Compra Concelhio (Índice)
      </h2>

      <Row type="flex" justify="center" align="middle">
        <Col span={8} style={{ width: 800 }}>
          <AreaChart width={700} height={400} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Area
              // type="monotone"
              dataKey="purchasing_power"
              stroke={COLORS[0]}
              fill={COLORS[0]}
            />
          </AreaChart>
        </Col>
        <Col span={2} style={{ width: 200 }}>
          <Table
            size="small"
            rowKey={"year"}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </Col>
      </Row>
    </Card>
  );
};
