import { connect } from "react-redux";
import { setType } from "../actions";

import FilterByPropertyType from "../FilterByPropertyType";

const mapStateToProps = state => ({
  value: state.filters.property.type || undefined
});

const mapDispatchToProps = dispatch => ({
  onChange: id => dispatch(setType(id || null))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByPropertyType);
