import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  propertyTypes_Query,
  queryPropertySubtypes,
  queryConditions
} from "./Queries";
import { DB } from "./constants";
import { Table, notification, Select } from "antd";
import { District, Municipality, Parish, Purpose } from "./DBComponents";
import { formatPrice } from "./util/Formatter";
import { setupBaseColumns } from "./propertyColumns";
import { createPropertiesMutation } from "./Queries";
import { Mutation } from "react-apollo";

const topHeight = 440;

const useTypes = () => {
  const { data } = useQuery(propertyTypes_Query);
  return data && data.propertyTypes;
};

const useConditions = () => {
  const { data } = useQuery(queryConditions);
  return data && data.conditions;
};

const SelectSubtype = ({ typeId, subtypeId, onChange, ...other }) => {
  const { data } = useQuery(queryPropertySubtypes, {
    variables: { type_id: typeId }
  });

  const subtypes = data && data.propertySubtypes;
  if (!subtypes) {
    return null;
  }

  const options = subtypes.map(v => (
    <Select.Option key={v.id} value={v.id}>
      {v.name}
    </Select.Option>
  ));

  return (
    <Select
      value={subtypeId && String(subtypeId)}
      style={{ width: 200 }}
      {...other}
      onChange={onChange}
    >
      {options}
    </Select>
  );
};

const onCompleted = data => {};

const onError = error => {
  notification.error({
    message: "ERROR during property creation:",
    description: error.message
  });
};

export const usePropertiesToUpload = ({ properties, source }) => {
  const [propertySubtypes, setPropertySubtypes] = useState({});
  const [propertyTypes, setPropertyTypes] = useState({});
  const [propertyConditions, setPropertyConditions] = useState({});
  const [propertyIsFinished, setPropertyIsFinished] = useState({});

  if (properties === null) return null;

  const propertiesWithSource = properties.map((p, index) => {
    const subtypeId = propertySubtypes.hasOwnProperty(index)
      ? propertySubtypes[index]
      : p.subtype.id;
    const typeId = propertyTypes.hasOwnProperty(index)
      ? propertyTypes[index]
      : p.type.id;
    const conditionId = propertyConditions.hasOwnProperty(index)
      ? propertyConditions[index]
      : p.condition && p.condition.id;
    const is_finished = propertyIsFinished.hasOwnProperty(index)
      ? propertyIsFinished[index] === "true"
      : p.is_finished;
    return {
      ...p,
      site: { id: source },
      type: { id: typeId },
      subtype: { id: subtypeId },
      condition: conditionId ? { id: conditionId } : null,
      is_finished
    };
  });

  return {
    propertySubtypes,
    setPropertySubtypes,
    propertyTypes,
    setPropertyTypes,
    propertyConditions,
    setPropertyConditions,
    propertyIsFinished,
    setPropertyIsFinished,
    propertiesWithSource
  };
};

export const PropertiesToUpload = ({ propertiesWithState }) => {
  const {
    setPropertySubtypes,
    setPropertyTypes,
    setPropertyConditions,
    setPropertyIsFinished,
    propertiesWithSource
  } = propertiesWithState;

  const [tableHeight, setTableHeight] = useState(0);
  const allTypes = useTypes();
  const allConditions = useConditions();

  const setType = (index, value) => {
    setPropertyTypes(propertyTypes => ({ ...propertyTypes, [index]: value }));
    setPropertySubtypes(propertySubtypes => ({
      ...propertySubtypes,
      [index]: null
    }));
  };

  const setSubtype = (index, value) => {
    setPropertySubtypes(propertySubtypes => ({
      ...propertySubtypes,
      [index]: value
    }));
  };

  const setCondition = (index, value) => {
    setPropertyConditions(propertyConditions => ({
      ...propertyConditions,
      [index]: value
    }));
  };

  const setIsFinished = (index, value) => {
    setPropertyIsFinished(propertyIsFinished => ({
      ...propertyIsFinished,
      [index]: value
    }));
  };

  const updateWindowDimensions = () => {
    const height = window.innerHeight - topHeight;
    setTableHeight(height);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    // setPropertiesToUpload(propertiesWithSource);
  });

  if (!allTypes) return null;

  const allTypesOptions = allTypes.map(type => (
    <Select.Option key={type.id} value={type.id}>
      {type.name}
    </Select.Option>
  ));

  const allConditionsOptions = allConditions
    ? allConditions.map(value => (
        <Select.Option key={value.id} value={value.id}>
          {value.name}
        </Select.Option>
      ))
    : null;

  const allIsFinishedOptions = [
    <Select.Option key="true" value="true">
      Yes
    </Select.Option>,
    <Select.Option key="false" value="false">
      No
    </Select.Option>
  ];

  const baseSetup = setupBaseColumns();
  const columnsSetup = {
    ...baseSetup,
    source: {
      ...baseSetup.source,
      render: site => DB.sites.find(site.id).name
    },
    type: {
      ...baseSetup.type,
      dataIndex: "subtype.id",
      width: 160,
      key: "type",
      render: (subtypeId, property, index) => {
        const typeId = property.type.id;
        return (
          <Select
            defaultValue={String(typeId)}
            style={{ width: 120 }}
            onChange={newValue => {
              setType(index, newValue);
            }}
          >
            {allTypesOptions}
          </Select>
        );
      }
    },
    subtype: {
      ...baseSetup.subtype,
      dataIndex: "subtype.id",
      key: "subtype",
      render: (subtypeId, property, index) => {
        return (
          <SelectSubtype
            typeId={property.type.id}
            subtypeId={subtypeId}
            onChange={newValue => setSubtype(index, newValue)}
            // style={{ width: 220 }}
          />
        );
      }
    },
    district: {
      ...baseSetup.district,
      render: district => <District id={district.id} />
    },
    municipality: {
      ...baseSetup.municipality,
      render: municipality => <Municipality id={municipality.id} />
    },
    parish: {
      ...baseSetup.parish,
      render: parish => <Parish id={parish.id} name={parish.name} />
    },
    gps: {
      ...baseSetup.gps,
      title: "GPS",
      width: 180,
      render: v => `${v.lat},${v.lng}`
    },
    purpose: {
      ...baseSetup.purpose,
      dataIndex: "crawls[0].purpose.id",
      render: v => <Purpose id={v} />
    },
    price: {
      ...baseSetup.price,
      dataIndex: "crawls[0].price",
      render: v => formatPrice(v)
    },
    date: {
      ...baseSetup.date,
      dataIndex: "crawls[0].date_of_crawl"
    },
    file: { title: "File", dataIndex: "file", width: 200 },
    area_gross: {
      ...baseSetup.area,
      title: "A.Gross",
      dataIndex: "area_gross"
    },
    area_net: {
      ...baseSetup.area,
      title: "A.Net",
      dataIndex: "area_net"
    },
    area_ground: {
      ...baseSetup.area,
      title: "A.Ground",
      width: 110,
      dataIndex: "area_ground"
    },
    condition: {
      ...baseSetup.condition,
      width: baseSetup.condition.width + 60,
      // render: condition => condition && DB.conditions.find(condition.id).name
      render: (condition, property, index) => {
        const conditionId = condition && condition.id;
        return (
          <Select
            defaultValue={conditionId && String(conditionId)}
            style={{ width: 120 }}
            allowClear={true}
            onChange={newValue => {
              setCondition(index, newValue);
            }}
          >
            {allConditionsOptions}
          </Select>
        );
      }
    },
    is_finished: {
      ...baseSetup.is_finished,
      width: baseSetup.is_finished.width + 60,
      render: (isFinished, property, index) => {
        return (
          <Select
            defaultValue={isFinished ? "true" : "false"}
            style={{ width: 120 }}
            onChange={newValue => {
              setIsFinished(index, newValue);
            }}
          >
            {allIsFinishedOptions}
          </Select>
        );
      }
    }
  };
  const columnsNames = [
    "ref",
    "source",
    "type",
    "subtype",
    "condition",
    "is_finished",
    "district",
    "municipality",
    "parish",
    "gps",
    "purpose",
    "price",
    "date",
    "energy_rating",
    "typology",
    "area_gross",
    "area_net",
    "area_ground",
    "description",
    "file",
    "empty"
  ];

  const columns = columnsNames.map(name => columnsSetup[name]);

  return (
    <Mutation
      mutation={createPropertiesMutation}
      onCompleted={onCompleted}
      onError={onError}
    >
      {(mutation, { loading, ...rest }) => {
        return (
          <div style={{ padding: "20px" }}>
            <Table
              dataSource={propertiesWithSource}
              columns={columns}
              rowKey="site_ref"
              pagination={false}
              scroll={{ y: tableHeight, x: 3600 }}
              bordered
            />
          </div>
        );
      }}
    </Mutation>
  );
};
