export function parseGPS(text) {
  var LatLng = text
    .replace(/[^\d.\-,]/g, "")
    .replace(/([^\d])\.([^\d])/g, "$1$2")
    .split(",");
  var lat = parseFloat(Number(LatLng[0]));
  var lng = parseFloat(Number(LatLng[1]));

  if (lat > 0 && lng < 0) {
    return { lat, lng };
  } else if (lat < 0 && lng > 0) {
    return { lat: lng, lng: lat };
  } else {
    return null;
  }
}
