import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card, Row, Col } from "antd";
import { CustomizedAxisTick } from "./CustomBarChart";
import { COLORS } from "./constants";

const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

export const IndicadoresMacroeconomicosChart = ({ data }) => {
  const barSize = 15;
  return (
    <Card style={{ width: "100%" }}>
      <h2 style={{ textAlign: "center" }}>
        Indicadores Macroeconómicos: Portugal
      </h2>

      <Row type="flex" justify="space-around" align="middle">
        <Col span={24} style={{ width: 1000 }}>
          <ComposedChart
            width={1000}
            height={400}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
            barGap={-5}
            barCategoryGap={2}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              interval={0}
              dataKey="year"
              height={60}
              tick={<CustomizedAxisTick />}
            />
            <YAxis tickFormatter={toPercent} />
            <Tooltip />
            <Legend />
            <Bar name="PIB" dataKey="pib" barSize={barSize} fill={COLORS[0]} />
            <Bar
              name="Consumo Privado"
              dataKey="consumo_privado"
              barSize={barSize}
              fill={COLORS[1]}
            />
            <Line
              name="IHPC"
              type="monotone"
              dataKey="ihpc"
              stroke={COLORS[2]}
            />
            <Line
              name="Taxa de Desemprego"
              type="monotone"
              dataKey="taxa_desemprego"
              stroke={COLORS[3]}
            />
          </ComposedChart>
        </Col>
      </Row>
    </Card>
  );
};
