import { connect } from "react-redux";
import { setLocation, setPropertyId, setNearGPS } from "../actions";

import PropertyDivisionSearch from "../PropertyDivisionSearch";

const mapStateToProps = undefined;

const mapDispatchToProps = dispatch => ({
  onChange: item => {
    if (!item || !item.__typename) return null;
    const type = item.__typename.toLowerCase();
    switch (type) {
      case "gps":
        const { parish, gps } = item;
        dispatch(setNearGPS(gps));
        dispatch(
          setLocation({ ...parish, type: parish.__typename.toLowerCase() })
        );
        break;
      case "siteref":
        dispatch(setLocation(null));
        return dispatch(setPropertyId(item.property_id, item.site_ref));
      case "property":
        const location = item.parish || item.municipality || item.district;
        if (location) {
          dispatch(
            setLocation({
              type: location.__typename.toLowerCase(),
              id: location.id,
              name: location.name
            })
          );
        }
        return dispatch(setPropertyId(item.id, item.site_ref));
      case "cancel":
        return null;
      default:
        dispatch(setNearGPS(null));
        return dispatch(
          setLocation({
            type: item.__typename.toLowerCase(),
            id: item.id,
            name: item.name
          })
        );
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyDivisionSearch);
