import React from "react";
import { Radio } from "antd";

export const FilterYesNoAll = ({ label, value, onChange }) => {
  return (
    <div>
      <label>{label} </label>
      <Radio.Group
        size="small"
        onChange={e => onChange(e.target.value)}
        value={value}
      >
        <Radio.Button value="yes">Yes</Radio.Button>
        <Radio.Button value="no">No</Radio.Button>
        <Radio.Button value="all">ALL</Radio.Button>
      </Radio.Group>
    </div>
  );
};
