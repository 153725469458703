import React from "react";
import { Subscription } from "react-apollo";
import { notification } from "antd";
import gql from "graphql-tag";
import { Card } from "antd";
import { loadState } from "./util/localStorage";

const propertiesSubscription = gql`
  subscription {
    properties {
      mutation
      userId
      node {
        id
        site_ref
        site {
          id
          name
        }
      }
    }
  }
`;

const propertySubscription = gql`
  subscription {
    property {
      mutation
      userId
      node {
        id
        site_ref
        site {
          id
          name
        }
      }
    }
  }
`;

const userSubscription = gql`
  subscription {
    user {
      mutation
      userId
      node {
        id
        name
        username
        email
        role {
          id
          name
        }
      }
    }
  }
`;

const statisticsSubscription = gql`
  subscription {
    statistics
  }
`;

class Notification extends React.Component {
  notify = () => {
    const { message, description } = this.props;
    notification.open({
      message,
      // duration: 0,
      description
    });
  };
  componentDidMount() {
    this.notify();
  }
  componentDidUpdate() {
    this.notify();
  }

  render() {
    return null;
  }
}

export const Notifications = ({ client, user }) => {
  return (
    <div>
      <Subscription subscription={propertiesSubscription}>
        {({ loading, data }) => {
          if (loading) {
            return null;
          }
          if (!data) {
            console.log("Warning: data should not be empty");
            return null;
          }
          const state = loadState();
          const currentUserId = state && state.user && state.user.id;
          const {
            properties: { mutation, userId, node }
          } = data;
          const message = <h5>Properties {mutation.toLowerCase()}:</h5>;
          const description = node.map(p => (
            <Card key={p.id}>
              {`ID: ${p.id}`}
              <br />
              {`Ref: ${p.site_ref}`}
              <br />
              {`Source: ${p.site.name}`}
            </Card>
          ));
          client.resetStore();
          // client.clearStore();
          if (userId === currentUserId) return null;
          return <Notification message={message} description={description} />;
        }}
      </Subscription>
      <Subscription subscription={propertySubscription}>
        {({ loading, data }) => {
          if (loading) {
            return null;
          }
          if (!data) {
            console.log("Warning: data should not be empty");
            return null;
          }
          const state = loadState();
          const currentUserId = state && state.user && state.user.id;
          const {
            property: { mutation, userId, node }
          } = data;
          const message = <h5>Property {mutation.toLowerCase()}:</h5>;
          const description = (
            <div>
              <p>{`ID: ${node.id}`}</p>
              <p>{`Ref: ${node.site_ref}`}</p>
              <p>{`Source: ${node.site.name}`}</p>
            </div>
          );
          client.resetStore();
          // client.clearStore();
          if (userId === currentUserId) return null;
          return <Notification message={message} description={description} />;
        }}
      </Subscription>
      <Subscription subscription={userSubscription}>
        {({ loading, data }) => {
          if (loading) {
            return null;
          }
          const {
            user: { mutation, userId, node }
          } = data;
          const state = loadState();
          const currentUserId = state && state.user && state.user.id;
          const message = <h5>User {mutation.toLowerCase()}:</h5>;
          const description = (
            <div>
              <p>{`ID: ${node.id}`}</p>
              <p>{`Username: ${node.username}`}</p>
              <p>{`Name: ${node.name}`}</p>
              <p>{`Email: ${node.email}`}</p>
            </div>
          );
          // IMPORTANT: with "clearStore", when creating/deleting users, the table doesn't refresh
          //            automatically
          client.resetStore();
          // client.clearStore();
          if (userId === currentUserId) return null;
          return <Notification message={message} description={description} />;
        }}
      </Subscription>
      <Subscription subscription={statisticsSubscription}>
        {({ loading, data }) => {
          if (loading) {
            return null;
          }
          const { statistics } = data;
          client.resetStore();
          return (
            <Notification message={"Table updated"} description={statistics} />
          );
        }}
      </Subscription>
    </div>
  );
};
