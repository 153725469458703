import React from "react";
import { Popover } from "antd";
import { Iframe } from "./Iframe";
import { Icon, Button, Input, Tag } from "antd";
import { renderPrice, renderLocation } from "./TableRender";
import { formatDistance } from "./util/Formatter";
import NumberFormat from "react-number-format";
import { formatExcelNumber } from "./util/Formatter";

export const setupBaseColumns = () => {
  const ref = {
    title: "Ref",
    dataIndex: "site_ref",
    width: 250,
  };
  const source = {
    title: "Source",
    dataIndex: "site",
    width: 100,
  };
  const type = {
    title: "Type",
    dataIndex: "type.name",
    width: 130,
    // render: obj => obj.name,
    sorter: (a, b) => ("" + a.name).localeCompare(b.name),
  };
  const subtype = {
    title: "Subtype",
    dataIndex: "subtype.name",
    width: 240,
  };
  const district = {
    title: "District",
    dataIndex: "district",
    width: 200,
  };
  const municipality = {
    title: "Municipality",
    dataIndex: "municipality",
    width: 250,
  };
  const parish = {
    title: "Parish",
    dataIndex: "parish",
    width: 420,
  };
  const gps = {
    title: "GPS?",
    key: "gps",
    width: 80,
  };
  const purpose = {
    title: "Purpose",
    width: 120,
    dataIndex: "purpose",
  };
  const price = {
    title: "Price",
    dataIndex: "price",
    width: 170,
    align: "right",
  };
  const date = {
    title: "Date",
    dataIndex: "date_of_crawl",
    width: 130,
  };
  const energy_rating = {
    title: "Energy",
    dataIndex: "energy_rating",
    width: 80,
  };
  const area = {
    title: "Area",
    dataIndex: "area",
    width: 90,
    align: "right",
    render: (text, object) => {
      return (
        text !== null &&
        (text < 0 ? (
          <Popover content={`invalid: ${-text} m2`}>
            <Button>
              <Icon type="exclamation-circle" />
            </Button>
          </Popover>
        ) : object.updated_area ? (
          <Popover content={object.crawled_area}>
            <Tag color="red">{text}</Tag>
          </Popover>
        ) : (
          text
        ))
      );
    },
  };
  const typology = {
    title: "T",
    dataIndex: "number_of_bedrooms",
    width: 70,
    render: (text, object) => {
      if (object.updated_number_of_bedrooms) {
        return (
          <Popover content={`T${object.crawled_number_of_bedrooms}`}>
            <Tag color="red">{`T${text}`}</Tag>
          </Popover>
        );
      }
      return text !== null && `T${text}`;
    },
  };
  const is_finished = {
    title: "Finished",
    dataIndex: "is_finished",
    render: (value) => (value === null ? null : value ? "Yes" : "No"),
    width: 100,
  };
  const has_garage = {
    title: "Gar?",
    dataIndex: "has_garage",
    render: (value) => (value === null ? null : value ? "Yes" : "No"),
    width: 50,
  };
  const has_parking = {
    title: "Par?",
    dataIndex: "has_parking",
    render: (value) => (value === null ? null : value ? "Yes" : "No"),
    width: 50,
  };
  const description = {
    title: "Description",
    dataIndex: "description",
    width: 300,
  };
  const condition = {
    title: "Condition",
    dataIndex: "condition",
    width: 120,
  };
  const agencyLocation = {
    title: "Agency Location",
    dataIndex: "advertiser_location",
    width: 400,
  };

  return {
    ref,
    source,
    type,
    subtype,
    district,
    municipality,
    parish,
    gps,
    purpose,
    price,
    date,
    energy_rating,
    area,
    typology,
    is_finished,
    has_garage,
    has_parking,
    description,
    condition,
    agencyLocation,
    empty: { title: "" },
  };
};

export const setupPropertiesColumns = ({
  onSetLocation,
  onSetCenter,
  setPropertyToEdit,
} = {}) => {
  const baseSetup = setupBaseColumns();

  const actions = {
    title: "Actions",
    role: "MANAGER",
    dataIndex: "property_id",
    width: 100,
    render: (_id, obj) => {
      return (
        <div>
          <Button
            size="small"
            type="ghost"
            onClick={(_event) => {
              setPropertyToEdit(obj);
            }}
            data-testid="edit-button"
          >
            <Icon type="edit" />
          </Button>
        </div>
      );
    },
  };
  const id = {
    title: "ID",
    dataIndex: "property_id",
    width: 100,
    defaultSortOrder: "ascent",
    sorter: (a, b) => a.property_id - b.property_id,
  };
  const ref = {
    ...baseSetup.ref,
    render: (text, record) =>
      record.description ? (
        <Popover
          content={
            <Iframe
              content={record.description}
              // stylesheets={[
              //   "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css"
              // ]}
            />
          }
        >
          <a href={record.url} target="_black">
            {text}
          </a>
        </Popover>
      ) : (
        <a href={record.url} target="_black">
          {text}
        </a>
      ),
  };
  const site = {
    ...baseSetup.source,
    render: (obj) => obj.name,
    sorter: (a, b) => ("" + a.site.name).localeCompare(b.site.name),
  };
  const district = {
    ...baseSetup.district,
    render: (obj) => renderLocation(obj, onSetLocation),
  };
  const municipality = {
    ...baseSetup.municipality,
    render: (obj) => renderLocation(obj, onSetLocation),
    sorter: (a, b) =>
      ("" + a.municipality.name).localeCompare(b.municipality.name),
  };
  const parish = {
    ...baseSetup.parish,
    render: (obj) => renderLocation(obj, onSetLocation),
    sorter: (a, b) => ("" + a.parish.name).localeCompare(b.parish.name),
  };
  const gps = {
    ...baseSetup.gps,
    render: (obj) =>
      obj.lat ? (
        <Button
          size="small"
          type="ghost"
          onClick={(event) => {
            event.stopPropagation();
            onSetCenter(obj);
          }}
        >
          <Icon type="environment-o" />
        </Button>
      ) : null,
  };
  const typology = {
    ...baseSetup.typology,
    sorter: (a, b) => a.number_of_bedrooms - b.number_of_bedrooms,
  };
  const area = {
    ...baseSetup.area,
    sorter: (a, b) => a.area - b.area,
  };
  const price = {
    ...baseSetup.price,
    render: renderPrice,
    sorter: (a, b) => a.price - b.price,
  };
  const purpose = {
    ...baseSetup.purpose,
    dataIndex: "purpose.name",
    sorter: (a, b) => ("" + a.purpose.name).localeCompare(b.purpose.name),
  };
  const condition = {
    ...baseSetup.condition,
    dataIndex: "condition.name",
    sorter: (a, b) => ("" + a.condition.name).localeCompare(b.condition.name),
  };
  const type = {
    ...baseSetup.type,
    // render: obj => obj.name,
    sorter: (a, b) => ("" + a.name).localeCompare(b.name),
  };
  const subtype = {
    ...baseSetup.subtype,
    // render: obj => obj.name,
    sorter: (a, b) => ("" + a.name).localeCompare(b.name),
  };
  const distance = {
    title: "Dist.",
    dataIndex: "distance",
    width: 100,
    render: (obj) => obj && formatDistance(obj),
  };
  const sqmPrice = {
    title: "€/m2",
    dataIndex: "sqm_price",
    width: 120,
    align: "right",
    allowClear: true,
    render: (text) => (
      <NumberFormat
        value={text}
        displayType={"text"}
        decimalSeparator={","}
        thousandSeparator={"."}
        decimalScale={2}
        fixedDecimalScale
        suffix={" €"}
      />
    ),
    csv: (obj) => formatExcelNumber(obj.sqm_price, 2),
    sorter: (a, b) => a.sqm_price - b.sqm_price,
  };
  const sqmPriceFixed = {
    title: "Fixed",
    dataIndex: "sqm_price_fixed",
    width: 120,
    align: "right",
    render: (text) => (
      <NumberFormat
        value={text}
        displayType={"text"}
        decimalSeparator={","}
        thousandSeparator={"."}
        decimalScale={2}
        fixedDecimalScale
        suffix={" €"}
      />
    ),
    csv: (obj) => formatExcelNumber(obj["sqm_price_fixed"], 2),
    sorter: (a, b) => a.sqm_price - b.sqm_price,
  };
  const advertiserName = {
    title: "Name",
    dataIndex: "advertiser_name1",
    width: 230,
  };
  const agency = {
    title: "Agency",
    dataIndex: "advertiser_name2",
    width: 170,
      sorter: (a, b) => ("" + a.advertiser_name2).localeCompare(b.advertiser_name2),
  };
  const phones = {
    title: "Phones",
    dataIndex: "advertiser_phones",
    width: 180,
    render: (phones) => {
      const result = phones.map((phone, index) => (
        <div key={index}>{phone}</div>
      ));
      return result;
    },
    csv: (obj) => obj["advertiser_phones"].join(", "),
  };

  return {
    ...baseSetup,
    actions,
    id,
    ref,
    site,
    district,
    municipality,
    parish,
    gps,
    typology,
    area,
    price,
    purpose,
    condition,
    type,
    subtype,
    distance,
    sqmPrice,
    sqmPriceFixed,
    advertiserName,
    agency,
    phones,
  };
};

export const setupHomogenizationColumns = ({
  renderFilterByNumberOfBedrooms,
  renderSelectPrice,
}) => {
  const date = {
    title: "Date",
    dataIndex: "date_of_crawl",
    width: 130,
  };
  const district = {
    title: "District",
    dataIndex: "district.name",
    width: 100,
    // render: obj => obj && obj.name
  };
  const municipality = {
    title: "Municipality",
    dataIndex: "municipality.name",
    width: 150,
    sorter: (a, b) => ("" + a.name).localeCompare(b.name),
  };
  const parish = {
    title: "Parish",
    dataIndex: "parish.name",
    width: 400,
    sorter: (a, b) => ("" + a.name).localeCompare(b.name),
  };
  const typology = {
    title: "T",
    dataIndex: "number_of_bedrooms",
    width: 80,
    editable: true,
    inputType: "select",
    allowClear: true,
    inputRender: renderFilterByNumberOfBedrooms,
    sorter: (a, b) => a.number_of_bedrooms - b.number_of_bedrooms,
  };
  const area = {
    title: "Area",
    editable: true,
    dataIndex: "area",
    width: 120,
    align: "right",
    sorter: (a, b) => a.area - b.area,
    allowClear: true,
    inputRender: (form, record, dataIndex, onClear) => {
      const suffix = (
        <Icon
          type="close-circle"
          onClick={(e) => {
            form.setFieldsValue({
              area: record.originalArea < 0 ? "Invalid" : record.originalArea,
            });
            const propertyId = record.property_id;
            const weightId = dataIndex;
            return onClear(propertyId, weightId, null);
          }}
        />
      );
      return <Input size="small" min="0" suffix={suffix} />;
    },
  };
  const price = {
    title: "€",
    dataIndex: "price",
    width: 150,
    align: "right",
    editable: true,
    inputType: "select",
    inputRender: renderSelectPrice,
    allowClear: true,
    sorter: (a, b) => a.price - b.price,
    csv: (obj) => formatExcelNumber(obj.price, 2),
  };
  const commonSetup = setupPropertiesColumns();
  return {
    ...commonSetup,
    date,
    district,
    municipality,
    parish,
    typology,
    area,
    price,
  };
};
