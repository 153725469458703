import React from "react";
import { Query } from "./Query";

import { Table } from "antd";
import { SHOW_FOOTER, HEIGHT_ADJUSTMENT } from "./constants";

import {
  propertiesQuery,
  allPropertiesCoefficientsQuery,
  queryColumns
} from "./Queries";

import { UpdateProperty } from "./UpdateProperty";
import { hasRole } from "./util/authorization";
import { setupPropertiesColumns } from "./propertyColumns";
import { DisplaySearchTitle } from "./DisplaySearchTitle";

export class PropertiesTable extends React.Component {
  state = { width: 0, height: 0, propertyToEdit: null };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  setPropertyToEdit = p => {
    this.setState({ propertyToEdit: p });
  };

  render() {
    const {
      maxProperties,
      propertiesPerPage,
      where,
      searchFor,
      selectedProperties,
      highlightedProperty,
      filterSelected,
      propertiesPage,
      onChangeSelected,
      onRowClick,
      onSetLocation,
      onSetCenter,
      setPropertiesPage
    } = this.props;

    return where ? (
      <Query query={queryColumns}>
        {({ loading, error, data, client }) => {
          if (error) {
            console.log(error);
            return <div>Error</div>;
          }

          if (loading) {
            return null;
          }

          const {
            fields: allFields,
            userSettings: { fieldsSettings }
          } = data;

          const fields = allFields.reduce(
            (acc, value) => ({ ...acc, [value.id]: value }),
            {}
          );

          const { fixedIds, floatingIds } = fieldsSettings.properties;
          const columnsFixedLeft = fixedIds.map(id => fields[id]);
          const columnsFloating = floatingIds.map(id => fields[id]);

          const { property, ...other } = where;
          const propertiesWhere = { ...other, ...property };

          return (
            <Query
              query={propertiesQuery}
              variables={{ first: maxProperties, where: propertiesWhere }}
            >
              {({ loading, error, data, client }) => {
                // if (loading) return <div>Loading</div>;

                const selectedPropertiesIds = Object.keys(selectedProperties);
                // if (error) {
                //   console.log(error);
                //   return <div>Error</div>;
                // }

                const properties = loading ? [] : data.properties;
                // const headers = [{ label: "Site Ref.", key: "site_ref" }];
                // const filename = "data.csv";

                let filteredProperties;
                if (filterSelected === true) {
                  filteredProperties = properties.filter(property =>
                    selectedPropertiesIds.includes(property.id)
                  );
                } else if (filterSelected === false) {
                  filteredProperties = properties.filter(
                    property => !selectedPropertiesIds.includes(property.id)
                  );
                } else {
                  filteredProperties = properties;
                }

                const datasource = filteredProperties.map(
                  ({ id, price, purpose, date_of_crawl, ...rest }) => {
                    return {
                      ...rest,
                      property_id: id,
                      id,
                      price,
                      purpose,
                      date_of_crawl
                    };
                  }
                );

                const rowSelection = {
                  selectedRowKeys: selectedPropertiesIds,
                  getCheckboxProps: _record => ({
                    "data-testid": "select-property"
                  }),

                  onChange: (selectedRowKeys, selectedRows) => {
                    // selectedRowKeys.forEach(id => {
                    //   client.query({
                    //     query: singlePropertyCoefficientsQuery,
                    //     variables: { where: { id: id } }
                    //   });
                    // });
                    const sortedSelectedIds = selectedRowKeys.concat().sort();

                    client
                      .query({
                        query: allPropertiesCoefficientsQuery,
                        variables: {
                          where: {
                            purpose: propertiesWhere.purpose,
                            property_id_in: sortedSelectedIds
                          }
                        }
                      })
                      .catch(error => {
                        console.log(
                          "error in query: allPropertiesCoefficientsQuery"
                        );
                      });
                    return onChangeSelected(selectedRowKeys);
                  }
                };

                const columnsSetup = setupPropertiesColumns({
                  onSetLocation,
                  onSetCenter,
                  setPropertyToEdit: this.setPropertyToEdit
                });
                const posColumns = [{ tag: "empty" }];

                const configuredPosColumns = posColumns.map(c => {
                  return { ...c, ...columnsSetup[c.tag] };
                });
                const configuredColumnsFixedLeft = columnsFixedLeft.map(c => {
                  return { fixed: "left", ...c, ...columnsSetup[c.tag] };
                });
                const configuredColumnsFloating = columnsFloating.map(c => {
                  return { ...c, ...columnsSetup[c.tag] };
                });

                const columns = [
                  ...configuredColumnsFixedLeft,
                  ...configuredColumnsFloating,
                  ...configuredPosColumns
                ];

                const filteredColumns = columns.filter(c => {
                  return hasRole(this.props.user, c.role || "USER");
                });

                const pagination =
                  datasource.length > propertiesPerPage
                    ? {
                        pageSize: propertiesPerPage,
                        current: propertiesPage
                      }
                    : false;

                const removeScrollY =
                  (pagination ? 50 : 0) +
                  (SHOW_FOOTER ? 100 + HEIGHT_ADJUSTMENT : HEIGHT_ADJUSTMENT);

                return (
                  <div>
                    <DisplaySearchTitle searchFor={searchFor} />
                    <UpdateProperty
                      visible={this.state.propertyToEdit !== null}
                      property={this.state.propertyToEdit}
                      hide={() => this.setState({ propertyToEdit: null })}
                    />
                    <Table
                      size="small"
                      loading={loading}
                      rowKey="property_id"
                      rowSelection={rowSelection}
                      dataSource={datasource}
                      columns={filteredColumns}
                      bordered
                      scroll={{
                        x: 3600,
                        y: this.state.height - removeScrollY
                      }}
                      onRow={record => {
                        return {
                          onClick: () => {
                            return onRowClick(record.property_id);
                          }
                        };
                      }}
                      rowClassName={record =>
                        record.property_id === highlightedProperty
                          ? "highlighted-row"
                          : "row"
                      }
                      pagination={pagination}
                      onChange={pagination => {
                        pagination &&
                          pagination.current &&
                          setPropertiesPage(pagination.current);
                      }}
                    />
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Query>
    ) : null;
  }
}
