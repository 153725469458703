import { connect } from "react-redux";
import { setMinArea, setMaxArea } from "../actions";
import FilterByNumberBetween from "../FilterByNumberBetween";

const mapStateToProps = state => ({
  step: 10,
  minPlaceholder: "Min Area",
  maxPlaceholder: "Max Area",
  initialMinValue: state.filters.property.area.min,
  initialMaxValue: state.filters.property.area.max,
  minWidth: 150
});

const mapDispatchToProps = dispatch => ({
  onMinChange: area => dispatch(setMinArea(area)),
  onMaxChange: area => dispatch(setMaxArea(area))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByNumberBetween);
