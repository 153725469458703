import { connect } from "react-redux";
import { setPropertiesPerPage } from "../actions";

import { SetNumber } from "../SetNumber";

const mapStateToProps = state => ({
  value: state.preferences.propertiesPerPage,
  "data-testid": "page-size",
  label: "Page Size",
  min: 1,
  max: 100,
  step: 10
});

const mapDispatchToProps = dispatch => ({
  onChange: propertiesPerPage =>
    dispatch(setPropertiesPerPage(propertiesPerPage))
});

export const InputPropertiesPerPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetNumber);
