import { connect } from "react-redux";
import { setTypologies } from "../actions";
import { TYPOLOGY } from "./typologies";

import { FilterByNumberOfBedrooms } from "../FilterByNumberOfBedrooms";

const mapStateToProps = state => ({
  value: state.filters.property.typologies,
  options: Object.values(TYPOLOGY)
});

const mapDispatchToProps = dispatch => ({
  onChange: ids => dispatch(setTypologies(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByNumberOfBedrooms);
