import { connect } from "react-redux";
import { enableWeight, disableWeight } from "../actions";

import { HomogenizationMenus } from "../HomogenizationMenus";

const mapStateToProps = state => ({
  activeWeights: state.activeWeights
});

const mapDispatchToProps = dispatch => ({
  enableWeight: id => dispatch(enableWeight(id)),
  disableWeight: id => dispatch(disableWeight(id))
});

export const HomogenizationMenusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomogenizationMenus);
