import React from "react";
import styled from "styled-components";

const SearchTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  padding-bottom: 20px;
`;

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const DisplaySearchTitle = ({ searchFor: args }) => {
  const { division, property, searchFor } = args;
  let title = null;
  if (searchFor) {
    if (searchFor.type === "GPS") {
      title = `${searchFor.type}: ${searchFor.lat}, ${searchFor.lng}`;
    } else if (searchFor.type === "NEAR") {
      title = `Near ref: ${searchFor.siteRef}`;
    }
  } else if (property && property.id) {
    title = `Ref: ${property.siteRef}`;
  } else {
    // title = "AAAAj";
    title = `${capitalize(division.type)}: ${division.name}`;
  }

  return <SearchTitle>{title}</SearchTitle>;
};
