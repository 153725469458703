import React from "react";
import { Switch, Card, Row, Col } from "antd";

import { Query } from "./Query";

import { queryUserSettings } from "./Queries";

export const HomogenizationMenus = ({
  activeWeights,
  enableWeight,
  disableWeight
}) => {
  return (
    <Query query={queryUserSettings}>
      {({ loading, error, data }) => {
        if (error) {
          console.log(error);
          return <div>Error</div>;
        }
        if (loading) {
          return null;
        }

        const { userSettings } = data;
        const { homogenizationParams } = userSettings;
        return (
          <Card size="small">
            <Row type="flex" justify="start" align="bottom">
              <Col span={4}>
                <Card>
                  {homogenizationParams.map(param => {
                    return (
                      <div key={param.id}>
                        <Switch
                          checked={
                            param.id in activeWeights
                              ? activeWeights[param.id]
                              : param.enabled
                          }
                          size="small"
                          onChange={checked =>
                            checked
                              ? enableWeight(param.id)
                              : disableWeight(param.id)
                          }
                        />
                        <label> {param.name}</label>
                      </div>
                    );
                  })}
                </Card>
              </Col>
            </Row>
          </Card>
        );
      }}
    </Query>
  );
};
