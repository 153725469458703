import React from "react";
import { Card, Button } from "antd";

const Rect = ({ show = false, x, y, w, h }) => {
  return show ? (
    <rect
      x={x}
      y={y}
      width={w}
      height={h}
      fill="lightsteelblue"
      // stroke="blue"
    />
  ) : null;
};

const SVG = ({ color = "red", scale = 0.4, showRect = false }) => {
  const width = 64;
  const height = 64;
  const x = 0;
  const y = 0;
  const w = width;
  const h = height;
  return (
    <svg
      width={width * scale}
      height={height * scale}
      viewBox={`${x} ${y} ${w} ${h}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Rect x={x} y={y} w={w} h={h} show={showRect} />
      <path
        d={`M${width / 2} ${(height - 50) /
          2}c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z`}
        fill={color}
      />
    </svg>
  );
};

const CardColorPin = ({ title, color }) => {
  return (
    <Card bordered={false}>
      <Button style={{ backgroundColor: "lightsteelblue" }}>
        <SVG color={color} />
      </Button>
      <span style={{ paddingLeft: 8 }}>{title}</span>
    </Card>
  );
};

const CardSizePin = ({ title, scale }) => {
  const color = "red";
  return (
    <Card bordered={false}>
      <SVG color={color} showRect={true} scale={scale} />
      <span style={{ paddingLeft: 8, cursor: "auto" }}>{title}</span>
    </Card>
  );
};

const HelpMapPinColor = () => {
  return (
    <Card
      title="Map Pin Colors"
      style={{ margin: "100px auto auto auto", width: 500 }}
    >
      <CardColorPin
        color="black"
        title="Position of search with GPS coordinates"
      />
      <CardColorPin
        color="green"
        title="all properties in this coords are selected"
      />
      <CardColorPin
        color="blue"
        title="some (not all) properties are selected"
      />
      <CardColorPin color="red" title="none selected (with GPS)" />
      <CardColorPin color="yellow" title="none selected (without GPS)" />
    </Card>
  );
};

const HelpMapPinSize = () => {
  return (
    <Card
      title="Map Pin Size"
      style={{ margin: "100px auto 200px auto", width: 500 }}
    >
      <CardSizePin
        title="Normal: Not hightlighted in table / No mouse hover"
        scale={0.6}
      />
      <CardSizePin title="Mouse hover" scale={0.8} />
      <CardSizePin title="Highlighted in table" scale={1} />
    </Card>
  );
};

export const Help = () => {
  return (
    <>
      <HelpMapPinColor />
      <HelpMapPinSize />
    </>
  );
};
