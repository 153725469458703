import React from "react";
import { SelectMunicipality } from "./SelectMunicipality";
import { StatisticsMacroCharts } from "./StatisticsMacroCharts";
import { IndicadoresMacroeconomicosChart } from "./IndicadoresMacroeconomicosChart";
import { Row, Col } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { statisticsMacroPortugalQuery } from "./Queries";

export const StatisticsMacro = () => {
  const { loading, error, data } = useQuery(statisticsMacroPortugalQuery);
  const [municipality, setMunicipality] = React.useState(null);
  // const [municipality, setMunicipality] = React.useState({
  //   key: "1111",
  //   label: "Sintra",
  //   district: {
  //     key: "11",
  //     label: "Lisboa"
  //   }
  // });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    throw error;
  }
  const { indicadoresMacroeconomicos } = data;
  return (
    <>
      <SelectMunicipality
        onChange={municipality => setMunicipality(municipality)}
      />
      <Row type="flex" justify="space-around" align="middle">
        <Col span={24}>
          <IndicadoresMacroeconomicosChart data={indicadoresMacroeconomicos} />
        </Col>
      </Row>

      {municipality ? (
        <StatisticsMacroCharts municipality={municipality} />
      ) : null}
    </>
  );
};
