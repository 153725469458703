import React from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

export const FilterAgency = ({ value, onChange, ...props }) => {
  const { t } = useTranslation();
  return (
    <Input
      allowClear
      size="small"
      style={{ width: 200, ...props.style }}
      defaultValue={value}
      placeholder={t("Agency")}
      data-testid="input-agency"
      onBlur={e => onChange(e.target.value)}
      onPressEnter={e => onChange(e.target.value)}
      onChange={e => e.target.value === "" && onChange(e.target.value)}
    />
  );
};
