import { LOCALSTORAGE_APP_STATE } from "../constants";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(LOCALSTORAGE_APP_STATE);
    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (error) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCALSTORAGE_APP_STATE, serializedState);
  } catch (error) {
    console.log("ERROR: failed to saveState");
  }
};
