import React from "react";
import {
  OfertaByType,
  OfferSubtypeByPurpose,
  OfferSubtypeByTypology,
  OfferSubtypeByPriceRange,
  OfferByAverageAreaArray,
  OfferByAveragePrice,
} from "./OfertaByType";
import { useQuery } from "@apollo/react-hooks";
// import { useSelector } from "react-redux";
import { SelectMunicipality } from "./SelectMunicipality";
import moment from "moment";

import {
  statisticsOffertaQuery1,
  statisticsOffertaQuery2,
  statisticsOffertaQuery3,
  statisticsOffertaQuery4,
  statisticsOffertaQuery5,
  statisticsOffertaQuery6,
  statisticsOffertaQuery7,
  statisticsOffertaQuery8,
  statisticsOffertaQuery9,
  statisticsOffertaQuery10,
  statisticsOffertaQuery11,
  statisticsOffertaQuery12,
  statisticsOffertaQuery13,
} from "./Queries";
import { Spin, DatePicker, Card } from "antd";

import { APARTAMENTOS, MORADIAS } from "./constants";

export const StatisticsOferta = () => {
  const [municipality, setMunicipality] = React.useState(null);
  const [month, setMonth] = React.useState(null);

  // const [municipality, setMunicipality] = React.useState({
  //   key: "1111",
  //   label: "Sintra",
  //   district: {
  //     key: "11",
  //     label: "Lisboa"
  //   }
  // });
  // const [month, setMonth] = React.useState("2019-03");

  const startDate =
    month &&
    moment(month)
      .startOf("month")
      .format("YYYY-MM-DD");
  const endDate =
    month &&
    moment(month)
      .endOf("month")
      .format("YYYY-MM-DD");

  const valid =
    municipality && municipality.key && startDate && endDate && true;

  const variables = {
    municipalityId: municipality && municipality.key,
    startDate,
    endDate,
  };

  const { loading: loading1, data: data1 } = useQuery(statisticsOffertaQuery1, {
    variables,
    skip: !valid,
  });
  const { loading: loading2, data: data2 } = useQuery(statisticsOffertaQuery2, {
    variables,
    skip: !valid,
  });
  const { loading: loading3, data: data3 } = useQuery(statisticsOffertaQuery3, {
    variables,
    skip: !valid,
  });
  const { loading: loading4, data: data4 } = useQuery(statisticsOffertaQuery4, {
    variables,
    skip: !valid,
  });
  const { loading: loading5, data: data5 } = useQuery(statisticsOffertaQuery5, {
    variables,
    skip: !valid,
  });
  const { loading: loading6, data: data6 } = useQuery(statisticsOffertaQuery6, {
    variables,
    skip: !valid,
  });
  const { loading: loading7, data: data7 } = useQuery(statisticsOffertaQuery7, {
    variables,
    skip: !valid,
  });
  const { loading: loading8, data: data8 } = useQuery(statisticsOffertaQuery8, {
    variables,
    skip: !valid,
  });
  const { loading: loading9, data: data9 } = useQuery(statisticsOffertaQuery9, {
    variables,
    skip: !valid,
  });
  const { loading: loading10, data: data10 } = useQuery(
    statisticsOffertaQuery10,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 1 /* Buy */,
        conditionId: 1 /* New */,
      },
      skip: !valid,
    }
  );
  const { loading: loading10_2, data: data10_2 } = useQuery(
    statisticsOffertaQuery10,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 1 /* Buy */,
        conditionId: 2 /* Used */,
      },
      skip: !valid,
    }
  );
  const { loading: loading10_3, data: data10_3 } = useQuery(
    statisticsOffertaQuery10,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 2 /* Rent */,
      },
      skip: !valid,
    }
  );
  const { loading: loading10_4, data: data10_4 } = useQuery(
    statisticsOffertaQuery10,
    {
      variables: {
        ...variables,
        subtypeId: 1 /* House */,
        purposeId: 1 /* Buy */,
      },
      skip: !valid,
    }
  );
  const { loading: loading10_5, data: data10_5 } = useQuery(
    statisticsOffertaQuery10,
    {
      variables: {
        ...variables,
        subtypeId: 1 /* House */,
        purposeId: 2 /* Rent */,
      },
      skip: !valid,
    }
  );
  const { loading: loading11_1, data: data11_1 } = useQuery(
    statisticsOffertaQuery11,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 1 /* Buy */,
        conditionId: 1 /* New */,
      },
      skip: !valid,
    }
  );
  const { loading: loading11_2, data: data11_2 } = useQuery(
    statisticsOffertaQuery11,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 2 /* Rent */,
        conditionId: 1 /* New */,
      },
      skip: !valid,
    }
  );
  const { loading: loading11_3, data: data11_3 } = useQuery(
    statisticsOffertaQuery11,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 1 /* Buy */,
        conditionId: 2 /* Used */,
      },
      skip: !valid,
    }
  );
  const { loading: loading11_4, data: data11_4 } = useQuery(
    statisticsOffertaQuery11,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 2 /* Rent */,
        conditionId: 2 /* Used */,
      },
      skip: !valid,
    }
  );
  const { loading: loading11_5, data: data11_5 } = useQuery(
    statisticsOffertaQuery11,
    {
      variables: {
        ...variables,
        subtypeId: 1 /* House */,
        purposeId: 1 /* Buy */,
      },
      skip: !valid,
    }
  );
  const { loading: loading11_6, data: data11_6 } = useQuery(
    statisticsOffertaQuery11,
    {
      variables: {
        ...variables,
        subtypeId: 1 /* House */,
        purposeId: 2 /* Rent */,
      },
      skip: !valid,
    }
  );
  const { loading: loading12_1, data: data12_1 } = useQuery(
    statisticsOffertaQuery12,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 1 /* Buy */,
        conditionId: 1 /* New */,
      },
      skip: !valid,
    }
  );
  const { loading: loading12_2, data: data12_2 } = useQuery(
    statisticsOffertaQuery12,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 2 /* Rent */,
        conditionId: 1 /* New */,
      },
      skip: !valid,
    }
  );
  const { loading: loading12_3, data: data12_3 } = useQuery(
    statisticsOffertaQuery12,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 1 /* Buy */,
        conditionId: 2 /* Used */,
      },
      skip: !valid,
    }
  );
  const { loading: loading12_4, data: data12_4 } = useQuery(
    statisticsOffertaQuery12,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 2 /* Rent */,
        conditionId: 2 /* Used */,
      },
      skip: !valid,
    }
  );
  const { loading: loading12_5, data: data12_5 } = useQuery(
    statisticsOffertaQuery12,
    {
      variables: {
        ...variables,
        subtypeId: 1 /* House */,
        purposeId: 1 /* Buy */,
      },
      skip: !valid,
    }
  );
  const { loading: loading12_6, data: data12_6 } = useQuery(
    statisticsOffertaQuery12,
    {
      variables: {
        ...variables,
        subtypeId: 1 /* House */,
        purposeId: 2 /* Rent */,
      },
      skip: !valid,
    }
  );
  const { loading: loading13_1, data: data13_1 } = useQuery(
    statisticsOffertaQuery13,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 1 /* Buy */,
        conditionId: 1 /* New */,
      },
      skip: !valid,
    }
  );
  const { loading: loading13_2, data: data13_2 } = useQuery(
    statisticsOffertaQuery13,
    {
      variables: {
        ...variables,
        subtypeId: 2 /* Apartment */,
        purposeId: 1 /* Buy */,
        conditionId: 2 /* Used */,
      },
      skip: !valid,
    }
  );
  const { loading: loading13_3, data: data13_3 } = useQuery(
    statisticsOffertaQuery13,
    {
      variables: {
        ...variables,
        subtypeId: 1 /* House */,
        purposeId: 1 /* Buy */,
      },
      skip: !valid,
    }
  );

  const loading =
    loading1 &&
    loading2 &&
    loading3 &&
    loading4 &&
    loading5 &&
    loading6 &&
    loading7 &&
    loading8 &&
    loading9 &&
    loading10 &&
    loading10_2 &&
    loading10_3 &&
    loading10_4 &&
    loading10_5 &&
    loading11_1 &&
    loading11_2 &&
    loading11_3 &&
    loading11_4 &&
    loading11_5 &&
    loading11_6 &&
    loading12_1 &&
    loading12_2 &&
    loading12_3 &&
    loading12_4 &&
    loading12_5 &&
    loading12_6 &&
    loading13_1 &&
    loading13_2 &&
    loading13_3;
  // console.log("data", data10);
  return (
    <div style={{ minWidth: "1000px" }}>
      <div style={{ display: "inline-block", padding: "20px" }}>
        <label>Month: </label>
        <DatePicker.MonthPicker
          size="small"
          placeholder="Select month"
          onChange={(month) => setMonth(month)}
        />
      </div>
      <SelectMunicipality
        onChange={(municipality) => setMunicipality(municipality)}
      />
      {valid && (
        <Card style={{ padding: "20px" }}>
          {/* <Button size="small" onClick={() => getStatistics({ variables })}> */}
          {/*   Submit */}
          {/* </Button> */}
          <Spin spinning={loading} tip="Loading...">
            {!loading ? (
              <>
                <Spin spinning={loading1} tip="Loading...">
                  <OfertaByType
                    name={data1?.municipality.name}
                    numberOfPropertiesBySubtypeForCountry={
                      data1?.numberOfPropertiesBySubtypeForCountry
                    }
                    numberOfPropertiesBySubtypeForMunicipality={
                      data1?.numberOfPropertiesBySubtypeForMunicipality
                    }
                    show={[APARTAMENTOS, MORADIAS]}
                  />
                </Spin>
                <Spin spinning={loading2} tip="Loading...">
                  <OfferSubtypeByPurpose
                    subtypeName="Apartamentos"
                    numberRenda={data2?.numberOfApartamentosRenda?.count}
                    numberVenda={data2?.numberOfApartamentosVenda?.count}
                    numberOutro={data2?.numberOfApartamentosOutro?.count}
                  />
                </Spin>
                <Spin spinning={loading3} tip="Loading...">
                  <OfferSubtypeByPurpose
                    subtypeName="Moradias"
                    numberRenda={data3?.numberOfMoradiasRenda?.count}
                    numberVenda={data3?.numberOfMoradiasVenda?.count}
                    numberOutro={data3?.numberOfMoradiasOutro?.count}
                  />
                </Spin>
                <Spin spinning={loading4} tip="Loading...">
                  <OfferSubtypeByTypology
                    title="Oferta de Apartamentos Novos para Venda por tipologia"
                    data={data4?.numberOfApartamentosNovosParaVendaPorTipologia}
                  />
                </Spin>
                <Spin spinning={loading5} tip="Loading...">
                  <OfferSubtypeByTypology
                    title="Oferta de Apartamentos Novos para Arrendar por tipologia"
                    data={
                      data5?.numberOfApartamentosNovosParaArrendarPorTipologia
                    }
                  />
                </Spin>
                <Spin spinning={loading6} tip="Loading...">
                  <OfferSubtypeByTypology
                    title="Oferta de Apartamentos Usados para Venda por tipologia"
                    data={
                      data6?.numberOfApartamentosUsadosParaVendaPorTipologia
                    }
                  />
                </Spin>
                <Spin spinning={loading7} tip="Loading...">
                  <OfferSubtypeByTypology
                    title="Oferta de Apartamentos Usados para Arrendar por tipologia"
                    data={
                      data7?.numberOfApartamentosUsadosParaArrendarPorTipologia
                    }
                  />
                </Spin>
                <Spin spinning={loading8} tip="Loading...">
                  <OfferSubtypeByTypology
                    title="Oferta de Moradias para Venda por tipologia"
                    data={data8?.numberOfMoradiasParaVendaPorTipologia}
                  />
                </Spin>
                <Spin spinning={loading9} tip="Loading...">
                  <OfferSubtypeByTypology
                    title="Oferta de Moradias para Arrendar por tipologia"
                    data={data9?.numberOfMoradiasParaArrendarPorTipologia}
                  />
                </Spin>
                <Spin spinning={loading10} tip="Loading...">
                  <OfferSubtypeByPriceRange
                    title="Segmentação Valores Pedidos Apartamentos Novos: Venda"
                    data={
                      data10?.numberOfPropertiesSegmentedByFixedPriceRangesGivenSubtypePurposeCondition
                    }
                    purposeId={1}
                  />
                </Spin>
                <Spin spinning={loading10_2} tip="Loading...">
                  <OfferSubtypeByPriceRange
                    title="Segmentação Valores Pedidos Apartamentos Usados: Venda"
                    data={
                      data10_2?.numberOfPropertiesSegmentedByFixedPriceRangesGivenSubtypePurposeCondition
                    }
                    purposeId={1}
                  />
                </Spin>
                <Spin spinning={loading10_3} tip="Loading...">
                  <OfferSubtypeByPriceRange
                    title="Segmentação Valores Pedidos Apartamentos: Arrendamento"
                    data={
                      data10_3?.numberOfPropertiesSegmentedByFixedPriceRangesGivenSubtypePurposeCondition
                    }
                    purposeId={2}
                  />
                </Spin>
                <Spin spinning={loading10_4} tip="Loading...">
                  <OfferSubtypeByPriceRange
                    title="Segmentação Valores Pedidos Moradias: Venda"
                    data={
                      data10_4?.numberOfPropertiesSegmentedByFixedPriceRangesGivenSubtypePurposeCondition
                    }
                    purposeId={1}
                  />
                </Spin>
                <Spin spinning={loading10_5} tip="Loading...">
                  <OfferSubtypeByPriceRange
                    title="Segmentação Valores Pedidos Moradias: Arrendamento"
                    data={
                      data10_5?.numberOfPropertiesSegmentedByFixedPriceRangesGivenSubtypePurposeCondition
                    }
                    purposeId={2}
                  />
                </Spin>
                <Spin spinning={loading11_1 || loading11_2} tip="Loading...">
                  <OfferByAverageAreaArray
                    title="Área média"
                    data={[
                      {
                        title: "Área Média Venda",
                        name: "Apartamentos Novos (m²)",
                        data:
                          data11_1?.averageAreaOfPropertiesByFixedTypologyGivenSubtypePurposeCondition,
                      },
                      {
                        title: "Área média Arrendamento",
                        name: "Apartamentos Novos (m²)",
                        data:
                          data11_2?.averageAreaOfPropertiesByFixedTypologyGivenSubtypePurposeCondition,
                      },
                    ]}
                  />
                </Spin>
                <Spin spinning={loading12_1} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio Absoluto de Oferta Venda: Apartamentos Novos"
                    data={
                      data12_1?.averagePriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
                <Spin spinning={loading13_1} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio €/m2 de Oferta Venda: Apartamentos Novos"
                    data={
                      data13_1?.averageSQMPriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
                <Spin spinning={loading12_2} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio Arrendamento: Apartamentos Novos"
                    data={
                      data12_2?.averagePriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
                <Spin spinning={loading11_3 || loading11_4} tip="Loading...">
                  <OfferByAverageAreaArray
                    title="Área média"
                    data={[
                      {
                        title: "Área Média Venda",
                        name: "Apartamentos Usados (m²)",
                        data:
                          data11_3?.averageAreaOfPropertiesByFixedTypologyGivenSubtypePurposeCondition,
                      },
                      {
                        title: "Área média Arrendamento",
                        name: "Apartamentos Usados (m²)",
                        data:
                          data11_4?.averageAreaOfPropertiesByFixedTypologyGivenSubtypePurposeCondition,
                      },
                    ]}
                  />
                </Spin>
                <Spin spinning={loading12_3} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio Absoluto de Oferta Venda: Apartamentos Usados"
                    data={
                      data12_3?.averagePriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
                <Spin spinning={loading13_2} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio €/m2 de Oferta Venda: Apartamentos Usados"
                    data={
                      data13_2?.averageSQMPriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
                <Spin spinning={loading12_4} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio Arrendamento: Apartamentos Usados"
                    data={
                      data12_4?.averagePriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
                <Spin spinning={loading11_5 || loading11_6} tip="Loading...">
                  <OfferByAverageAreaArray
                    title="Área média"
                    data={[
                      {
                        title: "Área Média Venda",
                        name: "Moradias (m²)",
                        data:
                          data11_5?.averageAreaOfPropertiesByFixedTypologyGivenSubtypePurposeCondition,
                      },
                      {
                        title: "Área média Arrendamento",
                        name: "Moradias (m²)",
                        data:
                          data11_6?.averageAreaOfPropertiesByFixedTypologyGivenSubtypePurposeCondition,
                      },
                    ]}
                  />
                </Spin>
                <Spin spinning={loading12_5} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio Absoluto de Oferta Venda: Moradias"
                    data={
                      data12_5?.averagePriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
                <Spin spinning={loading13_3} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio €/m2 de Oferta Venda: Moradias"
                    data={
                      data13_3?.averageSQMPriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
                <Spin spinning={loading12_6} tip="Loading...">
                  <OfferByAveragePrice
                    title="Valor Médio Arrendamento: Moradias"
                    data={
                      data12_6?.averagePriceOfPropertiesByFixedTypologyGivenSubtypePurposeCondition
                    }
                  />
                </Spin>
              </>
            ) : null}
          </Spin>
        </Card>
      )}
    </div>
  );
};
