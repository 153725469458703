import { connect } from "react-redux";
import { setSubtypes } from "../actions";

import FilterByPropertySubtype from "../FilterByPropertySubtype";

const mapStateToProps = state => ({
  type_id: state.filters.property.type || undefined,
  value: state.filters.property.subtypes
});

const mapDispatchToProps = dispatch => ({
  onChange: ids => dispatch(setSubtypes(ids))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByPropertySubtype);
