import React from "react";
import { InputNumber, Form, Input } from "antd";
import { EditableContext } from "./EditableContext";

export class EditableCell extends React.Component {
  state = {
    editing: false
  };

  componentDidMount() {
    if (this.props.editable) {
      document.addEventListener("click", this.handleClickOutside, true);
    }
  }

  componentWillUnmount() {
    if (this.props.editable) {
      document.removeEventListener("click", this.handleClickOutside, true);
    }
  }

  getInput = form => {
    return <InputNumber size="small" step={5} min={this.props.inputMin} />;
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  handleClickOutside = e => {
    const { editing } = this.state;
    if (editing && this.cell !== e.target && !this.cell.contains(e.target)) {
      this.save();
    }
  };

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  render() {
    const {
      editable,
      editing,
      dataIndex,
      title,
      inputType,
      inputMin,
      inputOptions,
      record,
      index,
      handleSave,
      onClear,
      inputRender,
      ...restProps
    } = this.props;

    const isEditing = editable ? this.state.editing : editing;

    return (
      <EditableContext.Consumer>
        {form => {
          this.form = form;
          const { getFieldDecorator } = form;
          const render = inputRender || this.getInput;
          return (
            <td ref={node => (this.cell = node)} {...restProps}>
              {isEditing ? (
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue:
                      inputType === "select"
                        ? String(record[dataIndex] || "")
                        : (dataIndex === "area" &&
                            record[dataIndex] < 0 &&
                            "Invalid") ||
                          record[dataIndex]
                  })(
                    editable ? (
                      <Input
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                      />
                    ) : (
                      render(form, record, dataIndex, onClear)
                    )
                  )}
                </Form.Item>
              ) : editable ? (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}
