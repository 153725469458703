import React from "react";
import { Query as ApolloQuery } from "react-apollo";
import GraphqlErrorHandler from "./GraphqlErrorHandler";

export const Query = ({ children, ...rest }) => (
  <ApolloQuery errorPolicy="all" {...rest}>
    {result => {
      // console.log("ApolloQuery result:", result);
      return (
        <GraphqlErrorHandler error={result.error} data={result.data}>
          {children(result)}
        </GraphqlErrorHandler>
      );
    }}
  </ApolloQuery>
);
