// ----------------------------------
// actionTypes
// ----------------------------------
export const SET_AGENCY = "SET_AGENCY";
export const SET_PURPOSE = "SET_PURPOSE";
export const SET_PROPERTY_ID = "SET_PROPERTY_ID";
export const SET_LOCATION = "SET_LOCATION";
export const SET_SITES = "SET_SITES";
export const SET_CONDITIONS = "SET_CONDITIONS";
export const SET_TYPE = "SET_TYPE";
export const SET_SUBTYPES = "SET_SUBTYPES";
export const SET_TYPOLOGIES = "SET_TYPOLOGIES";
export const SET_MIN_PRICE = "SET_MIN_PRICE";
export const SET_MAX_PRICE = "SET_MAX_PRICE";
export const SET_FILTER_PRICE_CHANGES = "SET_FILTER_PRICE_CHANGES";
export const SET_DATE = "SET_DATE";
export const SET_LAST_CRAWL_DATE = "SET_LAST_CRAWL_DATE";
export const SET_MIN_AREA = "SET_MIN_AREA";
export const SET_MAX_AREA = "SET_MAX_AREA";
export const SET_SELECTED_PROPERTIES = "SET_SELECTED_PROPERTIES";
export const TOGGLE_HIGHLIGHTED_PROPERTY = "TOGGLE_HIGHLIGHTED_PROPERTY";
export const SET_PROPERTIES_PER_PAGE = "SET_PROPERTIES_PER_PAGE";
export const SET_MAX_PROPERTIES = "SET_MAX_PROPERTIES";
export const SET_RADIUS = "SET_RADIUS";
export const RESET = "RESET";
export const SET_HAS_GPS = "SET_HAS_GPS";
export const SET_IS_SELECTED = "SET_IS_SELECTED";
export const SET_WEIGHT = "SET_WEIGHT";
export const ENABLE_WEIGTH = "ENABLE_WEIGHT";
export const DISABLE_WEIGTH = "DISABLE_WEIGHT";
export const SET_NEAR_PROPERTY = "SET_NEAR_PROPERTY";
export const SET_NEAR_GPS = "SET_NEAR_GPS";
export const UPDATE_NEW_PROPERTY_FIELDS = "UPDATE_NEW_PROPERTY_FIELDS";
export const UPDATE_STATISTICS_FIELDS = "UPDATE_STATISTICS_FIELDS";
export const SET_USER = "SET_USER";
export const SET_ZOOM = "SET_ZOOM";
export const SET_CENTER = "SET_CENTER";
export const SET_PROPERTIES_PAGE = "SET_PROPERTIES_PATGE";
export const SET_UPDATED_MANUALLY = "SET_UPDATED_MANUALLY";
export const RELOAD = "RELOAD";

// export const LOCATION_TYPES = {
//   parish: "parish",
//   municiparlity: "municiparlity",
//   district: "district"
// };
// ----------------------------------

export const setAgency = id => ({ type: SET_AGENCY, id });
export const setPurpose = id => ({ type: SET_PURPOSE, id });
export const setPropertyId = (id, siteRef) => ({
  type: SET_PROPERTY_ID,
  id,
  siteRef
});
export const setLocation = location => ({
  type: SET_LOCATION,
  location
});
export const setSites = ids => ({ type: SET_SITES, ids });
export const setConditions = ids => ({ type: SET_CONDITIONS, ids });
export const setType = id => ({ type: SET_TYPE, id });
export const setSubtypes = ids => ({ type: SET_SUBTYPES, ids });
export const setTypologies = ids => ({ type: SET_TYPOLOGIES, ids });
export const setMinPrice = price => ({ type: SET_MIN_PRICE, price });
export const setMaxPrice = price => ({ type: SET_MAX_PRICE, price });
export const setFilterPriceChanges = filterPriceChanges => ({
  type: SET_FILTER_PRICE_CHANGES,
  filterPriceChanges
});
export const setDate = date => ({ type: SET_DATE, date });
export const setLastCrawlDate = date => ({ type: SET_LAST_CRAWL_DATE, date });
export const setMinArea = area => ({ type: SET_MIN_AREA, area });
export const setMaxArea = area => ({ type: SET_MAX_AREA, area });
export const setSelectedProperties = ids => ({
  type: SET_SELECTED_PROPERTIES,
  ids
});
export const toggleHighlightedProperty = id => ({
  type: TOGGLE_HIGHLIGHTED_PROPERTY,
  id
});
export const setPropertiesPerPage = propertiesPerPage => ({
  type: SET_PROPERTIES_PER_PAGE,
  propertiesPerPage
});
export const setMaxProperties = max => ({ type: SET_MAX_PROPERTIES, max });
export const setRadius = radius => ({ type: SET_RADIUS, radius });
export const reset = () => ({ type: RESET });
export const setHasGps = hasGps => ({ type: SET_HAS_GPS, hasGps });
export const setIsSelected = isSelected => ({
  type: SET_IS_SELECTED,
  isSelected
});
export const setWeight = (propertyId, weightId, weightValue) => ({
  type: SET_WEIGHT,
  propertyId,
  weightId,
  weightValue
});
export const enableWeight = weightId => ({
  type: ENABLE_WEIGTH,
  weightId
});
export const disableWeight = weightId => ({
  type: DISABLE_WEIGTH,
  weightId
});
export const setNearProperty = property => ({
  type: SET_NEAR_PROPERTY,
  property
});
export const setNearGPS = gps => ({
  type: SET_NEAR_GPS,
  gps
});
export const updateNewPropertyFields = fields => ({
  type: UPDATE_NEW_PROPERTY_FIELDS,
  fields
});
export const updateStatisticsFields = fields => ({
  type: UPDATE_STATISTICS_FIELDS,
  fields
});
export const setUser = user => ({
  type: SET_USER,
  user
});
export const setZoom = zoom => ({ type: SET_ZOOM, zoom });
export const setCenter = center => ({ type: SET_CENTER, center });
export const setPropertiesPage = page => ({ type: SET_PROPERTIES_PAGE, page });
export const setUpdatedManually = updatedManually => ({
  type: SET_UPDATED_MANUALLY,
  updatedManually
});
export const reload = state => ({ type: RELOAD, state });
