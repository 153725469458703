import React from "react";
import styled from "styled-components";
import { Field } from "./Field";
import { Droppable } from "react-beautiful-dnd";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 200px;

  display: flex;
  flex-direction: column;
  // padding: 28px;
`;
const Title = styled.h3`
  padding: 8px;
`;
const FieldList = styled.div`
  padding: 8px;
  transition: background-color: 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? "skyblue" : "white")};
  flex-grow: 1;
  min-height: 100px;
  text-align: left;
`;

export const Column = ({ column, fields }) => {
  return (
    <Container>
      <Title>{column.title}</Title>
      <Droppable droppableId={column.id}>
        {(provided, snapshot) => (
          <FieldList
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            {fields.map((field, index) => (
              <Field key={field.id} field={field} index={index} />
            ))}
            {provided.placeholder}
          </FieldList>
        )}
      </Droppable>
    </Container>
  );
};
