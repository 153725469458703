import { notification } from "antd";
import { betterError } from "./util/GraphQL";
import React from "react";

const GraphqlErrorHandler = ({ error, data, children }) => {
  React.useEffect(() => {
    if (error) {
      if (data) {
        notification.error({
          message: "Error",
          description: `${betterError(error)}`
        });
      } else {
        console.log("SHOULD HANDLE THIS ERROR", error);
        // throw error;
      }
    }
  });
  return children;
};

export default GraphqlErrorHandler;
