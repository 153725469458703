import React from "react";
import { Tooltip, Legend, PieChart, Pie, Cell } from "recharts";
import { COLORS } from "./constants";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
  fill,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);

  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;

  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;

  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  const percentText = `${(percent * 100).toFixed(0)}%`;
  return (
    <g>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percentText}
      </text>

      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {`${name}`}
      </text>
    </g>
  );
};

export const CustomPieChart = ({
  data,
  dataKey = "value",
  width = 400,
  outerRadius = "50%",
}) => {
  let total = 0;
  data.forEach((d) => {
    total += d[dataKey];
  });
  return (
    <PieChart style={{ margin: "auto" }} width={width} height={400}>
      <Pie
        data={data.filter((d) => d[dataKey] > 0)}
        cx="50%"
        cy="50%"
        isAnimationActive={false}
        label={renderCustomizedLabel}
        outerRadius={outerRadius}
        dataKey={dataKey}
      >
        {data.map((_entry, index) => (
          <Cell
            key={index}
            fill={COLORS[index % COLORS.length]}
            // stroke={COLORS[0]}
          />
        ))}
      </Pie>
      <Tooltip
        formatter={(_value, _name, props) => {
          return `${props.value} (${((100 * props.value) / total).toFixed(
            1
          )}%)`;
        }}
      />
      <Legend />
    </PieChart>
  );
};
