import React from "react";
import { Radio } from "antd";

import { purposes_Query as query } from "./Queries";
import { Query } from "./Query";

export const FilterByPurpose = ({ onChange, value }) => (
  <Query query={query}>
    {({ loading, error, data }) => {
      if (loading) return <span>loading....</span>;
      if (error) {
        return <div>{error.message}</div>;
      }

      const children = data["purposes"].map(item => (
        <Radio.Button key={item.id} value={item.id}>
          {item.name}
        </Radio.Button>
      ));

      return (
        <Radio.Group
          size="small"
          onChange={event => onChange(event.target.value)}
          value={value}
        >
          {children}
        </Radio.Group>
      );
    }}
  </Query>
);
