export const formatNumber = (number, decimals = 0) =>
  Number(number)
    .toLocaleString("pt-PT", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .replace(/\s/g, ".");

export const formatExcelNumber = (number, decimals = 0) =>
  number
    ? Number(number)
        .toFixed(2)
        .replace(".", ",")
        .toLocaleString("pt-PT", {
          minimumFractionDigits: decimals,
        })
        .replace(/\s/g, "")
    : null;
// number ? Number(number).toFixed(2) : null;

export const formatPrice = (number, decimals = 2) => {
  if (!number) return undefined;
  return formatNumber(number, decimals) + " €";
};

export const formatArea = (number) => {
  if (!number) return undefined;
  return formatNumber(number) + " m²";
};
export const formatDistance = (number) => {
  let suffix = " m";
  return number.toFixed(0) + suffix;
};
