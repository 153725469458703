import React from "react";
import { Checkbox } from "antd";
import "antd/dist/antd.css";

import { connect } from "react-redux";
import { setUpdatedManually } from "./actions";

const FilterUpdatedManuallyView = ({ checked, text, setChecked }) => (
  <Checkbox
    checked={checked}
    onChange={e => {
      const isChecked = e.target.checked;
      return setChecked(isChecked);
    }}
  >
    {text}
  </Checkbox>
);

const mapStateToProps = state => {
  return {
    checked: state.filters.property.wasUpdatedManually,
    text: "manually updated"
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setChecked: checked => {
      return dispatch(setUpdatedManually(checked));
    }
  };
};

export const FilterUpdatedManually = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterUpdatedManuallyView);
