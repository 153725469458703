import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Select } from "antd";

const { Option } = Select;

const DropdownWithQuery = ({
  queryName,
  query,
  variables,
  mode,
  allowClear = true,
  value,
  disabled = false,
  ...props
}) => {
  const { loading, error, data } = useQuery(query, { variables });

  if (loading) return null;
  const placeholder = loading ? "loading ..." : props.placeholder;

  if (error) {
    console.log("error: ", error.message);
    return null;
  }

  const result = data[queryName] || [];
  const children = result.map(item => (
    <Option key={item.id}>{item.name}</Option>
  ));

  // const valueOption = value && { value };

  return (
    <Select
      size="small"
      {...props}
      style={{ width: 200, ...props.style }}
      allowClear={allowClear}
      mode={mode}
      placeholder={placeholder}
      // {...valueOption}
      value={value}
      onChange={props.onChange}
      disabled={disabled}
    >
      {children}
    </Select>
  );
};

export class DropdownWithQueryClass extends React.Component {
  render() {
    return <DropdownWithQuery {...this.props} />;
  }
}

export default DropdownWithQuery;
