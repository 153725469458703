import React from "react";
import gql from "graphql-tag";
import DropdownWithQuery from "./DropdownWithQuery";

const query = gql`
  query($type_id: ID, $hasType: Boolean!) {
    propertySubtypes(first: -1, where: { type: { id: $type_id } })
      @include(if: $hasType) {
      id
      name
    }
  }
`;

const FilterBySubtype = ({ value, type_id, onChange }) => (
  <DropdownWithQuery
    queryName="propertySubtypes"
    query={query}
    variables={{ type_id, hasType: type_id !== undefined }}
    mode="multiple"
    allowClear={true}
    placeholder="Subtype"
    value={value}
    onChange={value => onChange(value)}
  />
);

export default FilterBySubtype;
