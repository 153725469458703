import React from "react";
import DropdownWithQuery from "./DropdownWithQuery";

import { sites_Query } from "./Queries";

const FilterBySite = ({ value, onChange }) => {
  return (
    <DropdownWithQuery
      mode="multiple"
      queryName="sites"
      query={sites_Query}
      placeholder="Source"
      selection
      value={value}
      onChange={value => onChange(value)}
    />
  );
};
export default FilterBySite;
