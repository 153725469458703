import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Card, Table, Button, Icon } from "antd";
import moment from "moment";
import { FilterByDate } from "./FilterByDate";

const sessionsQuery = gql`
  query($where: SessionWhereInput!) {
    sessions(where: $where) {
      id
      user {
        id
        name
        username
      }
      start_time
      end_time
      ip
    }
  }
`;

const sessionsByUser = sessions => {
  let result = {};
  sessions.forEach(session => {
    const { user } = session;
    const { id: user_id } = user;
    result[user_id] = result[user_id] || { user: null, sessions: [] };
    result[user_id]["user"] = user;
    result[user_id]["sessions"] = [...result[user_id]["sessions"], session];
  });
  return result;
};

export const ShowSessions = () => {
  const defaultStart = moment()
    .subtract(7, "days")
    .startOf("day")
    .format();
  const defaultEnd = moment()
    .endOf("day")
    .format();

  // const [start, setStart] = React.useState(defaultStart);
  // const [end, setEnd] = React.useState(defaultEnd);
  const [dateRange, setDateRange] = React.useState({
    start: defaultStart,
    end: defaultEnd
  });

  // console.log(`date range: ${dateRange.start} - ${dateRange.end}`);
  const { loading, error, data, refetch, networkStatus } = useQuery(
    sessionsQuery,
    {
      variables: {
        where: {
          start_time_lte: dateRange.end,
          end_time_gte: dateRange.start
        }
      },
      notifyOnNetworkStatusChange: true
    }
  );

  const refreshing = networkStatus === 4;
  if (error) throw error;
  if (loading && !refreshing) return null;

  const sessions = sessionsByUser(data.sessions);

  const formatDate = date => moment(date).format("YYYY-MM-DD  (HH:mm)");
  const dataSource = Object.entries(sessions).map(
    ([
      _user_id,
      {
        user: { username, name },
        sessions: user_sessions
      }
    ]) => {
      // const lastSession = user_sessions[user_sessions.length - 1];
      const lastSession = user_sessions[0];
      const { id: key, start_time, end_time, ip } = lastSession;
      // var date = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));

      const children = user_sessions.slice(1).map(child => {
        const { id: key, start_time, end_time, ip } = child;

        return {
          key,
          start_time: formatDate(start_time),
          end_time: formatDate(end_time),
          ip
        };
      });

      return {
        key,
        name,
        username,
        start_time: formatDate(start_time),
        end_time: formatDate(end_time),
        ip,
        children: children.length > 0 ? children : null
      };
    }
  );

  const columns = [
    { dataIndex: "username", title: "Username" },
    { dataIndex: "name", title: "Name" },
    { dataIndex: "start_time", title: "Login" },
    { dataIndex: "end_time", title: "Last Acess" },
    { dataIndex: "ip", title: "IP" }
  ];

  return (
    <Card
      // title="Sessions"
      style={{ margin: "50px auto auto auto", width: 800 }}
      extra={
        <Button
          // loading={refreshing}
          size="small"
          type="default"
          onClick={() => refetch()}
        >
          <Icon type={refreshing ? "loading" : "sync"} />
          Refresh
        </Button>
      }
      title={
        <>
          {"Sessions in date range:  "}
          <FilterByDate
            startDate={dateRange.start}
            endDate={dateRange.end}
            onDateChange={({ start, end }) => {
              setDateRange({
                start: start || defaultStart,
                end: end || defaultEnd
              });
            }}
          />
        </>
      }
    >
      <Table
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </Card>
  );
};
