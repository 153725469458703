import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";

import TopBar from "./TopBar";
import Footer from "./Footer";
import { Login } from "./Login";
import { Logout } from "./Logout";
// import { AddProperties } from "./AddProperties";
import { AddFile } from "./AddFile";
import { ShowSessions } from "./ShowSessions";
import { AddRav } from "./AddRav";
import { CopyRavProspection } from "./CopyRavProspection";
import { Users } from "./Users";
import PropertyListWithSearch from "./PropertyListWithSearch";
import { StatisticsOferta } from "./Statistics";
import { StatisticsOfferComercial } from "./StatisticsOfferComercial";
import { StatisticsMacro } from "./StatisticsMacro";
import { Help } from "./Help";
import { Changelog } from "./Changelog";
import { UserSettings } from "./UserSettings";

import Antd, { Layout, Affix, Icon } from "antd";
import { AUTH_TOKEN, SHOW_FOOTER, VERSION } from "./constants";
import { ErrorBoundary } from "./ErrorBoundary";
import { connect } from "react-redux";

import { HomogenizationMenusContainer } from "./containers/HomogenizationMenusContainer";
import { HomogenizationTableContainer } from "./containers/PropertyContainers";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-cache-inmemory";

import { Notifications } from "./Notifications";
// import { useTranslation } from "react-i18next";

const { Header, Content } = Layout;

const withErrorBoundary = Component => {
  return () => (
    <ErrorBoundary>
      <Component />
    </ErrorBoundary>
  );
};

const NetworkError = () => {
  return "Network Error";
};

const ShowRefreshErrors = withRouter(({ errors, clearErrors, ...props }) => {
  const [visible, setVisible] = React.useState(true);
  let title = null;
  const errorsToDisplay = Object.entries(errors).map(([code, messages]) => {
    title = code === "INVALID_API_VERSION" ? "Versão invalida" : "Erro";
    return (
      <div>
        <p>Versões incompativeis.</p>
        <p>
          Clique em <i>Refresh</i> para actualizar a versão.
        </p>
      </div>
    );
  });
  return (
    <Antd.Modal
      title={title}
      visible={visible}
      closable={false}
      footer={[
        <Antd.Button
          key="ok"
          type="primary"
          onClick={() => {
            setVisible(false);
            clearErrors();
            window.location.reload(true);
            // props.history.push("/logout");
          }}
        >
          <Icon type="sync" />
          Refresh Browser
        </Antd.Button>
      ]}
    >
      {errorsToDisplay}
    </Antd.Modal>
  );
});

const ShowFatalErrors = withRouter(({ errors, ...props }) => {
  const [visible, setVisible] = React.useState(true);
  let title = null;
  const errorsToDisplay = Object.entries(errors).map(([code, messages]) => {
    title = code === "UNAUTHENTICATED" ? "Sessão expirou" : "Erro";
    return (
      <ul key={code}>
        {messages.map((message, index) => {
          return <li key={index}>{message}</li>;
        })}
      </ul>
    );
  });
  return (
    <Antd.Modal
      title={title}
      visible={visible}
      closable={false}
      footer={[
        <Antd.Button
          key="ok"
          type="primary"
          onClick={() => {
            setVisible(false);
            props.history.push("/logout");
          }}
        >
          Ok
        </Antd.Button>
      ]}
    >
      {errorsToDisplay}
    </Antd.Modal>
  );
});

const AppWithUser = ({ user, ...props }) => {
  const [fatalErrors, setFatalErrors] = React.useState({});
  const [refreshErrors, setRefreshErrors] = React.useState({});

  // const { i18n } = useTranslation();
  // i18n.changeLanguage("en");

  const middlewareAuthLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      const authorizationHeader = `Bearer ${token}`;
      operation.setContext({
        headers: {
          authorization: authorizationHeader,
          version: VERSION
        }
      });
    }
    return forward(operation);
  });

  let httpUri = null;
  let wsUri = null;
  if (window.Cypress) {
    httpUri = "http://localhost:5000/api";
    wsUri = "ws://localhost:5000/api";
  } else if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
    httpUri = "http://localhost:4000/api";
    wsUri = "ws://localhost:4000/api";
  } else {
    // production code
    httpUri = "https://reapp.reasonstack.com/api";
    wsUri = "wss://reapp.reasonstack.com/api";
  }

  const httpLink = createPersistedQueryLink().concat(
    new HttpLink({ uri: httpUri })
  );

  const wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: localStorage.getItem(AUTH_TOKEN)
      }
    }
  });

  const addError = ({ code, message }, errors, setErrors) => {
    if (errors[code]) {
      if (errors[code].indexOf(message) < 0) {
        setErrors({ ...errors, [code]: [...errors[code], message] });
      }
    } else {
      setErrors({ ...errors, [code]: [message] });
    }
  };

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        // console.log("[GraphQL error]", graphQLErrors);
        graphQLErrors.forEach(({ message, extensions }) => {
          switch (extensions.code) {
            case "UNAUTHENTICATED":
              addError(
                { code: extensions.code, message },
                fatalErrors,
                setFatalErrors
              );
              break;
            case "INVALID_API_VERSION":
              addError(
                { code: extensions.code, message },
                refreshErrors,
                setRefreshErrors
              );
              console.log("Invalid version. Need to handle. message:", message);
              console.log(
                "Invalid version. Need to handle. extensions:",
                extensions
              );
              // window.location.reload(true);
              break;
            default:
              console.log("Unhandled graphqQLError:", extensions.code);
          }
          // props.history.push("/network-error");
        });
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // props.history.push("/network-error");
      }
    }
  );

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    errorLink.concat(middlewareAuthLink.concat(httpLink))
  );

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
  });

  // const authToken = user && localStorage.getItem(AUTH_TOKEN);
  const authToken = localStorage.getItem(AUTH_TOKEN);

  const content = authToken ? (
    <Content>
      <Notifications client={client} user={user} />
      <Switch>
        <Route
          path={["/map", "/properties"]}
          render={withErrorBoundary(PropertyListWithSearch)}
        />
        <Route
          path="/homogenization"
          render={props => {
            return (
              <ErrorBoundary>
                <HomogenizationMenusContainer {...props} />
                <HomogenizationTableContainer {...props} />
              </ErrorBoundary>
            );
          }}
        />
        <Route
          path="/statistics-offer-residential"
          render={withErrorBoundary(StatisticsOferta)}
        />
        <Route
          path="/statistics-offer-comercial"
          render={withErrorBoundary(StatisticsOfferComercial)}
        />
        <Route
          path="/statistics-macro"
          render={withErrorBoundary(StatisticsMacro)}
        />
        <Route path="/help" render={withErrorBoundary(Help)} />
        <Route path="/changelog" render={withErrorBoundary(Changelog)} />
        {/* <Route
              path="/add-properties"
              render={withErrorBoundary(AddProperties)}
            /> */}
        <Route path="/admin-add-file" render={withErrorBoundary(AddFile)} />
        <Route
          path="/admin-sessions"
          render={withErrorBoundary(ShowSessions)}
        />
        <Route path="/add-rav" render={withErrorBoundary(AddRav)} />
        <Route
          path="/prospection-copy-rav"
          render={withErrorBoundary(CopyRavProspection)}
        />
        <Route path="/admin-users" render={withErrorBoundary(Users)} />
        <Route path="/settings" render={withErrorBoundary(UserSettings)} />
        <Route path="/network-error" render={NetworkError} />
        <Route path="/login" component={Login} />
        <Route
          path="/logout"
          render={() => {
            setFatalErrors({});
            return <Logout />;
          }}
        />
        <Route exact path="/" render={() => <Redirect to="/properties" />} />
        <Redirect to="/" />
      </Switch>
    </Content>
  ) : (
    <Content>
      <Route exact path="/logout" render={() => <Redirect to="/login" />} />
      <Route component={Login} />
    </Content>
  );

  return (
    <ApolloProvider client={client}>
      <Layout style={{ minHeight: "100vh" }}>
        <Affix style={{ zIndex: 100 }}>
          <Header>
            <Route path="/:selected" component={TopBar} />
          </Header>
        </Affix>
        {Object.keys(fatalErrors).length > 0 ? (
          <ShowFatalErrors errors={fatalErrors} {...props} />
        ) : Object.keys(refreshErrors).length > 0 ? (
          <ShowRefreshErrors
            errors={refreshErrors}
            clearErrors={() => setRefreshErrors({})}
            {...props}
          />
        ) : null}
        {content}
        {SHOW_FOOTER ? (
          <Affix offsetBottom={0}>
            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          </Affix>
        ) : null}
      </Layout>
    </ApolloProvider>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const App = connect(mapStateToProps)(AppWithUser);

export default App;
