import React from "react";
import { InputNumber, Input } from "antd";

// const buildFilter = (field, { minValue, maxValue }) => {
//   let result = {};
//   if (minValue) {
//     result[`${field}_gte`] = minValue;
//   }
//   if (maxValue) {
//     result[`${field}_lte`] = maxValue;
//   }
//   return result;
// };

class FilterByNumberBetween extends React.Component {
  state = {
    minValue: undefined,
    maxValue: undefined
  };

  render() {
    const {
      step,
      formatter,
      parser,
      minPlaceholder,
      maxPlaceholder,
      initialMinValue,
      initialMaxValue,
      onMinChange,
      onMaxChange,
      minWidth = 200
    } = this.props;
    const { minValue, maxValue } = this.state;
    return (
      <div>
        <InputNumber
          size="small"
          style={{ minWidth }}
          step={step}
          value={minValue || initialMinValue}
          placeholder={minPlaceholder}
          min={0}
          // max={2147483647}
          max={2000000000}
          formatter={formatter}
          parser={parser}
          onChange={value => this.setState({ minValue: value })}
          onBlur={() =>
            this.setState({ minValue: undefined }, () => onMinChange(minValue))
          }
        />
        <Input
          size="small"
          style={{
            width: 30,
            borderLeft: 0,
            pointerEvents: "none",
            backgroundColor: "#fff"
          }}
          placeholder="~"
          disabled
        />
        <InputNumber
          size="small"
          style={{ minWidth }}
          step={step}
          value={maxValue || initialMaxValue}
          placeholder={maxPlaceholder}
          min={0}
          // max={2147483647}
          max={2000000000}
          formatter={formatter}
          parser={parser}
          onChange={value => this.setState({ maxValue: value })}
          onBlur={() =>
            this.setState({ maxValue: undefined }, () => onMaxChange(maxValue))
          }
        />
      </div>
    );
  }
}

export default FilterByNumberBetween;
