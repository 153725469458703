import { connect } from "react-redux";
import { setPurpose } from "../actions";

import { FilterByPurpose } from "../FilterByPurpose";

const mapStateToProps = state => ({
  value: state.filters.purpose
});

const mapDispatchToProps = dispatch => ({
  onChange: id => dispatch(setPurpose(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByPurpose);
