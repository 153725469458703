import React from "react";
import { COLORS } from "./constants";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";

export const CustomBarChart2 = ({
  data,
  barNames,
  xDataKey = "name",
  barDataKeys = ["value"],
  barSize,
  legendType,
  unit,
  yUnit,
  domain,
}) => {
  return (
    <BarChart
      style={{ margin: "auto" }}
      width={600}
      height={400}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      barGap={-5}
      layout="vertical"
      barSize={200}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" unit={yUnit} hide={true} domain={domain} />
      <YAxis dataKey={xDataKey} type="category" />
      <Tooltip
        formatter={(_value, _name, props) => {
          const percentage = props.payload["percentage"];
          const total = props.payload["value"];
          return (
            <label>
              {percentage} %<div>value : {total}</div>
            </label>
          );
        }}
      />
      {barDataKeys.map((dataKey, index) => (
        <Bar
          key={index}
          dataKey="range"
          name="percentage"
          fill={COLORS[index]}
          barSize={barSize}
          unit={unit}
          isAnimationActive={false}
        >
          <LabelList
            dataKey="percentage"
            position="center"
            fill="#222"
            formatter={(v) => `${v}%`}
          />
        </Bar>
      ))}
    </BarChart>
  );
};

export const CustomBarChart = ({
  data,
  barNames,
  xDataKey = "name",
  barDataKeys = ["value"],
  barSize,
  legendType,
  unit,
  yUnit,
  yTickFormatter,
  tooltipValues = [],
  formatters = {},
}) => {
  return (
    <BarChart
      style={{ margin: "auto" }}
      width={600}
      height={400}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      barGap={-5}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xDataKey} />
      <YAxis unit={yUnit} tickFormatter={yTickFormatter} />
      <Tooltip
        formatter={(v, _name, props) => {
          // console.log("tooltip", value, _name, props);
          const key = props.dataKey.split("_")[0];
          // const key = "count";
          const value = formatters[key] ? formatters[key](v) : `${v} %`;
          const total = props.payload[key];
          const extra =
            tooltipValues.length > 0 ? (
              tooltipValues.map(({ name, formatter }, index) => {
                let value = props.payload[name];
                if (formatter) {
                  value = formatter(value);
                }
                return (
                  <div key={index}>
                    {name} : {value}
                  </div>
                );
              })
            ) : (
              <div>
                {props.name} : {total}
              </div>
            );

          return (
            <label>
              {value}
              {extra}
            </label>
          );
        }}
      />
      <Legend />
      {barDataKeys.map((dataKey, index) => (
        <Bar
          key={index}
          dataKey={dataKey}
          name={barNames[index]}
          fill={COLORS[index]}
          barSize={barSize}
          legendType={legendType}
          unit={unit}
        />
      ))}
    </BarChart>
  );
};

export const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-5}
        y={0}
        dy={5}
        textAnchor="end"
        fill="#8884d8"
        transform="rotate(-90)"
      >
        {payload.value}
      </text>
    </g>
  );
};
