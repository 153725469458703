import { connect } from "react-redux";
import { setAgency } from "../actions";

import { FilterAgency } from "../FilterAgency";

const mapStateToProps = state => ({
  value: state.filters.agency
});

const mapDispatchToProps = dispatch => ({
  onChange: regex => dispatch(setAgency(regex))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterAgency);
