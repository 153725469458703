import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "antd/dist/antd.css";

const monthInterval = (month) => {
  const currentMonth = moment().month();
  const currentYear = moment().year();
  return [
    moment()
      .year(currentMonth < month ? currentYear - 1 : currentYear)
      .month(month)
      .startOf("month"),
    moment()
      .year(currentMonth < month ? currentYear - 1 : currentYear)
      .month(month)
      .endOf("month"),
  ];
};

export const FilterByDate = ({ startDate, endDate, onDateChange }) => {
  return (
    <DatePicker.RangePicker
      size="small"
      ranges={{
        // Today: [moment(), moment()],
        // "This Month": [moment().startOf("month"), moment()],
        // "Last 1 Month": [moment().subtract(1, "month"), moment()],
        // "Last 3 Month": [moment().subtract(3, "months"), moment()],
        // "Last 6 Month": [moment().subtract(6, "months"), moment()],
        // "1 Month Ago": [
        //   moment().subtract(2, "months"),
        //   moment().subtract(1, "months")
        // ],

        Today: [null, null, "Today"],
        Month: [null, null, "This Month"],
        "Last 1 Month": [null, null, "Last 1 Month"],
        "Last 3 Month": [null, null, "Last 3 Month"],
        "Last 6 Month": [null, null, "Last 6 Month"],
        "1 Month Ago": [null, null, "1 Month Ago"],
        Jan: monthInterval(0),
        Feb: monthInterval(1),
        Mar: monthInterval(2),
        Apr: monthInterval(3),
        May: monthInterval(4),
        Jun: monthInterval(5),
        Jul: monthInterval(6),
        Aug: monthInterval(7),
        Sep: monthInterval(8),
        Oct: monthInterval(9),
        Nov: monthInterval(10),
        Dec: monthInterval(11),
      }}
      onChange={([start, end, literal]) => {
        onDateChange({ start, end, literal });
      }}
      value={startDate && endDate && [moment(startDate), moment(endDate)]}
    />
  );
};
