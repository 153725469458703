import React from "react";
import data from "./static/gitInfo.txt";

class Footer extends React.PureComponent {
  state = {
    text: ""
  };

  componentDidMount() {
    fetch(data)
      .then(result => result.text())
      .then(text => this.setState({ text }));
  }
  render() {
    return (
      <p>
        <b>last update: </b>
        <code>{this.state.text}</code>
      </p>
    );
  }
}

export default Footer;
