import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  PropertyListContainer,
  PropertyMapContainer
} from "./containers/PropertyContainers";
import FilterByLocationContainer from "./containers/FilterByLocationContainer";
import PropertyFilters from "./PropertyFilters";
import { Row, Col } from "antd";

const PropertyListWithSearch = props => {
  return (
    <>
      <PropertyFilters {...props} />
      {/* <Divider /> */}
      <Row type="flex" justify="center" style={{ padding: "10px" }}>
        <Col>
          <FilterByLocationContainer {...props} />
        </Col>
      </Row>
      {/* <Divider /> */}
      <Switch>
        <Route path={`/properties`} component={PropertyListContainer} />
        <Route path={`/map`} component={PropertyMapContainer} />
      </Switch>
    </>
  );
};

export default PropertyListWithSearch;
