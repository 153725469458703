import React from "react";
import { Select } from "antd";

const { Option } = Select;

export const FilterByNumberOfBedrooms = ({
  value,
  options,
  onChange,
  allowClear = true,
  mode = "multiple",
  width = 200,
  ...rest
}) => (
  <Select
    size="small"
    style={{ width }}
    allowClear={allowClear}
    mode={mode}
    placeholder={"Typology"}
    value={value}
    onChange={value => onChange(value)}
    {...rest}
  >
    {options.map(option => (
      <Option key={option.id}>{option.value}</Option>
    ))}
  </Select>
);

export class FilterByNumberOfBedroomsClass extends React.Component {
  render() {
    return <FilterByNumberOfBedrooms {...this.props} />;
  }
}
