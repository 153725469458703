import { connect } from "react-redux";
import { setDate, setLastCrawlDate } from "../actions";
import { FilterByDate } from "../FilterByDate";
import { dateRange } from "../util/date";

const mapStateToProps = state => {
  const date = state.filters.date;
  const [startDate, endDate] = date.literal
    ? dateRange(date.literal)
    : [date.start || "", date.end || ""];
  return {
    startDate,
    endDate
  };
};

const mapDispatchToProps = dispatch => ({
  onDateChange: date => dispatch(setDate(date))
});

const lastCrawlDate_mapStateToProps = state => {
  const date = state.filters.lastCrawlDate;
  const [startDate, endDate] = date.literal
    ? dateRange(date.literal)
    : [date.start || "", date.end || ""];
  return {
    startDate,
    endDate
  };
};

const lastCrawlDate_mapDispatchToProps = dispatch => ({
  onDateChange: date => dispatch(setLastCrawlDate(date))
});

export const FilterByDateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByDate);

export const FilterByLastCrawlDateContainer = connect(
  lastCrawlDate_mapStateToProps,
  lastCrawlDate_mapDispatchToProps
)(FilterByDate);
