import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers";

import { unregister } from "./registerServiceWorker";

import { BrowserRouter as Router } from "react-router-dom";

import { GET_STATE_THROTTLE } from "./constants";
import { loadState, saveState } from "./util/localStorage";
import throttle from "lodash/throttle";

import "./i18n";

const persistedState = loadState();
let store;
if (process.env.NODE_ENV === "development") {
  store = createStore(
    reducer,
    persistedState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStore(reducer, persistedState);
}

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, GET_STATE_THROTTLE)
);

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      {/* <Notifications */}
      {/*   client={client} */}
      {/*   user={persistedState && persistedState.user} */}
      {/* /> */}
      <Router>
        <Component />
      </Router>
    </Provider>,
    document.getElementById("root")
  );
};

render(App);

if (window.Cypress) {
  window.store = store;
}

// registerServiceWorker();
unregister();

if (module.hot) {
  module.hot.accept("./App", () => {
    render(App);
  });
}
