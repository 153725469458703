export const hasRole = (user, role) => {
  if (!user) {
    return role === "NONE";
  }

  switch (user.role.name) {
    case "ADMIN":
      return true;
    case "MANAGER":
      return role === "MANAGER" || role === "USER" || role === "NONE";
    case "USER":
      return role === "USER" || role === "NONE";
    default:
      console.log("User ... ", user);
      throw new Error(
        `role "name" must be ADMIN/MANAGER/USER. Got:${user.role}`
      );
  }
};
