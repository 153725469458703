import {
  SET_AGENCY,
  SET_PURPOSE,
  SET_LOCATION,
  SET_SITES,
  SET_CONDITIONS,
  SET_TYPE,
  SET_SUBTYPES,
  SET_TYPOLOGIES,
  SET_MIN_PRICE,
  SET_MAX_PRICE,
  SET_FILTER_PRICE_CHANGES,
  SET_DATE,
  SET_LAST_CRAWL_DATE,
  SET_MIN_AREA,
  SET_MAX_AREA,
  SET_SELECTED_PROPERTIES,
  TOGGLE_HIGHLIGHTED_PROPERTY,
  SET_PROPERTIES_PER_PAGE,
  SET_MAX_PROPERTIES,
  SET_RADIUS,
  RESET,
  SET_HAS_GPS,
  SET_IS_SELECTED,
  SET_WEIGHT,
  ENABLE_WEIGTH,
  DISABLE_WEIGTH,
  SET_PROPERTY_ID,
  SET_NEAR_PROPERTY,
  SET_NEAR_GPS,
  UPDATE_NEW_PROPERTY_FIELDS,
  UPDATE_STATISTICS_FIELDS,
  SET_USER,
  SET_ZOOM,
  SET_CENTER,
  SET_PROPERTIES_PAGE,
  SET_UPDATED_MANUALLY
} from "./actions";
import { combineReducers } from "redux";

// const initialState = {
//   filters: {
//     agency: "agency regex",
//     purpose: "1", // Buy
//     price: {
//       min: null,
//       max: null,
//       changed: null
//     },
//     date: {
//       start: null,
//       end: null
//     },
//     lastCrawlDate: {
//       start: null,
//       end: null
//     },
//     property: {
//       id: null,
//       siteRef: null,
//       near: {
//         propertyId: null
//       }, // prohasGpspertyId. Search all properties within 1km of propertyId
//       division: {
//         type: "parish", // one of: parish, municipality, disitrct
//         id: "010101",
//         name: "Freguesia Inventada"
//       },
//       sites: ["1", "3"],
//       conditions: ["1", "3"],
//       type: "1",
//       subtype: ["1", "3"],
//       typologies: ["0", "1", "T5Plust", "ND"],
//       area: {
//         min: null,
//         max: null
//       },
//       hasGps: null, // null, Yes, No
//       isSelected: null,
//       wasUpdatedManually: null
//     }
//   },
//   searchFor: null,
//   selectedProperties: {}, // { id1: {weight1: ..., weight2: ...}, id2: {weigth1: ...}}
//   activeWeights: {"weightA": true, "weightB": false},
//   highlightedProperty: "001",
//   preferences: {
//     propertiesPerPage: 50,
//     maxProperties: 100,
//     radius: 1000
//   },
//   newProperty: {
//     type: "1"
//   },
//   user: {
//     id: 1,
//     name: "Admin",
//     role: "ADMIN"
//   },
//   googleMap: {
//     zoom: 6,
//     center: {/*...*/}
//   },
//   propertiesPage: null
// };

const defaults = {
  propertyId: null,
  siteRef: null,
  near: null,
  agency: "",
  purpose: "1",
  price: { min: null, max: null, changed: null },
  date: { start: null, end: null, literal: "Last 1 Month" },
  lastCrawlDate: {
    start: null, //   moment().subtract(2, "month"),
    end: null, //   moment().subtract(1, "month")
    literal: null
  },
  sites: [],
  conditions: [],
  type: null,
  subtypes: [],
  typologies: [],
  area: { min: null, max: null },
  searchFor: null,
  selectedProperties: {},
  // activeWeights: [],
  activeWeights: {},
  highlightedProperty: null,
  propertiesPerPage: 50,
  maxProperties: 50,
  radius: 1000,
  division: null,
  hasGps: null,
  isSelected: null,
  wasUpdatedManually: null,
  newProperty: null,
  statistics: null,
  user: null,
  googleMap: {
    zoom: 13,
    center: { lat: 41.41721495445506, lng: -7.225435209426905 }
  },
  propertiesPage: null
};

const agency = (state = defaults.agency, action) => {
  switch (action.type) {
    case SET_AGENCY:
      return action.id;
    case RESET:
      return defaults.agency;
    default:
      return state;
  }
};

const purpose = (state = defaults.purpose, action) => {
  switch (action.type) {
    case SET_PURPOSE:
      return action.id;
    case RESET:
      return defaults.purpose;
    default:
      return state;
  }
};

const price = (state = defaults.price, action) => {
  switch (action.type) {
    case SET_MIN_PRICE:
      return { ...state, min: action.price };
    case SET_MAX_PRICE:
      return { ...state, max: action.price };
    case SET_FILTER_PRICE_CHANGES:
      return { ...state, changed: action.filterPriceChanges };
    case RESET:
      return defaults.price;
    default:
      return state;
  }
};

const date = (state = defaults.date, action) => {
  switch (action.type) {
    case SET_DATE:
      return action.date;
    case RESET:
      return defaults.date;
    default:
      return state;
  }
};

const lastCrawlDate = (state = defaults.lastCrawlDate, action) => {
  switch (action.type) {
    case SET_LAST_CRAWL_DATE:
      return action.date;
    case RESET:
      return defaults.lastCrawlDate;
    default:
      return state;
  }
};

const propertyId = (state = defaults.propertyId, action) => {
  switch (action.type) {
    case SET_PROPERTY_ID:
      return action.id;
    case SET_NEAR_PROPERTY:
    case SET_NEAR_GPS:
    case SET_LOCATION:
    case RESET:
      return defaults.propertyId;
    default:
      return state;
  }
};

const siteRef = (state = defaults.siteRef, action) => {
  switch (action.type) {
    case SET_PROPERTY_ID:
      return action.siteRef || null;
    case SET_NEAR_PROPERTY:
    case SET_NEAR_GPS:
    case SET_LOCATION:
    case RESET:
      return defaults.siteRef;
    default:
      return state;
  }
};

const division = (state = defaults.division, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return action.location;
    case SET_NEAR_PROPERTY:
      const property = action.property;
      if (property.parish) {
        return {
          type: "parish",
          id: property.parish.id,
          name: property.parish.name
        };
      } else if (property.municipality) {
        return {
          type: "municipality",
          id: property.municipality.id,
          name: property.municipality.name
        };
      } else if (property.district) {
        return {
          type: "district",
          id: property.district.id,
          name: property.district.name
        };
      } else {
        return null;
      }
    case SET_NEAR_GPS:
    case RESET:
      return defaults.division;
    default:
      return state;
  }
};

const near = (state = defaults.near, action) => {
  switch (action.type) {
    case SET_NEAR_PROPERTY:
      return {
        propertyId: action.property.property_id
      };
    case SET_NEAR_GPS:
      return action.gps
        ? {
            lat: action.gps.lat,
            lng: action.gps.lng
          }
        : null;
    case SET_LOCATION:
    case SET_PROPERTY_ID:
    case RESET:
      return defaults.near;
    default:
      return state;
  }
};

const sites = (state = defaults.sites, action) => {
  switch (action.type) {
    case SET_SITES:
      return action.ids;
    case RESET:
      return defaults.sites;
    default:
      return state;
  }
};

const conditions = (state = defaults.conditions, action) => {
  switch (action.type) {
    case SET_CONDITIONS:
      return action.ids;
    case RESET:
      return defaults.conditions;
    default:
      return state;
  }
};

const type = (state = defaults.type, action) => {
  switch (action.type) {
    case SET_TYPE:
      return action.id;
    case RESET:
      return defaults.type;
    default:
      return state;
  }
};

const subtypes = (state = defaults.subtypes, action) => {
  switch (action.type) {
    case SET_TYPE:
      return defaults.subtypes;
    case SET_SUBTYPES:
      return action.ids;
    case RESET:
      return defaults.subtypes;
    default:
      return state;
  }
};

const typologies = (state = defaults.typologies, action) => {
  switch (action.type) {
    case SET_TYPOLOGIES:
      return action.ids;
    case RESET:
      return defaults.typologies;
    default:
      return state;
  }
};

const area = (state = defaults.area, action) => {
  switch (action.type) {
    case SET_MIN_AREA:
      return { ...state, min: action.area };
    case SET_MAX_AREA:
      return { ...state, max: action.area };
    case RESET:
      return defaults.area;
    default:
      return state;
  }
};

const hasGps = (state = defaults.hasGps, action) => {
  switch (action.type) {
    case SET_HAS_GPS:
      return action.hasGps;
    case RESET:
      return defaults.hasGps;
    default:
      return state;
  }
};

const isSelected = (state = defaults.isSelected, action) => {
  switch (action.type) {
    case SET_IS_SELECTED:
      return action.isSelected;
    case RESET:
      return defaults.isSelected;
    default:
      return state;
  }
};

const wasUpdatedManually = (state = defaults.wasUpdatedManually, action) => {
  switch (action.type) {
    case SET_UPDATED_MANUALLY:
      return action.updatedManually;
    case RESET:
      return defaults.wasUpdatedManually;
    default:
      return state;
  }
};

const searchFor = (state = defaults.searchFor, action) => {
  switch (action.type) {
    case SET_NEAR_GPS:
      return action.gps
        ? {
            type: "GPS",
            lat: action.gps.lat,
            lng: action.gps.lng
          }
        : null;
    case SET_NEAR_PROPERTY:
      return {
        type: "NEAR",
        siteRef: action.property.site_ref
      };
    case RESET:
    case SET_PROPERTY_ID:
      return defaults.searchFor;
    default:
      return state;
  }
};
const selectedProperties = (state = defaults.selectedProperties, action) => {
  switch (action.type) {
    case SET_SELECTED_PROPERTIES:
      const ids = action.ids;
      let result = {};
      ids.forEach(id => (result[id] = {}));
      result = { ...result, ...state };
      const filtered = ids.reduce(
        (obj, key) => ({ ...obj, [key]: result[key] }),
        {}
      );
      return filtered;
    case SET_WEIGHT:
      const currentProperty = state[action.propertyId];
      const changedProperty = {
        ...currentProperty,
        [action.weightId]: action.weightValue
      };
      return { ...state, [action.propertyId]: changedProperty };
    case SET_LOCATION:
    case RESET:
      return defaults.selectedProperties;
    default:
      return state;
  }
};

const activeWeights = (state = defaults.activeWeights, action) => {
  switch (action.type) {
    case ENABLE_WEIGTH:
      console.log("action", action);
      return { ...state, [action.weightId]: true };
    // return [...new Set([...state, action.weightId])];
    case DISABLE_WEIGTH:
      console.log("action", action);
      return { ...state, [action.weightId]: false };
    // return state.filter(elem => elem !== action.weightId);
    case RESET:
      return defaults.activeWeights;
    default:
      return state;
  }
};

const highlightedProperty = (state = defaults.highlightedProperty, action) => {
  switch (action.type) {
    case TOGGLE_HIGHLIGHTED_PROPERTY:
      return state === action.id ? null : action.id;
    case SET_LOCATION:
    case RESET:
      return defaults.highlightedProperty;
    default:
      return state;
  }
};

const propertiesPerPage = (state = defaults.propertiesPerPage, action) => {
  switch (action.type) {
    case SET_PROPERTIES_PER_PAGE:
      return action.propertiesPerPage;
    case RESET:
      return defaults.propertiesPerPage;
    default:
      return state;
  }
};

const maxProperties = (state = defaults.maxProperties, action) => {
  switch (action.type) {
    case SET_MAX_PROPERTIES:
      return action.max;
    case RESET:
      return defaults.maxProperties;
    default:
      return state;
  }
};

const radius = (state = defaults.radius, action) => {
  switch (action.type) {
    case SET_RADIUS:
      return action.radius;
    case RESET:
      return defaults.radius;
    default:
      return state;
  }
};

const newProperty = (state = defaults.newProperty, action) => {
  switch (action.type) {
    case UPDATE_NEW_PROPERTY_FIELDS:
      let newState = { ...state, ...action.fields };
      if (action.fields.propertyType) {
        newState = { ...newState, propertySubtype: undefined };
      }
      if (action.fields.district) {
        newState = {
          ...newState,
          municipality: undefined,
          parish: undefined
        };
      } else if (action.fields.municipality) {
        newState = { ...newState, parish: undefined };
      }
      return newState;
    default:
      return state;
  }
};

const statistics = (state = defaults.statistics, action) => {
  switch (action.type) {
    case UPDATE_STATISTICS_FIELDS:
      let newState = { ...state, ...action.fields };
      if (action.fields.district) {
        newState = {
          ...newState,
          municipality: undefined,
          parish: undefined
        };
      } else if (action.fields.municipality) {
        newState = { ...newState, parish: undefined };
      }
      return newState;
    case RESET:
      return defaults.statistics;
    default:
      return state;
  }
};

const user = (state = defaults.user, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user;
    default:
      return state;
  }
};

const zoom = (state = defaults.googleMap.zoom, action) => {
  switch (action.type) {
    case SET_ZOOM:
      return action.zoom;
    case SET_LOCATION:
      return null;
    case RESET:
      return defaults.googleMap.zoom;
    default:
      return state;
  }
};

const center = (state = defaults.googleMap.center, action) => {
  switch (action.type) {
    case SET_CENTER:
      return action.center;
    case SET_LOCATION:
      return null;
    case RESET:
      return defaults.googleMap.center;
    default:
      return state;
  }
};

const propertiesPage = (state = defaults.propertiesPage, action) => {
  switch (action.type) {
    case SET_PROPERTIES_PAGE:
      return action.page;
    case RESET:
      return defaults.propertiesPage;
    default:
      return state;
  }
};

const googleMap = combineReducers({
  zoom,
  center
});

const property = combineReducers({
  id: propertyId,
  siteRef,
  near,
  division,
  sites,
  conditions,
  type,
  subtypes,
  typologies,
  area,
  hasGps,
  isSelected,
  wasUpdatedManually
});
const preferences = combineReducers({
  propertiesPerPage,
  maxProperties,
  radius
});
const filters = combineReducers({
  agency,
  purpose,
  price,
  date,
  lastCrawlDate,
  property
});
const rootReducer = combineReducers({
  filters,
  searchFor,
  selectedProperties,
  activeWeights,
  highlightedProperty,
  preferences,
  newProperty,
  statistics,
  user,
  googleMap,
  propertiesPage
});

const reducer = (state, action) => {
  if (action.type === "RELOAD") {
    const newState = action.state;
    return newState;
  } else {
    return rootReducer(state, action);
  }
};

export default reducer;
