import React from "react";
import { HomogenizationParamsSettings } from "./HomogenizationParamsSettings";
import { ColumnSettings } from "./ColumnSettings";
import { AccountSettings } from "./AccountSettings";
import { Card, Layout, Menu } from "antd";
import { Link, Switch, Route } from "react-router-dom";

const MenuBar = ({ match }) => {
  const { selected } = match.params;
  const selectedKeys = [selected || "account"];

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      style={{ height: "100%", borderRight: 0 }}
    >
      <Menu.Item key="account">
        <Link to="/settings/account">Account</Link>
      </Menu.Item>
      <Menu.Item key="properties">
        <Link to="/settings/properties">Properties Table</Link>
      </Menu.Item>
      <Menu.Item key="homogenization">
        <Link to="/settings/homogenization">Homogenization Table</Link>
      </Menu.Item>
    </Menu>
  );
};

export const UserSettings = props => {
  const style = { margin: "50px auto auto auto", width: 800 };
  return (
    <>
      <Layout>
        <Layout.Sider
          width={180}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0
          }}
        >
          <Route
            exact
            path={["/settings", "/settings/:selected"]}
            component={MenuBar}
          />
        </Layout.Sider>
        <Layout style={{ marginLeft: 180 }}>
          <Layout.Content>
            <Switch>
              <Route exact path={["/settings", "/settings/account"]}>
                <Card title="Change password" style={style}>
                  <AccountSettings />
                </Card>
              </Route>
              <Route exact path="/settings/properties">
                <Card title="Properties table settings" style={style}>
                  <ColumnSettings type="properties" />
                </Card>
              </Route>
              <Route exact path="/settings/homogenization">
                <Card title="Homogenization weight names" style={style}>
                  <HomogenizationParamsSettings />
                </Card>
                <Card title="Homogenization table settings" style={style}>
                  <ColumnSettings type="homogenization" />
                </Card>
              </Route>
            </Switch>
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  );
};
