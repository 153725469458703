import React from "react";
import { districts_Query, municipalities_Query } from "./Queries";
import { DropdownWithQueryClass } from "./DropdownWithQuery";

const SelectDistrict = ({ onChange, value }) => {
  return (
    <div style={{ display: "inline-block", padding: "20px" }}>
      <label>District: </label>
      <DropdownWithQueryClass
        mode="default"
        queryName="districts"
        query={districts_Query}
        placeholder="District"
        labelInValue={true}
        onChange={onChange}
        value={value}
        // allowClear={false}
      />
    </div>
  );
};

export const SelectMunicipality = ({ onChange }) => {
  const [district, setDistrict] = React.useState(null);
  const [municipality, setMunicipality] = React.useState(null);

  return (
    <>
      <SelectDistrict
        value={district || undefined}
        onChange={district => {
          setDistrict(district);
          setMunicipality(null);
          onChange(null);
        }}
      />
      {district ? (
        <div style={{ display: "inline-block", padding: "20px" }}>
          <label>Municipality: </label>
          <DropdownWithQueryClass
            mode="default"
            value={municipality || undefined}
            queryName="municipalities"
            query={municipalities_Query}
            variables={{
              districtId: district.key,
              hasDistrict: true
            }}
            placeholder="Municipality"
            labelInValue={true}
            onChange={municipality => {
              setMunicipality(municipality);
              onChange({ district, ...municipality });
            }}
            // allowClear={false}
          />
        </div>
      ) : null}
    </>
  );
};
