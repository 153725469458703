import React from "react";
import {
  BarChart,
  ComposedChart,
  // Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card, Row, Col, message } from "antd";
import { Query } from "./Query";
import { topRentAndSalesQuery } from "./Queries";
import * as R from "ramda";
import { COLORS } from "./constants";

const getYearOfMacroRentVsSale = (data) => {
  if (data.length !== 1) {
    console.log("Arrendamento Vs Venda: data should have 1 row");
    message.error("Arrendamento Vs Venda: data should have 1 row");
    return null;
  }

  const row = data[0];
  const { year } = row;
  if (!year) {
    console.log("Arrendamento Vs Venda: ano inválido");
    message.error("Arrendamento Vs Venda: ano inválido");
    return null;
  }

  return year;
};

const getYearOfMacroTotalRents = (data) => {
  if (data.length !== 1) {
    console.log("TotalRents: data should have 1 row");
    message.error("TotalRents: data should have 1 row");
    return null;
  }

  const row = data[0];
  const { year } = row;
  if (!year) {
    console.log("TotalRents: ano inválido");
    message.error("TotalRents: ano inválido");
    return null;
  }

  return year;
};

export const MacroRentVsSale = ({ data, totalRents, municipality }) => {
  const year = getYearOfMacroRentVsSale(data);
  const year_totalRents = getYearOfMacroTotalRents(totalRents);

  //
  // if (!data) {
  //   message.error("Arrendamento Vs Venda: dados inválidos");
  //   return null;
  // }

  const row = data[0];
  const row_totalRents = totalRents[0];
  // console.log("row", row);

  const { median_rent, median_sale, pt, parent } = row;
  const total_rents = row_totalRents.total_rents;
  const percent_of_parent = Number(
    ((total_rents / row_totalRents.parent.total_rents) * 100.0).toFixed(1)
  );
  const percent_of_pt = Number(
    ((total_rents / row_totalRents.pt.total_rents) * 100.0).toFixed(1)
  );

  const rent_percent = [
    {
      name: row_totalRents.pt.name,
      value: percent_of_pt,
    },
    {
      name: row_totalRents.parent.name,
      value: percent_of_parent,
    },
  ];
  // const rent_percent = [
  //   {
  //     name: row_totalRents.pt.name,
  //     value: 4.0
  //   },
  //   {
  //     name: row_totalRents.parent.name,
  //     value: 12.0
  //   }
  // ];
  const pt_vs_parent = [
    { ...pt },
    { ...parent },
    { median_rent, median_sale, name: municipality.label },
  ];
  // console.log("pt_vs_parent", pt_vs_parent);

  return (
    <Query query={topRentAndSalesQuery} variables={{ year, limit: 20 }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <div>Loading...</div>;
        }
        if (error) {
          throw error;
        }
        const { topRentValue, topSalesValue } = data;
        const pt = R.head(topRentValue).pt;
        const topRentWithPortugal = [
          ...topRentValue,
          { median_rent: pt.median_rent, municipality: { name: pt.name } },
        ];
        const topSalesWithPortugal = [
          ...topSalesValue,
          { median_sale: pt.median_sale, municipality: { name: pt.name } },
        ];
        // console.log("data", data);

        return (
          <Card style={{ width: "100%" }}>
            <h2 style={{ textAlign: "center" }}>
              Arrendamento Versus Venda
              {": " +
                (year === year_totalRents
                  ? year
                  : year + " + " + year_totalRents)}
            </h2>

            <Row type="flex" justify="center" align="middle">
              <Col span={5} style={{ width: 500 }}>
                <h4 style={{ textAlign: "center" }}>
                  Representatividade do arrendamento do concelho no país e na
                  sub-região (%): {year_totalRents}
                </h4>
                <BarChart
                  width={480}
                  height={250}
                  data={rent_percent}
                  barGap={-5}
                  margin={{ top: 10, right: 35, left: 25, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis yAxisId="left" unit="%" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="value"
                    name="Percentagem relativa"
                    fill={COLORS[0]}
                    // label
                    yAxisId="left"
                  />
                </BarChart>
              </Col>
              <Col span={5} style={{ width: 500 }}>
                <h4 style={{ textAlign: "center" }}>
                  Valor Mediano de Vendas e Rendas (€/m2): {year}
                </h4>
                <BarChart
                  width={480}
                  height={250}
                  data={pt_vs_parent}
                  barGap={-5}
                  margin={{ top: 10, right: 35, left: 25, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis yAxisId="left" unit="€" />
                  <YAxis yAxisId="right" orientation="right" unit="€" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="median_sale"
                    name="Valor Mediano Vendas (€/m2)"
                    fill={COLORS[0]}
                    yAxisId="left"
                    // label
                  />
                  <Bar
                    dataKey="median_rent"
                    name="Valor Mediano Rendas (€/m2)"
                    fill={COLORS[1]}
                    yAxisId="right"
                    // label
                  />
                </BarChart>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ marginTop: 100 }}
            >
              <Col span={5} style={{ width: 500 }}>
                <h4 style={{ textAlign: "center" }}>
                  Top 20: Valor Mediano de Venda nos alojamentos familiares
                  (€/m2): {year}
                </h4>
                <ComposedChart
                  width={480}
                  height={480}
                  data={R.reverse(topSalesWithPortugal)}
                  margin={{ top: 10, right: 35, left: 25, bottom: 0 }}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis type="number" unit="€" />
                  <YAxis
                    dataKey="municipality.name"
                    type="category"
                    width={150}
                    interval={0}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="median_sale"
                    // barSize={60}
                    fill={COLORS[0]}
                    // label={{ position: "right" }}
                    isAnimationActive={false}
                  />
                </ComposedChart>
              </Col>
              <Col span={5} style={{ width: 500 }}>
                <h4 style={{ textAlign: "center" }}>
                  Top 20: Valor Mediano de Renda de novos contratos de
                  arrendamento (€/m2): {year}
                </h4>
                <ComposedChart
                  width={480}
                  height={480}
                  data={R.reverse(topRentWithPortugal)}
                  margin={{ top: 10, right: 35, left: 25, bottom: 0 }}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  <XAxis type="number" unit="€" />
                  <YAxis
                    dataKey="municipality.name"
                    type="category"
                    width={150}
                    interval={0}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="median_rent"
                    // barSize={60}
                    fill={COLORS[0]}
                    // label={{ position: "right" }}
                    isAnimationActive={false}
                  />
                </ComposedChart>
              </Col>
            </Row>
          </Card>
        );
      }}
    </Query>
  );
};
