import React from "react";
import { Table, Card } from "antd";

const getFromYear = (arr, year, key) => {
  const found = arr.filter(a => a.year === year);
  if (found.length > 1) {
    throw new Error(
      `FatalError: array should only have one entry for year ${year}`
    );
  }
  if (found.length === 1) {
    return found && found[0][key];
  }
  return null;
};

export const MacroIndicadoresSocioDemograficos = ({
  name,
  population,
  populationEstimate,
  families,
  accomodation,
  accomodationEstimate,
  ownersVsTenants
}) => {
  // console.log("name", name);
  // console.log("population", population);
  // console.log("populationEstimate", populationEstimate);
  // console.log("families", families);
  // console.log("accomodation", accomodation);
  // console.log("accomodationEstimate", accomodationEstimate);
  // console.log("ownersVsTenants", ownersVsTenants);

  if (!population || population.length < 1) {
    return (
      <Card style={{ width: "100%" }}>
        <h2 style={{ textAlign: "center" }}>Indicadores Sócio-demográficos</h2>
        <Table
          dataSource={[]}
          columns={[]}
          pagination={false}
          // bordered={true}
        />
      </Card>
    );
  }

  const populationRealYear0 = population[0].year;
  const populationRealYear1 = population.length > 1 && population[1].year;
  const populationRealYear2 = population.length > 2 && population[2].year;
  const populationEstimateYear0 =
    populationEstimate &&
    populationEstimate.length > 0 &&
    populationEstimate[0].year;
  const usePopulationEstimate = populationEstimateYear0 > populationRealYear0;

  let year0 = null;
  let year1 = null;
  let year2 = null;
  let year0PopulationTable = null;
  let year0AccomodationTable = null;
  let year0Title = null;
  if (usePopulationEstimate) {
    year0 = populationEstimateYear0;
    year1 = populationRealYear0;
    year2 = populationRealYear1;
    year0PopulationTable = populationEstimate;
    year0AccomodationTable = accomodationEstimate;
    year0Title = `${year0}E`;
  } else {
    year0 = populationRealYear0;
    year1 = populationRealYear1;
    year2 = populationRealYear2;
    year0PopulationTable = population;
    year0AccomodationTable = accomodation;
    year0Title = `${year0}`;
  }

  let columns = [
    {
      key: "municipality",
      title: name,
      dataIndex: "name"
    }
  ];

  if (year2) {
    columns = [
      ...columns,
      {
        key: "year2",
        title: year2,
        dataIndex: "year2"
      }
    ];
  }

  if (year1) {
    columns = [
      ...columns,
      {
        key: "year1",
        title: year1,
        dataIndex: "year1"
      }
    ];
  }

  columns = [
    ...columns,
    {
      key: "year0",
      title: year0Title,
      dataIndex: "year0"
    }
  ];

  // console.log("year0", year0);
  // console.log("year1", year1);
  // console.log("year2", year2);
  if (year2) {
    columns = [
      ...columns,
      {
        key: "tv_2_1",
        title: `T.V.(${year2}-${year1})`,
        dataIndex: "tv_2_1"
      }
    ];
  }
  if (year1) {
    columns = [
      ...columns,
      {
        key: "tv_1_0",
        title: `T.V.(${year1}-${year0Title})`,
        dataIndex: "tv_1_0"
      }
    ];
  }

  const tv = (v1, v0) => v1 && v0 && ((v0 / v1) * 100 - 100).toFixed(1) + "%";
  const proportion = (v2, v1) => v1 && v2 && ((v2 / v1) * 100).toFixed(0) + "%";

  const population2 = getFromYear(population, year2, "population");
  const population1 = getFromYear(population, year1, "population");
  const population0 = getFromYear(year0PopulationTable, year0, "population");

  const families_total2 = getFromYear(families, year2, "total");
  const families_total1 = getFromYear(families, year1, "total");
  const families_total0 = getFromYear(families, year0, "total");

  const families_unipessoal2 = getFromYear(families, year2, "unipessoal");
  const families_unipessoal1 = getFromYear(families, year1, "unipessoal");
  const families_unipessoal0 = getFromYear(families, year0, "unipessoal");

  // console.log("accomodation", accomodation);
  const accomodation2 = getFromYear(accomodation, year2, "total");
  const accomodation1 = getFromYear(accomodation, year1, "total");
  const accomodation0 = getFromYear(year0AccomodationTable, year0, "total");
  const accomodation_main2 = getFromYear(accomodation, year2, "main");
  const accomodation_main1 = getFromYear(accomodation, year1, "main");
  const accomodation_main0 = getFromYear(year0AccomodationTable, year0, "main");
  const accomodation_secondary2 = getFromYear(accomodation, year2, "secondary");
  const accomodation_secondary1 = getFromYear(accomodation, year1, "secondary");
  const accomodation_secondary0 = getFromYear(
    year0AccomodationTable,
    year0,
    "secondary"
  );
  const accomodation_empty2 = getFromYear(accomodation, year2, "empty");
  const accomodation_empty1 = getFromYear(accomodation, year1, "empty");
  const accomodation_empty0 = getFromYear(
    year0AccomodationTable,
    year0,
    "empty"
  );

  const ownersVsTenants2 = getFromYear(ownersVsTenants, year2, "total");
  const ownersVsTenants1 = getFromYear(ownersVsTenants, year1, "total");
  const ownersVsTenants0 = getFromYear(ownersVsTenants, year0, "total");
  const owners2 = getFromYear(ownersVsTenants, year2, "owners");
  const owners1 = getFromYear(ownersVsTenants, year1, "owners");
  const owners0 = getFromYear(ownersVsTenants, year0, "owners");
  const tenants2 = getFromYear(ownersVsTenants, year2, "tenants");
  const tenants1 = getFromYear(ownersVsTenants, year1, "tenants");
  const tenants0 = getFromYear(ownersVsTenants, year0, "tenants");

  let dataSource = [
    {
      key: "population",
      name: <strong>População</strong>,
      year2: population2,
      year1: population1,
      year0: population0,
      tv_2_1: tv(population2, population1),
      tv_1_0: tv(population1, population0)
    }
  ];

  if (families_total0 || families_total1) {
    dataSource = [
      ...dataSource,
      {
        key: "families_total",
        name: <strong>Famílias</strong>,
        year2: families_total2,
        year1: families_total1,
        year0: families_total0,
        tv_2_1: tv(families_total2, families_total1),
        tv_1_0: tv(families_total1, families_total0)
      },
      {
        key: "families_median",
        name: <strong>Dimensão Média Famílias</strong>,
        year2: (population2 / families_total2).toFixed(1),
        year1: (population1 / families_total1).toFixed(1),
        year0: (population0 / families_total0).toFixed(1)
      },
      {
        key: "families_unipessoal",
        name: <strong>Famílias Unipessoais</strong>,
        year2: proportion(families_unipessoal2, families_total2),
        year1: proportion(families_unipessoal1, families_total1),
        year0: proportion(families_unipessoal0, families_total0),
        tv_2_1: tv(families_unipessoal2, families_unipessoal1),
        tv_1_0: tv(families_unipessoal1, families_unipessoal0)
      }
    ];
  }

  // console.log("accomodation0", accomodation0);
  // console.log("accomodation1", accomodation1);

  if (accomodation0 || accomodation1) {
    dataSource = [
      ...dataSource,
      {
        key: "accomodation",
        name: <strong>Aloj. Familiares Clássicos</strong>,
        year2: accomodation2,
        year1: accomodation1,
        year0: accomodation0,
        tv_2_1: tv(accomodation2, accomodation1),
        tv_1_0: tv(accomodation1, accomodation0)
      },
      {
        key: "accomodation_main",
        name: <div>&nbsp;&nbsp;&nbsp;&nbsp;Residência Habitual</div>,
        year2: accomodation_main2,
        year1: accomodation_main1,
        year0: accomodation_main0,
        tv_2_1: tv(accomodation_main2, accomodation_main1),
        tv_1_0: tv(accomodation_main1, accomodation_main0)
      },
      {
        key: "accomodation_main_percent",
        name: <div>&nbsp;&nbsp;&nbsp;&nbsp;Proporção Residência Habitual</div>,
        year2: proportion(accomodation_main2, accomodation2),
        year1: proportion(accomodation_main1, accomodation1),
        year0: proportion(accomodation_main0, accomodation0)
      },
      {
        key: "accomodation_secondary",
        name: <div>&nbsp;&nbsp;&nbsp;&nbsp;Sazonais/Secundários</div>,
        year2: accomodation_secondary2,
        year1: accomodation_secondary1,
        year0: accomodation_secondary0,
        tv_2_1: tv(accomodation_secondary2, accomodation_secondary1),
        tv_1_0: tv(accomodation_secondary1, accomodation_secondary0)
      },
      {
        key: "accomodation_secondary_percent",
        name: <div>&nbsp;&nbsp;&nbsp;&nbsp;Proporção Sazonais/Secundários</div>,
        year2: proportion(accomodation_secondary2, accomodation2),
        year1: proportion(accomodation_secondary1, accomodation1),
        year0: proportion(accomodation_secondary0, accomodation0)
      },
      {
        key: "accomodation_empty",
        name: <div>&nbsp;&nbsp;&nbsp;&nbsp;Vagos</div>,
        year2: accomodation_empty2,
        year1: accomodation_empty1,
        year0: accomodation_empty0,
        tv_2_1: tv(accomodation_empty2, accomodation_empty1),
        tv_1_0: tv(accomodation_empty1, accomodation_empty0)
      },
      {
        key: "accomodation_empty_percent",
        name: <div>&nbsp;&nbsp;&nbsp;&nbsp;Proporção Vagos</div>,
        year2: proportion(accomodation_empty2, accomodation2),
        year1: proportion(accomodation_empty1, accomodation1),
        year0: proportion(accomodation_empty0, accomodation0)
      }
    ];

    dataSource = [
      ...dataSource,
      {
        key: "owners",
        name: <strong>Proprietário</strong>,
        year2: proportion(owners2, ownersVsTenants2),
        year1: proportion(owners1, ownersVsTenants1),
        year0: proportion(owners0, ownersVsTenants0)
      },
      {
        key: "tentants",
        name: <strong>Arrendatário</strong>,
        year2: proportion(tenants2, ownersVsTenants2),
        year1: proportion(tenants1, ownersVsTenants1),
        year0: proportion(tenants0, ownersVsTenants0)
      }
    ];
  }

  return (
    <Card style={{ width: "100%" }}>
      <h2 style={{ textAlign: "center" }}>Indicadores Sócio-demográficos</h2>
      <Table
        style={{ minWidth: 650, maxWidth: 900, margin: "auto" }}
        size="small"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        // bordered={true}
      />
    </Card>
  );
};
